import React, { useState } from "react";
import OverViewCard from "../../../components/cards/inventory/OverViewCard";
import { useDispatch, useSelector } from "react-redux";
import { Input, Radio, Spin } from "antd";
import { ReactComponent as BoxIcons } from "../../../assets/icons/dashboard/box-tick.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { Select } from "antd";
import CustomButton from "../../../components/buttons/CustomButton";
import { IoFilterOutline } from "react-icons/io5";
import { SearchOutlined } from "@ant-design/icons";
import type { SearchProps } from "antd/es/input/Search";
import Search from "antd/es/input/Search";
import "./style.css";
import DatePick from "./DatePicker";
import { TbGridDots } from "react-icons/tb";
import { GoListUnordered } from "react-icons/go";
import type { RadioChangeEvent } from "antd";
import InventoryTable from "../../../components/tables/inventoryTable";
import CurrentStockTable from "../../../components/tables/currentStockTable";
import { overViewStatActions } from "../../../store/posOverView/actions";
import { getActiveShop } from "../../../utils/converter";
import CurrentStockDetailsTable from "../../../components/tables/CurrentStokDetailsTable";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";

import { getAllStoclAction } from "../../../store/pos/actions";
const CurrentStock = () => {
  const { posOverView, auth, appSettings, shop, pos } = useSelector(
    (state: any) => state
  );

  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const [showFilter, setShowFilter] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [value4, setValue4] = useState("pending");
  const [data, setData] = useState<any>(null);
  const onSearch: SearchProps["onSearch"] = (value: any, info: any) =>
    console.log(info?.source, value);
  const handleChange = (value: { value: string; label: React.ReactNode }) => {
    console.log(value);
  };
  const shopId: any =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.user?.shop?.shopId;

  const handleViewDetails = () => {
    setViewDetails(true);
  };

  const onChange4 = ({ target: { value } }: RadioChangeEvent) => {
    setValue4(value);
  };
  const options = [
    { label: "Stok In", value: "pending", title: "approved" },
    { label: "Sold ", value: "sold" },
    { label: "Transfer", value: "transfer" }
  ];

  React.useEffect(() => {
    auth?.token && overViewStatActions(auth?.token, shopId)(dispatch);
  }, [auth?.token, dispatch, shopId]);
  React.useEffect(() => {
    auth?.token &&
      getAllStoclAction(
        auth?.token,
        `?shop=${shopId}&page=${page - 1}&limit=${limit}`
      )(dispatch);
  }, [auth, dispatch, shopId, limit, page]);
  return (
    <div className="text-black">
      <div className="flex gap-1">
        <DetailsHeaderActionBar
          goBack={() => setViewDetails(false)}
          title="Inventory"
        />
        <h1 className="text-[#030229d8] text-lg pt-1">\ CurrentStock</h1>
      </div>
      <div className="flex flex-wrap gap-4 mt-3">
        <OverViewCard
          total={
            posOverView.setIsFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              posOverView?.overViewStats?.data?.stockRequests || 0
            )
          }
          text="Total Requested"
          icon={<BoxIcons />}
        />
        <OverViewCard
          total={
            posOverView.setIsFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              posOverView?.overViewStats?.data?.totalCurrentDevices || 0
            )
          }
          text="Total Stock In"
          icon={<BoxIcons />}
        />
        <OverViewCard
          total={
            posOverView.setIsFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              posOverView?.overViewStats?.data?.totalDevicesSold || 0
            )
          }
          text="Total Sold"
          icon={<BoxIcons />}
        />
        <OverViewCard
          total={
            pos.setIsFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              posOverView?.overViewStats?.data?.transfer || 0
            )
          }
          text="Total Transfer"
          icon={<BoxIcons />}
        />
      </div>
      <div className="mt-4 flex justify-between">
        {!viewDetails ? (
          <>
            <div className="flex gap-5 flex-wrap">
              <CustomButton
                // title={showFilter ? "" : "filter"}
                textColor="[#030229B2]"
                icon={<IoFilterOutline size={20} />}
                bgColor={"white"}
                border="[#0302291A]"
                // onClick={() => setShowFilter(!showFilter)}
              />
              {showFilter && (
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="filter by Model "
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    {
                      value: "1",
                      label: "Pop 8 (BC6)"
                    },
                    {
                      value: "2",
                      label: "Spark 20"
                    },
                    {
                      value: "3",
                      label: "Pop7"
                    }
                  ]}
                />
              )}
              <CustomButton
                title={`Available Products (${
                  pos?.isFetching ? 0 : pos?.stock?.total
                })`}
                textColor="[#030229]"
                bgColor={"white"}
                border="[#0302291A]"
              />
            </div>

            <div className="flex gap-5">
              {/* <Search
                placeholder="input search text"
                onSearch={(value, _e) => console.log(value)}
                style={{ width: 200, marginTop: "5px" }}
                prefix={
                  <SearchOutlined
                    style={{ color: "#1677ff", fontSize: "16px" }}
                  />
                }
              /> */}
              {/* <DatePick /> */}
              <div className="bg-[#605BFF] flex justify-center items-center h-[30px] w-[30px] mt-1.5 rounded-md">
                <GoListUnordered size={23} color="#ffff" />
              </div>
              <div className="bg-white flex justify-center items-center h-[30px] w-[30px] mt-1.5 rounded-md">
                <TbGridDots size={23} color="#C0C0C9" />
              </div>
              <hr className="h-7 w-0.5 bg-gray-300 mt-1.5" />
            </div>
          </>
        ) : (
          <div className="flex justify-between w-full">
            <Radio.Group
              options={options}
              onChange={onChange4}
              value={value4}
              optionType="button"
              buttonStyle="solid"
            />
            <div className="flex gap-5">
              <DatePick />
              <hr className="h-7 w-0.5 bg-gray-300 mt-1.5" />
              <div className="bg-[#605BFF] flex justify-center items-center h-[30px] w-[30px] mt-1.5 rounded-md">
                <GoListUnordered size={23} color="#ffff" />
              </div>
              <div className="bg-white flex justify-center items-center h-[30px] w-[30px] mt-1.5 rounded-md">
                <TbGridDots size={23} color="#C0C0C9" />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="mt-5">
        {!viewDetails ? (
          <CurrentStockTable
            getData={() => handleViewDetails()}
            setData={setData}
          />
        ) : (
          <CurrentStockDetailsTable data={data} value={value4} />
        )}
      </div>
    </div>
  );
};

export default CurrentStock;
