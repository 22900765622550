import React, { useEffect, useState, Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { LoadingButton } from "@mui/lab";
import { FileImageOutlined, FilePdfOutlined } from "@ant-design/icons";
import { handlePreviousStep } from "../../../store/layout/actions";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import Aos from "aos";
import "aos/dist/aos.css";
import DocViewer from "react-doc-viewer";
import ViewFile from "./viewFile";
// import { Tabs } from "antd";
// import type { TabsProps } from "antd";
const ConfirmPage = (props: any) => {
  const { wareHouse } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const handlePrevious = async () => {
    await handlePreviousStep(4)(dispatch);
  };
  const [displayContent, setDisplayContent] = useState(true);
  const handleShow = () => {
    setDisplayContent(!displayContent);
  };
  useEffect(() => {
    Aos.init();
  }, []);
  const fileUrl =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.MomoStatementDoc &&
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.MomoStatementDoc[0];
  const nationalIdUrl =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.nationalIDDoc;

  const fileExtension = fileUrl?.split(".").pop();
  const nationalIdUrlExtension = nationalIdUrl?.split(".").pop();

  return (
    <div className="w-full flex flex-col lg:max-2xl:h-56 h-full overflow-y-auto relative">
      <div className="w-full ">
        {displayContent ? (
          <>
            <div
              className="flex gap-8 w-9/12 lg:max-2xl:w-full m-auto "
              data-aos="fade-down"
            >
              <div className="w-[360px] h-[183px] lg:max-2xl:h-[160px] rounded-lg xpl-8 pl-2 pb-4  border-[0.5px] mt-14 lg:max-2xl:mt-2">
                <h1 className="text-[#170F49] pt-6 text-lg font-semibold lg:max-2xl:pt-2 lg:max-2xl:text-base ">
                  Personal Information
                </h1>
                {wareHouse?.createdCart?.data?.customer && (
                  <div className="mt-6 ">
                    <div className="flex items-center space-x-4 ">
                      <h2 className="font-bold">
                        <PersonIcon className=" text-[#0F0F4799]" />
                      </h2>
                      <p className="lg:max-2xl:text-sm">
                        {wareHouse?.createdCart?.data?.customer?.name}
                      </p>
                    </div>

                    <div className="flex items-center space-x-4 mt-3">
                      <h2 className="font-bold">
                        <PhoneIcon className=" text-[#0F0F4799]" />
                      </h2>
                      <p className="lg:max-2xl:text-sm">
                        {wareHouse?.createdCart?.data?.customer?.phone}
                      </p>
                    </div>

                    <div className="flex items-center space-x-4 mt-3">
                      <h2 className="font-bold">
                        <EmailIcon className=" text-[#0F0F4799]" />
                      </h2>
                      <p className="lg:max-2xl:text-sm">
                        {wareHouse?.createdCart?.data?.customer?.email}
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="2xl:w-[446px] lg:max-2xl:w-[360px] h-[183px] lg:max-2xl:h-[160px] rounded-lg pl-8 lg:max-2xl:pl-2 border-[0.5px] mt-14 lg:max-2xl:mt-2">
                <h1 className=" text-[#170F49] pt-6 text-lg font-semibold lg:max-2xl:text-base lg:max-2xl:pt-2">
                  Address Details
                </h1>
                {wareHouse?.createdCart?.data?.customer?.subscriptionInfo
                  ?.address && (
                  <div className="flex gap-10 py-3">
                    <div>
                      <div className="flex gap-3 py-2">
                        <h2 className="text-[#0F0F4799] text-sm  ">Country:</h2>
                        <p className="text-[#0F0F47] text-sm font-medium">
                          {
                            wareHouse?.createdCart?.data?.customer
                              ?.subscriptionInfo?.address?.country
                          }
                        </p>
                      </div>
                      <div className="flex gap-3 py-2">
                        <h2 className="text-[#0F0F4799] text-sm ">Province:</h2>
                        <p className="text-[#0F0F47] text-sm font-medium">
                          {
                            wareHouse?.createdCart?.data?.customer
                              ?.subscriptionInfo?.address?.provence
                          }
                        </p>
                      </div>
                      <div className="flex gap-5 py-2 ">
                        <h2 className="text-[#0F0F4799] text-sm pb-2">
                          District:
                        </h2>
                        <p className="text-[#0F0F47] text-sm font-medium">
                          {
                            wareHouse?.createdCart?.data?.customer
                              ?.subscriptionInfo?.address?.district
                          }
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="flex gap-9 py-2 ">
                        <h2 className="text-[#0F0F4799] text-sm ">Sector:</h2>
                        <p className="text-[#0F0F47] text-sm font-medium">
                          {
                            wareHouse?.createdCart?.data?.customer
                              ?.subscriptionInfo?.address?.sector
                          }
                        </p>
                      </div>
                      <div className="flex gap-14 py-2 ">
                        <h2 className="text-[#0F0F4799] text-sm ">Cell:</h2>
                        <p className="text-[#0F0F47] text-sm font-medium">
                          {
                            wareHouse?.createdCart?.data?.customer
                              ?.subscriptionInfo?.address?.cell
                          }
                        </p>
                      </div>
                      <div className="flex gap-9 py-2 ">
                        <h2 className="text-[#0F0F4799] text-sm">Village:</h2>
                        <p className="text-[#0F0F47] text-sm font-medium">
                          {
                            wareHouse?.createdCart?.data?.customer
                              ?.subscriptionInfo?.address?.village
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            {" "}
            <div
              className="flex gap-10 w-11/12 mx-12 lg:max-2xl:mx-1"
              data-aos="fade-up"
            >
              <div className="w-[446px]  rounded-lg pl-8 lg:max-2xl:pl-2 lg:max-2xl:w-[340px] pb-4 border-[0.5px] mt-5">
                <h1 className="text-[#170F49] pt-6 lg:max-2xl:pt-2 text-lg font-semibold">
                  Witness Information
                </h1>

                {wareHouse?.createdCart?.data?.customer?.subscriptionInfo
                  ?.witnessInfo?.length > 0 && (
                  <>
                    {wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.witnessInfo?.map(
                      (d: any, index: any) => (
                        <div className="space-y-2 py-2">
                          <span className="text-base font-normal text-[#5F5AFE]">
                            Witness {index + 1}
                          </span>
                          <div className="ml-20 ">
                            <div className="flex  items-center space-x-12">
                              <p className="text-sm font-medium text-[#0F0F4799]">
                                Name:
                              </p>
                              <p className="text-sm font-medium text-[#0F0F47]">
                                {d?.name}
                              </p>
                            </div>

                            <div className="flex items-center space-x-6 ">
                              <p className="text-sm font-medium text-[#0F0F4799]">
                                Telephone:
                              </p>
                              <p className="text-sm font-medium text-[#0F0F47]">
                                {d?.phone}
                              </p>
                            </div>

                            <div className="flex items-center space-x-4">
                              <p className="text-sm font-medium text-[#0F0F4799]">
                                RelationShip:
                              </p>
                              <p className="text-sm font-medium text-[#0F0F47]">
                                {d?.relationship}
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </>
                )}
              </div>
              <div className="w-[446px] rounded-lg pl-8 lg:max-2xl:pl-2 lg:max-2xl:w-[360px] border-[0.5px] mt-5">
                <h1 className="text-[#170F49] pt-6 lg:max-2xl:pt-2 text-lg font-semibold">
                  Documents
                </h1>
                {wareHouse?.createdCart?.data?.customer?.subscriptionInfo && (
                  <>
                    <div className="my-4  flex gap-6 mr-5">
                      <div className="">
                        {[
                          "jpg",
                          "jpeg",
                          "png",
                          "gif",
                          "tiff",
                          "bmp",
                          "eps",
                          "row",
                          "ai",
                          "indd",
                        ].includes(nationalIdUrlExtension) && (
                          <>
                            <span>
                              <button className="font-medium text-sm text-[#0F0F4799] py-2">
                                {" "}
                                <FileImageOutlined
                                  style={{ color: "#170F49", fontSize: "20px" }}
                                />{" "}
                                <span className="pl-2">National ID </span>
                              </button>
                            </span>
                          </>
                        )}
                        {nationalIdUrlExtension === "pdf" && (
                          <>
                            <button>
                              {" "}
                              <span className="font-medium text-sm text-[#0F0F4799] py-2">
                                <FilePdfOutlined
                                  style={{ color: "#170F49", fontSize: "20px" }}
                                />{" "}
                                <span className="pl-2">National ID</span>
                              </span>
                            </button>
                          </>
                        )}
                      </div>
                      <div className="mt-[11px]">
                        {[
                          "jpg",
                          "jpeg",
                          "png",
                          "gif",
                          "tiff",
                          "bmp",
                          "eps",
                          "row",
                          "ai",
                          "indd",
                        ].includes(fileExtension) && (
                          <>
                            <button>
                              <span className="font-medium text-sm text-[#0F0F4799] py-2">
                                <FileImageOutlined
                                  style={{ color: "#170F49", fontSize: "20px" }}
                                />
                                <span className="pl-2"> Momo Statement</span>
                              </span>
                            </button>
                          </>
                        )}
                        {fileExtension === "pdf" && (
                          <>
                            <button>
                              <span className="font-medium text-sm text-[#0F0F4799] py-2">
                                <FilePdfOutlined
                                  style={{ color: "#170F49", fontSize: "20px" }}
                                />
                                <span className="pl-2"> Momo Statement</span>
                              </span>{" "}
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div>
        <button onClick={handleShow}>
          <ExpandCircleDownIcon
            className={` ${
              displayContent ? "" : "rotate-180"
            } absolute left-[43%] 2xl:left-[44%] 2xl:bottom-0`}
            sx={{ fontSize: "40px", color: "#2943D6" }}
          />
        </button>
      </div>
      <div
        className={`2xl:absolute items-end right-2 bottom-0
          }`}
      >
        {!wareHouse?.createdCart?.data?.isSubscriptionSubmitted && (
          <div className="flex items-center justify-end pr-2 space-x-2">
            <LoadingButton
              style={{
                padding: "6px",
                borderColor: "red",
                color: "red",
              }}
              variant="outlined"
              onClick={props?.removeCustomer}
            >
              {"Discard"}
            </LoadingButton>
            <LoadingButton
              variant="outlined"
              style={{
                padding: "6px",
                width: "80px",
              }}
              onClick={handlePrevious}
            >
              {`Previous`}
            </LoadingButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmPage;
