import React, { useEffect, useState } from "react";
import { Form, Input, notification, Select } from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { updateCustomerAction } from "../../../store/customer/actions";
import {
  handleNextStep,
  handlePreviousStep,
} from "../../../store/layout/actions";
import countryList from "country-list";
// @ts-ignore
import { Provinces, Districts, Sectors, Cells, Villages } from "rwanda";
import type { SelectProps } from "antd";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
interface UserValue {
  label: string;
  value: string;
}
const AddressDetailsForm = (props: any) => {
  const { wareHouse, customer, auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedCell, setSelectedCell] = useState("");
  const [selectedVillage, setSelectedVillage] = useState("");

  const [listDistrict, setListDistrict] = useState<any[]>([]);
  const [listSector, setListSector] = useState<any[]>([]);
  const [listCell, setListCell] = useState<any[]>([]);
  const [listVillage, setListVillage] = useState<any[]>([]);

  const witnessInfo =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.witnessInfo;
  const nationalId =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.nationalIDDoc;
  const momoStatement =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.MomoStatementDoc &&
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.MomoStatementDoc[0];
  const countryOptions = countryList.getData().map((country: any) => ({
    label: country.name,
    value: country.name,
  }));
  const provinceOptions: [{ value: string; label: string }] = Provinces().map(
    (pro: string) => ({
      label: pro,
      value: pro,
    })
  );

  const onFinish = async (values: any) => {
    if (
      !Villages(
        selectedProvince ?? "",
        selectedDistrict ?? "",
        selectedSector ?? "",
        selectedCell ?? ""
      )
    ) {
      return notification.error({ message: "Address is not well arranged!" });
    }
    console.log(values);
    // return;
    if (wareHouse?.createdCart?.data?.customer?._id && auth?.token) {
      await updateCustomerAction(
        auth?.token,
        wareHouse?.createdCart?.data?.customer?._id,
        {
          subscriptionInfo: {
            address: values,
            witnessInfo: witnessInfo,
            nationalIDDoc: nationalId,
            MomoStatementDoc: [momoStatement],
          },
        }
      )(dispatch);
      await handleNextStep(1)(dispatch);
    } else {
      notification.info({ message: "Missing Personal Details" });
    }
  };

  // if Province changes
  useEffect(() => {
    try {
      setListDistrict(
        Districts(selectedProvince ?? "").map((val: string) => ({
          label: val,
          value: val,
        }))
      );
    } catch (e: any) {
      setListSector([]);
      setSelectedSector("");

      return;
    }
  }, [selectedProvince]);

  useEffect(() => {
    try {
      setListSector(
        Sectors(selectedProvince ?? "", selectedDistrict ?? "").map(
          (val: string) => ({
            label: val,
            value: val,
          })
        )
      );
    } catch (e: any) {
      setSelectedCell("");
      setListCell([]);
      return;
    }
  }, [selectedDistrict, listDistrict]);

  useEffect(() => {
    try {
      setListCell(
        Cells(
          selectedProvince ?? "",
          selectedDistrict ?? "",
          selectedSector ?? ""
        ).map((val: string) => ({
          label: val,
          value: val,
        }))
      );
    } catch (e: any) {
      setSelectedVillage("");
      setListVillage([]);
      return;
    }
  }, [listSector, selectedSector]);

  useEffect(() => {
    try {
      setListVillage([]);
      setSelectedVillage("");
      setListVillage(
        Villages(
          selectedProvince ?? "",
          selectedDistrict ?? "",
          selectedSector ?? "",
          selectedCell ?? ""
        ).map((val: string) => ({
          label: val,
          value: val,
        }))
      );
    } catch (e: any) {
      console.log(
        "----= ",
        selectedProvince ?? "",
        selectedDistrict ?? "",
        selectedSector ?? "",
        selectedCell ?? ""
      );
      console.log(e);
    }
  }, [listCell, selectedCell]);

  useEffect(() => {
    if (wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address) {
      form.setFieldsValue(
        wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address
      );
    } else {
      form.resetFields();
    }
  }, [form, wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address]);

  const initialData = {
    ...wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address,
  };
  console.log("^^^", initialData);
  const handlePrevious = async () => {
    await handlePreviousStep(1)(dispatch);
  };

  return (
    <div className="lg:max-2xl:h-56 h-full overflow-y-auto p-4">
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        layout="vertical"
        onFinish={onFinish}
        initialValues={initialData || {}}
        style={{ maxWidth: "100%" }}
        scrollToFirstError
        className="antd-custom-style "
      >
        <div className="  mx-4 mt-10 lg:max-2xl:mt-2 lg:max-2xl:ml-0">
          <div className="rounded-lg">
            <div className="grid grid-cols-3 space-x-2 lg:max-2xl:gap-4">
              <Form.Item
                name="country"
                className="lg:max-2xl:ml-0 "
                // initialValue={firstNameToUpdate}
                label={<span className="text-[#0F0F47] text-sm">Country</span>}
                rules={[
                  {
                    required: true,
                    message: "Country is required!",
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  // defaultValue={"Rwanda"}
                  style={{ width: 200 }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? "").toString().toLowerCase()
                      )
                  }
                  onChange={(value: string) => {
                    setSelectedCountry(value);
                    form.resetFields([
                      "provence",
                      "district",
                      "sector",
                      "cell",
                      "village",
                    ]);
                  }}
                  options={countryOptions}
                />
              </Form.Item>
              <Form.Item
                name="provence"
                label={<span className="text-[#0F0F47] text-sm">Province</span>}
                rules={[
                  {
                    required: true,
                    message: "Provence is required!",
                    whitespace: true,
                  },
                ]}
              >
                {selectedCountry === "Rwanda" ? (
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toString()
                        .toLowerCase()
                        .localeCompare(
                          (optionB?.label ?? "").toString().toLowerCase()
                        )
                    }
                    onChange={(value: any) => {
                      setSelectedProvince(value);
                      form.resetFields([
                        "district",
                        "sector",
                        "cell",
                        "village",
                      ]);
                    }}
                    options={provinceOptions}
                    allowClear
                  />
                ) : (
                  <Input className="pl-4 h-[52px] w-[320px] lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] bg-[#EFF0F6] border-none" />
                )}
              </Form.Item>
              <Form.Item
                name="district"
                label={<span className="text-[#0F0F47] text-sm">District</span>}
                rules={[
                  {
                    required: true,
                    message: "District is required!",
                    whitespace: true,
                  },
                ]}
              >
                {selectedCountry === "Rwanda" ? (
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toString()
                        .toLowerCase()
                        .localeCompare(
                          (optionB?.label ?? "").toString().toLowerCase()
                        )
                    }
                    onChange={(value: any) => {
                      setSelectedDistrict(value);
                      form.resetFields(["sector", "cell", "village"]);
                    }}
                    options={listDistrict}
                    allowClear
                  />
                ) : (
                  <Input className="pl-4 h-[52px] w-[320px] lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] bg-[#EFF0F6] border-none" />
                )}
              </Form.Item>
              <Form.Item
                name="sector"
                className="lg:max-2xl:-mt-5"
                // initialValue={props?.dataToUpdate?.name
                //   ?.slice(firstNameToUpdate?.length)
                //   ?.trim()}
                label={<span className="text-[#0F0F47] text-sm">Sector</span>}
                rules={[
                  {
                    required: true,
                    message: "Sector is required!",
                    whitespace: true,
                  },
                ]}
              >
                {selectedCountry === "Rwanda" ? (
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toString()
                        .toLowerCase()
                        .localeCompare(
                          (optionB?.label ?? "").toString().toLowerCase()
                        )
                    }
                    onChange={(value: any) => {
                      setSelectedSector(value);
                      form.resetFields(["cell", "village"]);
                    }}
                    options={listSector}
                    value={selectedSector}
                    allowClear
                  />
                ) : (
                  <Input className="pl-4 h-[52px] w-[320px] lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] bg-[#EFF0F6] border-none" />
                )}
              </Form.Item>
              <Form.Item
                name="cell"
                className="lg:max-2xl:-mt-5"
                // initialValue={props?.dataToUpdate?.name
                //   ?.slice(firstNameToUpdate?.length)
                //   ?.trim()}
                label={<span className="text-[#0F0F47] text-sm">Cell</span>}
                rules={[
                  {
                    required: true,
                    message: "Cell is required!",
                    whitespace: true,
                  },
                ]}
              >
                {selectedCountry === "Rwanda" ? (
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toString()
                        .toLowerCase()
                        .localeCompare(
                          (optionB?.label ?? "").toString().toLowerCase()
                        )
                    }
                    onChange={(value: any) => {
                      setSelectedCell(value);
                      form.resetFields(["village"]);
                    }}
                    options={listCell}
                    value={selectedCell}
                    allowClear
                  />
                ) : (
                  <Input className="pl-4 h-[52px] w-[320px] lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] bg-[#EFF0F6] border-none" />
                )}
              </Form.Item>
              <Form.Item
                name="village"
                className="lg:max-2xl:-mt-5"
                label={<span className="text-[#0F0F47] text-sm">Village</span>}
                initialValue={props?.dataToUpdate?.email}
                rules={[
                  {
                    required: true,
                    message: "Village is required!",
                    whitespace: true,
                  },
                ]}
              >
                {selectedCountry === "Rwanda" ? (
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toString()
                        .toLowerCase()
                        .localeCompare(
                          (optionB?.label ?? "").toString().toLowerCase()
                        )
                    }
                    onChange={(value: any) => setSelectedVillage(value)}
                    options={listVillage}
                    value={selectedVillage}
                    allowClear
                  />
                ) : (
                  <Input className="pl-4 h-[52px] w-[320px] lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] bg-[#EFF0F6] border-none" />
                )}
              </Form.Item>
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-x-2 mr-24 mt-16 !h-4 box-border">
          <div className="flex items-center space-x-2 -mt-16">
            <Form.Item {...tailFormItemLayout}>
              <div className="flex items-center space-x-2">
                <LoadingButton
                  style={{
                    padding: "6px",
                    borderColor: "red",
                    color: "red",
                  }}
                  variant="outlined"
                  loading={customer?.isFetching}
                  onClick={props?.removeCustomer}
                >
                  {"Discard"}
                </LoadingButton>
                <LoadingButton
                  variant="outlined"
                  loading={customer?.isFetching}
                  style={{
                    padding: "6px",
                    width: "80px",
                  }}
                  onClick={handlePrevious}
                >
                  {`Previous`}
                </LoadingButton>

                <LoadingButton
                  type="submit"
                  variant="contained"
                  style={{
                    padding: "6px",
                    width: "80px",
                  }}
                  loading={customer?.isFetching}
                >
                  {`Next`}
                </LoadingButton>
              </div>
            </Form.Item>
          </div>
          {/* <div className="flex items-center space-x-2 ">
          <Form.Item {...tailFormItemLayout}>
            <LoadingButton
              type="submit"
              variant="contained"
              style={{
                padding: "6px",
                width: "80px",
                borderRadius: "10px",
              }}
              loading={customer?.isFetching}
            >
              {`Next`}
            </LoadingButton>
          </Form.Item>
        </div> */}
        </div>
      </Form>
    </div>
  );
};

export default AddressDetailsForm;
