import React, { useEffect, useState } from "react";
import OverViewCard from "../../../components/cards/inventory/OverViewCard";
import { ReactComponent as BoxIcons } from "../../../assets/icons/dashboard/box-tick.svg";
import { ReactComponent as NotifiactionsIcons } from "../../../assets/icons/dashboard/fluent_alert-urgent-24-filled.svg";
import { ReactComponent as PdfIcons } from "../../../assets/icons/dashboard/codicon_file-pdf.svg";
import { ReactComponent as CsvIcons } from "../../../assets/icons/dashboard/ph_file-csv.svg";
import { DatePicker, Select, DatePickerProps } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { GoListUnordered } from "react-icons/go";
import { TbGridDots } from "react-icons/tb";
import Search from "antd/es/input/Search";
import { SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../../components/buttons/CustomButton";
import { IoFilterOutline } from "react-icons/io5";
import NoSalesTable from "../../../components/tables/NoSalesTable";
import { getActiveShop } from "../../../utils/converter";
import { useDispatch, useSelector } from "react-redux";
import { getAllNosaleAction, getAllNosaleStatsAction } from "../../../store/pos/actions";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
 
const NoSales = () => {
  const { auth, customer, repair, appSettings, shop,pos } = useSelector(
    (state: any) => state
  );
  dayjs.extend(customParseFormat);
  const dispatch = useDispatch();
  // const shopId =
  //   getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
  //     ?.shop?._id ?? shop?.selected?._id;
  const dateFormat = "YYYY-MM-DD";
  const [showFilter, setShowFilter] = useState(false);
  const formattedDate = new Date().toISOString().slice(0, 10);
  const [selectedDate, setSelectedDate] = useState<any>(formattedDate);
  const [selectedDate2, setSelectedDate2] = useState<any>(formattedDate);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(dateString);
    setSelectedDate(dateString);
  };
  const onChangeDate: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(dateString);
    setSelectedDate2(dateString);
  };
  const shopId=getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]?.shop?._id || auth?.user?.shop?.shopId;
  useEffect(()=>{
auth?.token && getAllNosaleStatsAction(auth?.token,`?shopId=${shopId}&date1=${selectedDate}&date2=${selectedDate2}`)(dispatch);
  },[auth?.token,dispatch,selectedDate,selectedDate2,shopId])
  
  useEffect(() => {
    auth?.token &&
      getAllNosaleAction(auth?.token, `?shopId=${shopId}&startingDate=${selectedDate}&endingDate=${selectedDate2}&page=${
        page - 1
      }&limit=${limit}`)(dispatch);
  }, [auth?.token, dispatch, shopId,selectedDate,selectedDate2]);
  return (
    <div className="text-black">
      <div className="flex justify-between">
        <h1 className="text-[#030229d8] text-xl pt-2">No Sales Report</h1>
        <div className="flex gap-2">
          <p className="pt-2">From</p>
          <DatePicker defaultValue={dayjs(formattedDate)} onChange={onChange} />
          <p className="pt-2">To</p>
          <DatePicker defaultValue={dayjs(formattedDate)} onChange={onChangeDate}/>
          {/* <PdfIcons className="w-8 h-8 cursor-not-allowed" />
          <CsvIcons className="w-8 h-8 cursor-not-allowed" /> */}
        </div>
      </div>
      <div className="flex flex-wrap gap-5 mt-8">
        <OverViewCard text="Total No Sales" icon={<BoxIcons />} total={pos?.isFetching? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />:pos?.noSaleStats?.data?.Overview?.TotalNoSales
} />
        <OverViewCard text="Total Quantity" icon={<BoxIcons />} total={pos?.isFetching? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />:pos?.noSaleStats?.data?.Overview?.TotalQuantity} />
        <OverViewCard text="Total Amount" icon={<BoxIcons />} total={pos?.isFetching? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />:pos?.noSaleStats?.data?.Overview?.TotalAmount} />
        <OverViewCard
          text={pos?.isFetching? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />:pos?.noSaleStats?.data?.Overview?.MostNoSaleReasons}
          icon={<div className="w-[40px] h-[40px] rounded-md bg-[#E86C6E] flex justify-center items-center"><NotifiactionsIcons /></div>}
          total={<h1 className="text-lg">Most No Sales Reasons</h1>}
        />
      </div>
      <div>
        <div className="flex justify-between my-5">
          <div className="flex gap-3">
            <CustomButton
              // title={showFilter ? "" : "filter"}
              textColor="[#030229B2]"
              icon={<IoFilterOutline size={20} />}
              bgColor={"white"}
              border="[#0302291A]"
              // onClick={() => setShowFilter(!showFilter)}
            />
            {showFilter && (
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="filter by Model "
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "1",
                    label: "Pop 8 (BC6)"
                  },
                  {
                    value: "2",
                    label: "Spark 20"
                  },
                  {
                    value: "3",
                    label: "Pop7"
                  }
                ]}
              />
            )}
          </div>
          <div className="flex gap-5">
            {/* <Search
              placeholder="input search text"
              onSearch={(value, _e) => console.log(value)}
              style={{ width: 200, marginTop: "5px" }}
              prefix={
                <SearchOutlined
                  style={{ color: "#1677ff", fontSize: "16px" }}
                />
              }
            /> */}
            <div className="bg-[#605BFF] flex justify-center items-center h-[30px] w-[30px] mt-1.5 rounded-md">
              <GoListUnordered size={23} color="#ffff"/>
            </div>
            <div className="bg-white flex justify-center items-center h-[30px] w-[30px] mt-1.5 rounded-md">
              <TbGridDots size={23} color="#C0C0C9" />
            </div>
            <hr className="h-7 w-0.5 bg-gray-300 mt-1.5"/>
          </div>
        </div>
      </div>
      <div>
        <NoSalesTable selectedDate={selectedDate} selectedDate2={selectedDate2} setPage={setPage} limit={limit} setLimit={setLimit}/>
      </div>
    </div>
  );
};

export default NoSales;
