import React, { useEffect, useState } from "react";
import CustomerCard from "./CustomerCard";
import ProductCard from "./productCard";
import CartItems from "./CartItems";
import CustomButton from "../../../components/buttons/CustomButton";
import OrderCompletedCard from "./OrderCompletedCard";
import { Select, Skeleton, Tooltip, notification } from "antd";
import { DatePickerProps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { myCustomerActions } from "../../../store/customer";
import { useParams } from "react-router-dom";
import {
  getShopStockCartAction,
  updateShopStockCartAction,
  updateShopStockCartDetailsAction,
  updateWareHouseDetailsAction,
} from "../../../store/wareHouse/actions";
import PaymentProcess from "./PaymentProcess";
import { myWareHouseActions } from "../../../store/wareHouse";
import { myPosActions } from "../../../store/pos";
import {
  checkoutCartAction,
  generateFirstInvoiceAction,
} from "../../../store/pos/actions";
import {
  getActivePlan,
  getActivePrice,
  getActiveShop,
  getMomoPayments,
  getTotalPaidAmount,
  getTotalPrice,
  toIsoDate,
} from "../../../utils/converter";
import dayjs from "dayjs";
import { myLayoutActions } from "../../../store/layout";

const CartInformation = (props: any) => {
  const { wareHouse, auth, customer, pos, layout, appSettings } = useSelector(
    (state: any) => state
  );
  const [isCompleteOrder, setIsCompleteOrder] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRRAModalOpen, setIsRRAModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentMomoModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { cartId } = useParams();
  const [paymentType, setPaymentType] = useState("");
  // const [isMacyeMacye, setIsMacyeMacye] = useState(() => {
  //   const hasMacyeMacye = wareHouse?.createdCart?.data?.payment?.some(
  //     (payment: any) =>
  //       [
  //         "Macye_Macye",
  //         "Vuba_Vuba",
  //         "Intelligra_Insurance",
  //         "Corporate_Sales",
  //         "Yello_Payment",
  //         "Samphone_Staff_Handset_Requisition",
  //       ].includes(payment?.mode)
  //   );
  //   return hasMacyeMacye;
  // });
  const [uploadContract, setUploadContract] = useState<any | null>(null);
  const [customerPay, setCustomerPay] = useState(0);

  const [termsAndConditionModalOpen, setTermsAndConditionModalOpen] =
    useState(false);
  const [active, setActive] = useState(
    wareHouse?.createdCart?.data?.subscriptionPlan
  );
  const [isNewItemAdded, setIsNewItemAdded] = useState<any>(false);
  const paidAmount = getTotalPaidAmount(wareHouse?.createdCart?.data?.payment);
  const itemsList = wareHouse?.createdCart?.data?.list;
  const discount = wareHouse?.createdCart?.data?.discount;
  const isCartEmpty = wareHouse?.createdCart?.data?.list?.length < 1;
  const plan = wareHouse?.createdCart?.data?.subscriptionPlan;
  const handleOpenTermAndContions = () => {
    setTermsAndConditionModalOpen(true);
  };
  const onCloseTermAndContions = () => {
    setTermsAndConditionModalOpen(false);
  };
  const handlePaymentType = (value: any) => {
    setPaymentType(value);
  };
  const handleCompleteOder = () => {
    setIsCompleteOrder(true);
  };
  const [isConfirmOrder, setIsConfirmOrder] = useState(false);
  const handleConfirmOder = () => {
    setIsConfirmOrder(true);
  };
  let totalPrices = 0;
  // useEffect(() => {
  //   if (paymentType !== "") {
  //     setIsMacyeMacye(
  //       [
  //         "Macye_Macye",
  //         "Vuba_Vuba",
  //         "Intelligra_Insurance",
  //         "Corporate_Sales",
  //         "Yello_Payment",
  //         "Samphone_Staff_Handset_Requisition",
  //       ].includes(paymentType)
  //         ? true
  //         : false
  //     );
  //   }
  // }, [paymentType]);
  const isMacyeMacye = () => {
    const hasMacyeMacye = wareHouse?.createdCart?.data?.payment?.some(
      (payment: any) =>
        [
          "Macye_Macye",
          "Vuba_Vuba",
          "Intelligra_Insurance",
          "Corporate_Sales",
          "Yello_Payment",
          "Samphone_Staff_Handset_Requisition",
        ].includes(payment?.mode)
    );
    return hasMacyeMacye;
  };
  const [confirmationDate, setConfirmationDate] = useState(
    dayjs().format("DD/MM/YYYY")
  );
  const [current, setCurrent] = useState(0);
  const isSubscripitonPage =
    wareHouse?.createdCart?.data?.isSubscription === "subscription";

  const subsPlans = wareHouse?.createdCart?.data?.list?.map(
    (plan: any, index: number) => plan?.priceProduct?.subscriptionPlan
  );

  const ActivePlan = getActivePlan(
    subsPlans,
    wareHouse?.createdCart?.data?.subscriptionPlan
  );

  const discountPerc = wareHouse?.createdCart?.data?.discount?.discountPerc;
  const totalPrice = getTotalPrice(wareHouse?.createdCart?.data?.list);
  const payment = getTotalPaidAmount(wareHouse?.createdCart?.data?.payment);
  const finalAmount = isSubscripitonPage
    ? `${
        wareHouse?.createdCart?.data?.initialPaymentType === "b2c_prepaid"
          ? ActivePlan && ActivePlan.at(0)?.initialPayment
          : `${
              wareHouse?.createdCart?.data?.instalmentPlan === "daily"
                ? ActivePlan && ActivePlan.at(0)?.dailyInstalment
                : wareHouse?.createdCart?.data?.instalmentPlan === "weekly"
                ? ActivePlan && ActivePlan.at(0)?.weeklyInstalment
                : (ActivePlan && ActivePlan.at(0)?.monthlyInstalment) || 0
            }`
      }`
    : totalPrice - totalPrice * (discountPerc / 100);

  const onChangeDate: DatePickerProps["onChange"] = async (
    date,
    dateString
  ) => {
    setConfirmationDate(dateString);
    // setConfirmationDate(dateString);
  };

  useEffect(() => {
    auth?.token && getShopStockCartAction(auth?.token, cartId)(dispatch);
  }, [auth?.token, cartId, dispatch]);

  useEffect(() => {
    if (customer?.searchResult?.length > 0) {
      auth?.token &&
        updateShopStockCartDetailsAction(auth?.token, cartId, {
          customer: customer?.searchResult[0]?._id,
        })(dispatch);
    }
  }, [cartId, dispatch, auth?.token]);

  useEffect(() => {
    if (pos?.paymentInitiationStatus) {
      setIsModalOpen(false);
      setIsPaymentMomoModalOpen(true);
    } else {
      setIsPaymentMomoModalOpen(false);
    }
  }, [pos.paymentInitiationStatus]);
  const addToCart = async () => {
    const product = wareHouse?.searchResult?.data?.product;
    const payload = {
      cart: cartId,
      shopStockItem: product?._id,
      warehouseProduct: product?.wareHouseItem?._id,
      channel: product?.shop?.channel?._id,
      shop: product?.shop?._id,
      priceProduct: product?.requestedItem?.product?._id,
      payment: {
        amount: +getActivePrice(product?.requestedItem?.product?.prices),
        extendedWarranty: 0,
        installementPayment: `${
          wareHouse?.createdCart?.data?.initialPaymentType === "b2c_prepaid"
            ? ActivePlan && ActivePlan.at(0)?.initialPayment
            : `${
                wareHouse?.createdCart?.data?.instalmentPlan === "daily"
                  ? ActivePlan && ActivePlan.at(0)?.dailyInstalment
                  : wareHouse?.createdCart?.data?.instalmentPlan === "weekly"
                  ? ActivePlan && ActivePlan.at(0)?.weeklyInstalment
                  : (ActivePlan && ActivePlan.at(0)?.monthlyInstalment) || 0
              }`
        }`,
      },
    };

    auth?.token &&
      (await updateShopStockCartAction(auth?.token, cartId, payload)(dispatch));
    dispatch(myWareHouseActions.setSearchResult(null));
    await updateWareHouseDetailsAction(
      auth?.token,
      product?.wareHouseItem?._id,
      {
        dataActivated: false,
      }
    )(dispatch);
  };
  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.user?.shop?.shopId;

  const handleCheckout = async () => {
    if (payment === undefined) {
      notification.warning({ message: "Please provide payments!" });
    } else if (!wareHouse?.createdCart?.data?.customer) {
      notification.warning({ message: "Please add a customer!" });
    } else if (+finalAmount === payment || isMacyeMacye()) {
      if (
        getMomoPayments(wareHouse).length > 0 &&
        process.env.REACT_APP_ShopId_with_Momo_Implementation?.includes(
          shopId
        ) &&
        !layout?.isDisabledMomo
      ) {
        setIsModalOpen(true);
      } else {
        if (auth?.token) {
          const response = await checkoutCartAction(
            auth?.token,
            cartId as string,
            {
              status: "paid",
              paidOn: confirmationDate, //toIsoDate is removed
            }
          )(dispatch);

          // if (res) {
          //   await generateEBMInvoiceAction(
          //     auth?.token,
          //     {
          //       cartId: cartId,
          //     },
          //     cartId as string
          //   )(dispatch);
          // }
          if (
            response &&
            process.env.REACT_APP_Save_The_Children_Channel_ID !== undefined
          ) {
            await generateFirstInvoiceAction(
              auth?.token,
              cartId as string
            )(dispatch);
          }

          if (response) {
            dispatch(myLayoutActions.setSetConfirmCheckout(true));
          }
        }

        // navigate("/");
        // setIsRRAModalOpen(true);
        if (pos?.checkoutResponseStatus === 200) {
          setCurrent(current + 1);
        }
      }
    } else {
      notification.error({
        message: "Paid amount must be equal to total amount!",
      });
    }
  };

  useEffect(() => {
    if (
      wareHouse?.createdCart?.data?.status?.toLocaleLowerCase() === "paid" ||
      wareHouse?.createdCart?.data?.status?.toLocaleLowerCase() === "cancelled"
    ) {
      setCurrent(1);
    }
  }, [wareHouse?.createdCart?.data?.status]);

  useEffect(() => {
    dispatch(myCustomerActions.setSelected(null));
  }, []);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handlePaymentCancel = () => {
    setIsPaymentMomoModalOpen(false);
    dispatch(myPosActions.setPaymentInitiationStatus(false));
  };
  return (
    <>
    
      <div className="overflow-y-auto h-full w-full relative">
        {wareHouse?.createdCart?.data?.customer && (
          <>
            <h1 className="text-base font-medium text-[#030229] py-2">
              Customer Details
            </h1>
            <CustomerCard />
          </>
        )}
        {wareHouse?.createdCart?.data?.list && (
          <h1 className="text-base font-medium text-[#030229] py-4">
            CART ITEMS
          </h1>
        )}
        {!isCompleteOrder ? ( 
          <>
            <div className="">
              <CartItems data={wareHouse?.createdCart?.data?.list} />
            </div>
            <h1 className="text-base font-medium text-[#030229] pt-4">
              TOTAL AMOUNT:
              {wareHouse?.createdCart?.data?.list?.forEach((d: any) => {
                const itemPrice = d?.payment?.amount;
                const totalPriceForItem =
                  itemPrice + (itemPrice * d?.payment?.extendedWarranty) / 100;
                totalPrices += totalPriceForItem;
              })}
              {totalPrices}
            </h1>
            <h1 className="text-base font-medium text-[#030229] pt-1">
              ITEMS: {wareHouse?.createdCart?.data?.list?.length}{" "}
            </h1>
          </>
        ) : (
          wareHouse?.createdCart?.data?.list && (
            <>
              <div className="pr-5">
                <OrderCompletedCard data={wareHouse?.createdCart?.data?.list} />
                
              </div>
              <PaymentProcess
                isNewItemAdded={isNewItemAdded}
                setIsNewItemAdded={setIsNewItemAdded}
                addToCart={addToCart}
                confirmationDate={confirmationDate}
                onChangeDate={onChangeDate}
                handleCheckout={handleCheckout}
                disableCheckout={+finalAmount !== payment}
              />
            </>
          )
        )}
        {!isCompleteOrder && (
          <div className="absolute bottom-0 w-full px-20">
            {wareHouse?.createdCart?.data?.customer ? (
              <button
                className={`text-base font-semibold bg-[#5F5AFE] text-center w-full rounded-md py-2 text-white cursor-pointer`}
                onClick={handleCompleteOder}
              >
                Next
              </button>
            ) : (
              <Tooltip
                placement="top"
                title={"Please Customer is Required"}
                color="#5F5AFE"
              >
                <button
                  className={`text-base font-semibold bg-[#E5E5E5] text-center w-full rounded-md py-2 text-black cursor-not-allowed`}
                  onClick={handleCompleteOder}
                  disabled={!wareHouse?.createdCart?.data?.customer}
                >
                  Next
                </button>
              </Tooltip>
            )}
          </div>
        )}
      </div>
      {/* } */}
    </>
  );
};

export default CartInformation;
