import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import UploadComponent from "../../../../components/forms/Upload";
import {
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  notification,
  Radio,
  Spin,
  Tag,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { PlusOutlined } from "@ant-design/icons";
import { MinusCircleOutlined } from "@ant-design/icons";
import { LoadingButton } from "@mui/lab";
import EmptyCartImage from "../../../../assets/images/EmptyCart.png";
import dayjs from "dayjs";
import type { RadioChangeEvent } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ExclamationCircleOutlined } from '@ant-design/icons';
// import UploadComponent from "../../../../components/forms/Upload";
import UploadComponent from "../../../components/forms/Upload";

import "../../../styles/model.css";
import { getActivePlan, getActiveShop, getTotalPaidAmount, getTotalPrice } from "../../../utils/converter";
import { getShopStockCartAction, updateShopStockCartDetailsAction, updateShopStockCartItemDetailsAction } from "../../../store/wareHouse/actions";
import { createAccountAction, uploadContactction } from "../../../store/pos/actions";
import { searchValue } from "../../../utils/setColor";
import UploadAny from "../../../components/forms/UploadAny";
import DataPlan from "../POS/steps/dataPlan";
import PlansRadioGroup from "../POS/steps/PlansRadioGroup";
import Switches from "../../../components/buttons/Switches";
import TermsAndConditionForm from "../../../components/forms/TermsAndConditionForm";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const antIcon = <LoadingOutlined style={{ fontSize: 44 }} spin />;
const PaymentProcess: React.FC<{
  addToCart?: any;
  setIsNewItemAdded?: any;
  isNewItemAdded?: any;
  onChangeDate?: any;
  confirmationDate?: any;
  handleCheckout?: any;
  disableCheckout?: boolean;
}> = ({
  addToCart,
  setIsNewItemAdded,
  isNewItemAdded,
  onChangeDate,
  confirmationDate,
  handleCheckout,
  disableCheckout,
}) => {
  const { auth, wareHouse, pos, customer, appSettings } = useSelector(
    (state: any) => state
  );
  const [isMacyeMacye, setIsMacyeMacye] = useState(() => {
    const hasMacyeMacye = wareHouse?.createdCart?.data?.payment?.some(
      (payment: any) =>
        [
          "Macye_Macye",
          "Vuba_Vuba",
          "Intelligra_Insurance",
          "Corporate_Sales",
          "Yello_Payment",
          "Samphone_Staff_Handset_Requisition",
        ].includes(payment?.mode)
    );
    return hasMacyeMacye;
  });

  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.user?.shop?.shopId;

  const channelId = auth?.user?.shop?.channel || auth?.user?.shop?.channelId;

  const [paymentType, setPaymentType] = useState("");
  const [vubavubaProf, setvubavubaProof] = useState<any[]>([]);
  const [intelligraProof, setIntelligraProof] = useState<any[]>([]);
  const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);
  const [loadingSpiner, setLoadingSpiner] = useState(false);
  const [planType, setPlanType] = useState(
    wareHouse?.createdCart?.data?.initialPaymentType
  );
  const [uploadContract, setUploadContract] = useState<any | null>(null);
  const [customerPay, setCustomerPay] = useState(0);

  const [termsAndConditionModalOpen, setTermsAndConditionModalOpen] =
    useState(false);
  const [active, setActive] = useState(
    wareHouse?.createdCart?.data?.subscriptionPlan
  );

  const [activeInstalment, setActiveInstalment] = useState(
    wareHouse?.createdCart?.data?.instalmentPlan
  );

  const { cartId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const subsPlans = wareHouse?.createdCart?.data?.list?.map(
    (plan: any, index: number) => plan?.priceProduct?.subscriptionPlan
  );

  const ActivePlan = getActivePlan(
    subsPlans,
    wareHouse?.createdCart?.data?.subscriptionPlan
  );

  const paidAmount = getTotalPaidAmount(wareHouse?.createdCart?.data?.payment);
  const itemsList = wareHouse?.createdCart?.data?.list;
  const discount = wareHouse?.createdCart?.data?.discount;
  const isCartEmpty = wareHouse?.createdCart?.data?.list?.length < 1;
  const plan = wareHouse?.createdCart?.data?.subscriptionPlan;
  const isSubscripitonPage =
    wareHouse?.createdCart?.data?.isSubscription === "subscription";

  const handleOpenTermAndContions = () => {
    setTermsAndConditionModalOpen(true);
  };
  const onCloseTermAndContions = () => {
    setTermsAndConditionModalOpen(false);
  };
  const handleDiscountChange = async (e: any) => {
    const discount = {
      discounType: e,
      discountPerc: e?.split("-")[1],
    };
    auth?.token &&
      (await updateShopStockCartDetailsAction(auth?.token, cartId, {
        discount,
      })(dispatch));
    auth?.token &&
      (await updateShopStockCartDetailsAction(auth?.token, cartId, {
        discount,
      })(dispatch));
  };

  useEffect(() => {
    if (["Macye_Macye", "Intelligra_Insurance"].includes(paymentType)) {
      const discount = {
        discounType: "default-0",
        discountPerc: "0",
      };
      auth?.token &&
        updateShopStockCartDetailsAction(auth?.token, cartId, {
          discount,
        })(dispatch);
    }
  }, [paymentType]);

  const onChangeInitialPayType = async (value: any) => {
    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      initialPaymentType: value,
    })(dispatch);
    getShopStockCartAction(auth?.token, cartId)(dispatch);
    setPlanType(value);
  };

  const fileUpload = async (newFiles: any) => {
    const discount = {
      discounType: wareHouse?.createdCart?.data?.discount?.discounType,
      discountPerc: wareHouse?.createdCart?.data?.discount?.discountPerc,
      proof: [...newFiles.map((pic: any) => pic?.response?.data?.secure_url)],
    };
    auth?.token &&
      (await updateShopStockCartDetailsAction(auth?.token, cartId, {
        discount,
      })(dispatch));
  };

  const onFinish = async (values: any) => {
    // if([].includes(paymentType))
    const updatedPayment = values.payment.map((item: any) => ({
      ...item,
      amount:
        paymentType === "Intelligra_Insurance" ? customerPay : +item?.amount,
      reference:
        vubavubaProf?.length > 0
          ? [
              ...vubavubaProf?.map(
                (image: any) => image?.response?.data?.secure_url
              ),
            ]
          : [
              ...intelligraProof?.map(
                (image: any) => image?.response?.data?.secure_url
              ),
            ],
    }));

    if (
      updatedPayment[0]?.mode === "Vuba_Vuba" &&
      !updatedPayment[0]?.reference
    ) {
      return notification.warning({ message: "Upload Delivery note" });
    }

    auth?.token &&
      (await updateShopStockCartDetailsAction(auth?.token, cartId, {
        payment: updatedPayment,
      })(dispatch));
  };

  const handlePaymentType = (value: any) => {
    setPaymentType(value);
  };

  useEffect(() => {
    if (paymentType !== "") {
      setIsMacyeMacye(
        [
          "Macye_Macye",
          "Vuba_Vuba",
          "Intelligra_Insurance",
          "Corporate_Sales",
          "Yello_Payment",
          "Samphone_Staff_Handset_Requisition",
        ].includes(paymentType)
          ? true
          : false
      );
    }
  }, [paymentType]);

  const onChange = async (e: RadioChangeEvent) => {
    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      subscriptionPlan: e.target.value,
    })(dispatch);
    setActive(e.target.value);
  };

  const onInstalmentChange = async (e: RadioChangeEvent) => {
    setActiveInstalment(e.target.value);
    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      instalmentPlan: e.target.value,
    })(dispatch);
  };

  useEffect(() => {
    if (active && activeInstalment === "weekly") {
      const payload = {
        payment: {
          ...itemsList[0]?.payment,
          amount: ActivePlan && ActivePlan?.at(0)?.initialPayment,
          installementPayment:
            ActivePlan && ActivePlan?.at(0)?.weeklyInstalment,
        },
      };

      auth?.token &&
        updateShopStockCartItemDetailsAction(
          auth?.token,
          itemsList[0]?._id,
          payload
        )(dispatch);
    }
  }, [active, auth?.token, dispatch, activeInstalment]);

  const getTotalPrices = (list: any, subscritionPlan: number) => {
    return list
      ?.map(
        (item: any, _index: number) =>
          item?.payment?.amount +
          (item?.payment?.amount * item?.payment?.extendedWarranty) / 100
      )
      ?.reduce((total: any, price: any) => (total += parseInt(price)), 0);
  };

  const handleSendRequest = async () => {
    if (planType === "b2c_postpaid") {
      await updateShopStockCartDetailsAction(auth?.token, cartId, {
        isSubscriptionSubmitted: true,
        status: "accepted",
      })(dispatch);
      await createAccountAction(auth?.token, cartId as string)(dispatch);
      await getShopStockCartAction(auth?.token, cartId)(dispatch);
      setLoadingSpiner(false);
    } else {
      setLoadingSpiner(true);
      await updateShopStockCartDetailsAction(auth?.token, cartId, {
        isSubscriptionSubmitted: true,
        status: "requested",
      })(dispatch);
      await createAccountAction(auth?.token, cartId as string)(dispatch);
      navigate("/pos/sale");
      notification.success({ message: "Request Sent Successfully" });
      setLoadingSpiner(false);
    }
  };

  const options = [
    {
      value: "MoMo_Pay",
      label: "MoMo Pay",
    },
    {
      value: "Macye_Macye",
      label: "Macye Macye",
    },
    {
      value: "Intelligra_Insurance",
      label: "Intelligra Insurance",
    },
    {
      value: "Vuba_Vuba",
      label: "Vuba Vuba",
    },
    {
      value: "Samphone_POS",
      label: "Samphone POS",
    },
    {
      value: "MTN_POS",
      label: "MTN POS",
    },
    {
      value: "Samphone_Bank_Deposit",
      label: "Samphone Bank Deposit",
    },
    {
      value: "MTN_Bank_Deposit",
      label: "MTN Bank Deposit",
    },
    {
      value: "Corporate_Sales",
      label: "Corporate Sales",
    },
    {
      value: "MTN_Staff_Device_Requisition",
      label: "MTN Staff Device Requisition",
    },
    {
      value: "Yello_Payment",
      label: "Yello Payment",
    },
    {
      value: "Samphone_Staff_Handset_Requisition",
      label: "Samphone Staff handset Requisition",
    },
  ];

  const filteredOptions = options.filter((option) => {
    if (wareHouse?.createdCart?.data?.isSubscriptionSubmitted) {
      return (
        option.value !== "Macye_Macye" &&
        option.value !== "Vuba_Vuba" &&
        option.value !== "Corporate_Sales" &&
        option.value !== "Intelligra_Insurance" &&
        option.value !== "Yello_Payment" &&
        option.value !== "Samphone_Staff_Handset_Requisition"
      );
    }
    return true;
  });

  //here to update based on instalment and initial payment type
  const calculatePaymentAmount = () => {
    if (wareHouse?.createdCart?.data?.instalmentPlan === "weekly") {
      return ActivePlan?.at(0)?.weeklyInstalment || 0;
    } else if (wareHouse?.createdCart?.data?.instalmentPlan === "daily") {
      return ActivePlan?.at(0)?.dailyInstalment || 0;
    } else if (wareHouse?.createdCart?.data?.instalmentPlan === "monthly") {
      return ActivePlan?.at(0)?.monthlyInstalment || 0;
    }
    return 0;
  };
  const amountToPay = calculatePaymentAmount();

  useEffect(() => {
    if (!wareHouse?.createdCart?.data?.initialPaymentType) {
      return;
    }
    if (activeInstalment && itemsList) {
      const payload = {
        payment: {
          ...itemsList[0]?.payment,
          amount:
            planType === "b2c_postpaid"
              ? amountToPay
              : ActivePlan && ActivePlan.at(0)?.initialPayment,
          installementPayment:
            ActivePlan &&
            (activeInstalment === "daily"
              ? ActivePlan.at(0)?.dailyInstalment
              : activeInstalment === "weekly"
              ? ActivePlan.at(0)?.weeklyInstalment
              : activeInstalment === "monthly"
              ? ActivePlan.at(0)?.monthlyInstalment
              : 0.1),
        },
      };
      auth?.token &&
        updateShopStockCartItemDetailsAction(
          auth?.token,
          itemsList[0]?._id,
          payload
        )(dispatch);
    }
  }, [planType, auth?.token, dispatch, amountToPay]);

  const onchangeIntelligraAmount = (e: any) => {
    setCustomerPay(
      wareHouse?.createdCart?.data?.list[0]?.payment?.amount - e.target.value
    );
  };

  useEffect(() => {
    form.setFieldsValue({ customerAmount: customerPay });
  }, [customerPay]);

  const availablePlan =
    wareHouse?.createdCart?.data?.list &&
    wareHouse?.createdCart?.data?.list?.flatMap((item: any) =>
      item?.priceProduct?.subscriptionPlan?.filter(
        (d: any) => d?.duration === plan
      )
    );

  const onChangeBundleSelected = async (value: any) => {
    const payload = { ...wareHouse?.createdCart?.data?.other, bundle: value };
    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      other: payload,
    })(dispatch);
    getShopStockCartAction(auth?.token, cartId)(dispatch);
  };

  const onChangeSMSSelected = async (value: any) => {
    const payload = { ...wareHouse?.createdCart?.data?.other, sms: value };
    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      other: payload,
    })(dispatch);
    getShopStockCartAction(auth?.token, cartId)(dispatch);
  };

  const onChangeCallSelected = async (value: any) => {
    const payload = { ...wareHouse?.createdCart?.data?.other, call: value };
    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      other: payload,
    })(dispatch);
    getShopStockCartAction(auth?.token, cartId)(dispatch);
  };

  const onChangePeriodSelected = async (value: any) => {
    const payload = { ...wareHouse?.createdCart?.data?.other, period: value };

    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      other: payload,
    })(dispatch);
    getShopStockCartAction(auth?.token, cartId)(dispatch);
  };

  const handleSubmitContact = async () => {
    const payload = [
      ...(wareHouse?.createdCart?.data?.account?.documents ?? []),
    ];
    const newData = {
      name: "contract",
      tag: "contract",
      docLink: uploadContract && uploadContract[0]?.response?.data?.secure_url,
    };
    payload.push(newData);
    if (wareHouse?.createdCart?.data?.account?._id) {
      await uploadContactction(
        auth.token,
        wareHouse?.createdCart?.data?.account?._id,
        {
          isContractSubmitted: true,
          documents: payload,
        }
      )(dispatch);
      await getShopStockCartAction(auth?.token, cartId)(dispatch);
    } else {
      notification.error({ message: "Account not found" });
    }
  };
  const hasNullValues = discount?.proof
    .map((pic: any) => pic)
    .some((item: any) => item === null || item === undefined);

  return (
    <>
      <div className="flex h-full leading-none gap-x-[0.7vw]">
        <div className="flex-1 p-5 bg-white rounded-lg overflow-auto relative">
          {!isCartEmpty && (
            <div>
              {wareHouse?.createdCart?.data?.isSubscription === "pos" ||
              ["accepted", "rejected"]?.includes(
                wareHouse?.createdCart?.data?.status
              ) ? (
                <div>
                  {/* Discount section */}
                  <div className="space-y-2 mb-4 gap-2">
                    <div className="flex flex-wrap items-center gap-2">
                      <div className="space-y-2 w-full">
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          // className="border border-[#03022940] rounded-md"
                          placeholder="Select Discount Type"
                          optionFilterProp="children"
                          disabled={
                            ["Macye_Macye", "Intelligra_Insurance"]?.includes(
                              paymentType
                            ) ||
                            ["accepted", "rejected"]?.includes(
                              wareHouse?.createdCart?.data?.status
                            )
                          }
                          defaultValue={
                            wareHouse?.createdCart?.data?.discount
                              ?.discounType === "private"
                              ? "Select discount type"
                              : wareHouse?.createdCart?.data?.discount
                                  ?.discounType
                          }
                          filterOption={(input, option) =>
                            (option?.label ?? "").includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          onChange={handleDiscountChange}
                          options={[
                            {
                              value: "default-0",
                              label: "None",
                            },
                            {
                              value: "prestige-5",
                              label: "Prestige",
                            },
                            {
                              value: "mtn_staff-10",
                              label: "MTN Staff",
                            },
                            {
                              value: "momo_staff-10",
                              label: "MoMo Staff",
                            },
                          ]}
                        />
                      </div>
                    </div>
                    {wareHouse?.createdCart?.data?.discount?.discounType !==
                      "private" &&
                      wareHouse?.createdCart?.data?.discount?.discounType !==
                        "default-0" && (
                        <div className="flex items-center w-full gap-2">
                          <p className="min-w-max">Proof Document</p>
                          <UploadAny
                            setPictures={fileUpload}
                            isCard="picture-card"
                            limit={5}
                            // default={
                            //   wareHouse?.createdCart?.data?.discount && [
                            //     {
                            //       status: "done",
                            //       url: wareHouse?.createdCart?.data?.discount
                            //         ?.proof[0],
                            //     },
                            //   ]
                            // }
                          />
                        </div>
                      )}
                  </div>

                  {/* subscription info */}
                  {wareHouse?.createdCart?.data?.isSubscription ===
                    "subscription" && (
                    <div>
                      <p>Subscription duration</p>
                      <hr className="mb-3 mt-1" />
                      <PlansRadioGroup active={active} disabled />
                      <p>Here is the payment plan</p>

                      {subsPlans &&
                        subsPlans[0]
                          ?.filter(
                            (plan: any) =>
                              plan?.duration ===
                              wareHouse?.createdCart?.data?.subscriptionPlan
                          )
                          ?.map((plan: any, index: number) => (
                            <div className="flex justify-start border-t pt-2">
                              <Radio.Group
                                onChange={onInstalmentChange}
                                value={activeInstalment}
                                className="flex flex-wrap pb-2"
                                disabled
                              >
                                <Radio
                                  value={"daily"}
                                  className={`w-full h-9 border pl-4 mb-2 rounded-tl-md rounded-br-md flex items-center ${
                                    activeInstalment === "daily" &&
                                    "border-yellow-400"
                                  }`}
                                >
                                  <div className="w-full xbg-red-400 flex justify-between">
                                    <p>Daily </p>
                                    {/* <span className="text-right flex-1">{`${plan?.initialPayment?.toLocaleString()} RWF`}</span> */}
                                  </div>
                                </Radio>

                                <Radio
                                  value={"weekly"}
                                  className={`w-full h-9 border pl-4 mb-2 rounded-tl-md rounded-br-md flex items-center ${
                                    activeInstalment === "weekly" &&
                                    "border-yellow-400"
                                  }`}
                                >
                                  <div className="w-full xbg-red-400 flex justify-between">
                                    <p>Weekly </p>
                                    {/* <span className="text-right">{`${plan?.weeklyInstalment?.toLocaleString()} RWF`}</span> */}
                                  </div>
                                </Radio>

                                <Radio
                                  value={"monthly"}
                                  className={`w-full h-9 border pl-4 mb-2 rounded-tl-md rounded-br-md flex items-center ${
                                    activeInstalment === "monthly" &&
                                    "border-yellow-400"
                                  }`}
                                >
                                  <div className="w-full xbg-red-400 flex justify-between">
                                    <p>Monthly </p>
                                    {/* <span className="text-right">{`${plan?.monthlyInstalment?.toLocaleString()} RWF`}</span> */}
                                  </div>
                                </Radio>

                                {/* instalment payments details */}
                                <div className="w-full mt-5 flex justify-between">
                                  {wareHouse?.createdCart?.data
                                    ?.instalmentPlan === "weekly" && (
                                    <>
                                      <span className="font-bold text-neutral-800">
                                        {/* {!disableCheckout && (
                                  <DoneAllIcon className="text-blue-500 mr-1" />
                                )} */}
                                        Weekly Payment Amount:
                                      </span>
                                      <span
                                        className={`text-right font-bold text-slate-800`}
                                      >{`${
                                        ActivePlan &&
                                        ActivePlan.at(
                                          0
                                        )?.weeklyInstalment?.toLocaleString()
                                      } RWF`}</span>
                                    </>
                                  )}
                                  {wareHouse?.createdCart?.data
                                    ?.instalmentPlan === "daily" && (
                                    <>
                                      <span className="font-bold text-neutral-800">
                                        {/* {!disableCheckout && (
                                  <DoneAllIcon className="text-blue-500 mr-1" />
                                )} */}
                                        Daily Payment Amount:
                                      </span>
                                      <span
                                        className={`text-right font-bold text-slate-800`}
                                      >{`${
                                        ActivePlan &&
                                        ActivePlan.at(
                                          0
                                        )?.dailyInstalment?.toLocaleString()
                                      } RWF`}</span>
                                    </>
                                  )}
                                  {wareHouse?.createdCart?.data
                                    ?.instalmentPlan === "monthly" && (
                                    <>
                                      <span className="font-bold text-neutral-800">
                                        {/* {!disableCheckout && (
                                  <DoneAllIcon className="text-blue-500 mr-1" />
                                )} */}
                                        Monthly Payment Amount:
                                      </span>
                                      <span
                                        className={`text-right font-bold text-slate-800`}
                                      >{`${
                                        (ActivePlan &&
                                          ActivePlan.at(
                                            0
                                          )?.monthlyInstalment?.toLocaleString()) ||
                                        0
                                      } RWF`}</span>
                                    </>
                                  )}
                                </div>

                                <div className="w-full mt-5 flex justify-between">
                                  <span className="font-bold text-neutral-800">
                                    {/* {!disableCheckout && (
                                  <DoneAllIcon className="text-blue-500 mr-1" />
                                )} */}
                                    Initial Payment Amount:
                                  </span>
                                  {/* <span
                                    className={`text-right font-bold text-slate-800`}
                                  >{`${getActivePlan(
                                    subsPlans,
                                    wareHouse?.createdCart?.data
                                      ?.subscriptionPlan
                                  )
                                    .at(0)
                                    ?.initialPayment?.toLocaleString()} RWF`}</span> */}
                                  <span
                                    className={`text-right font-bold text-slate-800`}
                                  >{`${
                                    planType === "b2c_prepaid"
                                      ? ActivePlan &&
                                        ActivePlan.at(
                                          0
                                        )?.initialPayment?.toLocaleString()
                                      : `${
                                          wareHouse?.createdCart?.data
                                            ?.instalmentPlan === "daily"
                                            ? ActivePlan &&
                                              ActivePlan.at(
                                                0
                                              )?.dailyInstalment?.toLocaleString()
                                            : wareHouse?.createdCart?.data
                                                ?.instalmentPlan === "weekly"
                                            ? ActivePlan &&
                                              ActivePlan.at(
                                                0
                                              )?.weeklyInstalment?.toLocaleString()
                                            : (ActivePlan &&
                                                ActivePlan.at(
                                                  0
                                                )?.monthlyInstalment?.toLocaleString()) ||
                                              0
                                        }`
                                  } RWF`}</span>
                                </div>
                              </Radio.Group>
                            </div>
                          ))}
                    </div>
                  )}
                  {/* Upload terms and conditions */}
                  {wareHouse?.createdCart?.data?.isSubscriptionSubmitted &&
                    wareHouse?.createdCart?.data?.other && <DataPlan />}

                  {wareHouse?.createdCart?.data?.isSubscriptionSubmitted &&
                    !wareHouse?.createdCart?.data?.acceptTermsAndConditions && (
                      <div>
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          onClick={handleOpenTermAndContions}
                        >
                          Terms & Conditions
                        </LoadingButton>
                      </div>
                    )}

                  {wareHouse?.createdCart?.data?.isSubscriptionSubmitted &&
                    wareHouse?.createdCart?.data?.acceptTermsAndConditions &&
                    !wareHouse?.createdCart?.data?.account
                      ?.isContractSubmitted && (
                      <div className="mt-2 pt-2">
                        <h1 className="pb-2 font-bold ">Upload Contract</h1>
                        <UploadComponent
                          setPictures={setUploadContract}
                          isCard={true}
                          s
                          limit={1}
                        />
                        <LoadingButton
                          variant="contained"
                          onClick={handleSubmitContact}
                          disabled={
                            uploadContract === null ||
                            (uploadContract &&
                              !uploadContract[0]?.response?.data?.secure_url)
                          }
                          loading={pos?.isFetching}
                        >
                          Next
                        </LoadingButton>
                      </div>
                    )}

                  {wareHouse?.createdCart?.data?.status !== "rejected" &&
                    (wareHouse?.createdCart?.data?.isSubscription === "pos" ||
                      wareHouse?.createdCart?.data?.account
                        ?.isContractSubmitted) && (
                      <>
                        {/* Payment section */}
                        {wareHouse?.createdCart?.status === 200 && (
                          <div className="relative pb-4 border p-2 rounded-lg">
                            <h3 className="mt-2 font-semibold my-3">
                              Payment Method
                            </h3>
                            <Form
                              {...formItemLayout}
                              form={form}
                              name="register"
                              onFinish={onFinish}
                              // initialValues={props.data || {}}
                              // style={{ minWidth: 600 }}
                              scrollToFirstError
                              className="lg:max-w-[600px]"
                            >
                              <Form.List
                                name="payment"
                                {...formItemLayout}
                                initialValue={[
                                  ...wareHouse?.createdCart?.data?.payment,
                                ]}
                                // initialValue={wareHouse?.createdCart?.data?.payment.map(
                                //   (item: object) => ({
                                //     ...item,
                                //     reference:
                                //       // vubavubaProf[0]?.response?.data?.secure_url ||
                                //       "hi!...", // Add the "reference" field with an initial value
                                //   })
                                // )}
                              >
                                {(fields, { add, remove }) => {
                                  return (
                                    <>
                                      {fields.map(
                                        ({ key, name, ...restField }) => (
                                          <div className="flex flex-col pb-1 border-b mb-3">
                                            <div className="flex items-center">
                                              <Form.Item
                                                {...restField}
                                                name={[name, "mode"]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: "Missing names",
                                                  },
                                                ]}
                                                // style={{ minWidth: 250 }}
                                                className="mb-1 mr-3"
                                              >
                                                <Select
                                                  onChange={handlePaymentType}
                                                  placeholder="Select a payment method"
                                                  // className="border border-[#03022940] rounded-md py-2"
                                                  optionFilterProp="children"
                                                  // onChange={onChange}
                                                  // onSearch={onSearch}
                                                  style={{ width: 200 }}
                                                  filterOption={(
                                                    input,
                                                    option
                                                  ) =>
                                                    (option?.label ?? "")
                                                      .toLowerCase()
                                                      .includes(
                                                        input.toLowerCase()
                                                      )
                                                  }
                                                  options={filteredOptions}
                                                />
                                              </Form.Item>
                                              <button
                                                onClick={() => remove(name)}
                                                className="flex rounded-md shadow gap-x-2 py-2 w-fit px-3 border text-red-500"
                                              >
                                                <MinusCircleOutlined className=" my-auto" />
                                                {/* <p>Remove method</p> */}
                                              </button>
                                            </div>

                                            {[
                                              "Macye_Macye",
                                              "Intelligra_Insurance",
                                              "Corporate_Sales",
                                              "Vuba_Vuba",
                                              "Yello_Payment",
                                              "Samphone_Staff_Handset_Requisition",
                                            ]?.includes(paymentType) && (
                                              <Form.Item
                                                {...restField}
                                                name={[name, "macyemacyeType"]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      "Missing payment type",
                                                  },
                                                ]}
                                                className="w-full mb-1"
                                              >
                                                <Select
                                                  // onChange={handlePaymentType}
                                                  placeholder="Select a payment method"
                                                  optionFilterProp="children"
                                                  // onChange={onChange}
                                                  // onSearch={onSearch}
                                                  className="py-2"
                                                  style={{ width: 200 }}
                                                  filterOption={(
                                                    input,
                                                    option
                                                  ) =>
                                                    (option?.label ?? "")
                                                      .toLowerCase()
                                                      .includes(
                                                        input.toLowerCase()
                                                      )
                                                  }
                                                  options={
                                                    paymentType ===
                                                    "Intelligra_Insurance"
                                                      ? [
                                                          {
                                                            value:
                                                              "partially_paid",
                                                            label:
                                                              "Patially Paid",
                                                          },
                                                        ]
                                                      : paymentType ===
                                                          "Corporate_Sales" ||
                                                        paymentType ===
                                                          "Vuba_Vuba"
                                                      ? [
                                                          {
                                                            value: "not_paid",
                                                            label: "Not Paid",
                                                          },
                                                          {
                                                            value: "fully_paid",
                                                            label: "Fully Paid",
                                                          },
                                                        ]
                                                      : [
                                                          {
                                                            value: "not_paid",
                                                            label: "Not Paid",
                                                          },
                                                          {
                                                            value:
                                                              "partially_paid",
                                                            label:
                                                              "Patially Paid",
                                                          },
                                                          {
                                                            value: "fully_paid",
                                                            label: "Fully Paid",
                                                          },
                                                          {
                                                            value: "over_paid",
                                                            label: "Over Paid",
                                                          },
                                                        ]
                                                  }
                                                />
                                              </Form.Item>
                                            )}

                                            {paymentType !==
                                              "Intelligra_Insurance" && (
                                              <Form.Item
                                                {...restField}
                                                name={[name, "amount"]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: "Missing amount",
                                                  },
                                                ]}
                                                className="w-full mb-1 font-medium text-[#030229]"
                                              >
                                                <Input
                                                  placeholder={"Amount"}
                                                  type="number"
                                                  width={"100%"}
                                                />
                                              </Form.Item>
                                            )}

                                            {paymentType ===
                                              "Intelligra_Insurance" && (
                                              <Form.Item
                                                {...restField}
                                                name={[
                                                  name,
                                                  "intelligraAmount",
                                                ]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: "Missing amount",
                                                  },
                                                ]}
                                                className="w-full mb-1"
                                              >
                                                <Input
                                                  placeholder={
                                                    "Intelligra Amount"
                                                  }
                                                  type="number"
                                                  width={"100%"}
                                                  onChange={
                                                    onchangeIntelligraAmount
                                                  }
                                                />
                                              </Form.Item>
                                            )}

                                            {["Intelligra_Insurance"]?.includes(
                                              paymentType
                                            ) && (
                                              <div>
                                                <label className="block text-sm font-medium text-gray-900">
                                                  Customer Pay
                                                </label>

                                                <input
                                                  value={customerPay}
                                                  type="text"
                                                  id="first_name"
                                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:outline-none focus:border-gray-300 block w-[67%] py-1.5 px-2 cursor-not-allowed mb-1"
                                                  placeholder="Customer Pay"
                                                  required
                                                />
                                              </div>
                                            )}

                                            {process.env.REACT_APP_ShopId_with_Momo_Implementation?.includes(
                                              shopId
                                            )
                                              ? ![
                                                  "Macye_Macye",
                                                  "Vuba_Vuba",
                                                  "MoMo_Pay",
                                                  "Yello_Payment",
                                                  "Samphone_Staff_Handset_Requisition",
                                                ].includes(paymentType) && (
                                                  <Form.Item
                                                    {...restField}
                                                    name={[
                                                      name,
                                                      "momoTransactioId",
                                                    ]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                          "Missing Transaction Id",
                                                      },
                                                    ]}
                                                    className="w-full mb-1"
                                                  >
                                                    <Input
                                                      placeholder="Transaction Id"
                                                      type="text"
                                                    />
                                                  </Form.Item>
                                                )
                                              : ![
                                                  "Macye_Macye",
                                                  "Vuba_Vuba",
                                                  "Yello_Payment",
                                                  "Samphone_Staff_Handset_Requisition",
                                                ].includes(paymentType) && (
                                                  <Form.Item
                                                    {...restField}
                                                    name={[
                                                      name,
                                                      "momoTransactioId",
                                                    ]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                          "Missing Transaction Id",
                                                      },
                                                    ]}
                                                    className="w-full mb-1"
                                                  >
                                                    <Input
                                                      placeholder="Transaction Id"
                                                      type="text"
                                                    />
                                                  </Form.Item>
                                                )}

                                            {[
                                              "Vuba_Vuba",
                                              "Corporate_Sales",
                                              "MTN_Bank_Deposit",
                                              "Samphone_Bank_Deposit",
                                              "MTN_POS",
                                              "Samphone_POS",
                                            ].includes(paymentType) && (
                                              <div className="items-center w-full gap-2">
                                                <p className="min-w-max">
                                                  Delivery Note / Proof
                                                </p>
                                                <Form.Item
                                                  {...restField}
                                                  name={[name, "reference"]}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message:
                                                        "Missing documents",
                                                    },
                                                  ]}
                                                >
                                                  <div className="w-full">
                                                    <UploadAny
                                                      setPictures={
                                                        setvubavubaProof
                                                      }
                                                      limit={5}
                                                      isCard
                                                      // default={
                                                      //   wareHouse?.createdCart?.data
                                                      //     ?.payment[0]?.reference
                                                      // }
                                                    />
                                                  </div>
                                                </Form.Item>
                                              </div>
                                            )}

                                            {["Intelligra_Insurance"].includes(
                                              paymentType ||
                                                wareHouse?.createdCart?.data
                                                  ?.payment[0]?.mode
                                            ) && (
                                              <div className="items-center w-full gap-2 pt-2">
                                                <p className="min-w-max font-semibold">
                                                  Intelligra Proof
                                                </p>
                                                <Form.Item
                                                  {...restField}
                                                  name={[name, "reference"]}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message:
                                                        "Intelligra Proof is required",
                                                    },
                                                  ]}
                                                >
                                                  <div className="w-full">
                                                    <UploadAny
                                                      setPictures={
                                                        setIntelligraProof
                                                      }
                                                      limit={2}
                                                      isCard
                                                      // default={
                                                      //   wareHouse?.createdCart?.data
                                                      //     ?.payment[0]?.reference
                                                      // }
                                                    />
                                                  </div>
                                                </Form.Item>
                                              </div>
                                            )}
                                          </div>
                                        )
                                      )}

                                      <Form.Item className="absolute top-3 right-3">
                                        <Button
                                          onClick={() => add()}
                                          // startIcon={<PlusOutlined />}
                                          disabled={isMacyeMacye}
                                          className="shadow !m-0 justify-between !items-center"
                                        >
                                          {/* Add Method */}
                                          <PlusOutlined />
                                        </Button>
                                      </Form.Item>
                                    </>
                                  );
                                }}
                              </Form.List>
                              <div className="flex items-center justify-end gap-2 max-w-[500px]">
                                <p>
                                  <span>Total paid</span>
                                </p>
                                <span className="text-right font-bold text-neutral-800 ">{`${
                                  paidAmount?.toLocaleString() || 0
                                } RWF`}</span>
                              </div>
                              <Form.Item>
                                {/* <div className="flex"> */}
                                <LoadingButton
                                  type="submit"
                                  variant="contained"
                                  // startIcon={<AddIcon />}
                                  //   sx={{ minWidth: "100%" }}
                                  loading={wareHouse?.isFetching}
                                >
                                  Confirm payments
                                </LoadingButton>
                                {/* </div> */}
                              </Form.Item>
                            </Form>
                          </div>
                        )}
                        {/* Selling Date */}
                        <div className="flex  items-center p-2 justify-between">
                          <p className="font-semibold">Selling date</p>
                          <DatePicker
                            defaultValue={dayjs(
                              confirmationDate?.toString(),
                              "DD/MM/YYYY"
                            )}
                            format="DD/MM/YYYY"
                            onChange={onChangeDate}
                            disabled={
                              wareHouse?.createdCart?.data?.status?.toLowerCase() ===
                              "paid"
                            }
                          />
                        </div>
                        {process.env.REACT_APP_ShopId_with_Momo_Implementation?.includes(
                          shopId
                        ) &&
                          ["admin", "dev"].includes(
                            auth?.user?.role?.toLowerCase()
                          ) && (
                            <div className="flex items-center p-2 justify-between">
                              <p className="font-semibold">
                                Disable MoMo Integration
                              </p>
                              <Switches />
                            </div>
                          )}

                        {/* Final amount to pay */}
                        <div className="flex justify-end border-t pt-2">
                          <div className="grid grid-cols-2 w-fit  gap-x-2">
                            <p>Total: </p>
                            <span className="text-right">{`${
                              isSubscripitonPage
                                ? `${
                                    planType === "b2c_prepaid"
                                      ? ActivePlan &&
                                        ActivePlan.at(
                                          0
                                        )?.initialPayment?.toLocaleString()
                                      : `${
                                          wareHouse?.createdCart?.data
                                            ?.instalmentPlan === "daily"
                                            ? ActivePlan &&
                                              ActivePlan.at(
                                                0
                                              )?.dailyInstalment?.toLocaleString()
                                            : wareHouse?.createdCart?.data
                                                ?.instalmentPlan === "weekly"
                                            ? ActivePlan &&
                                              ActivePlan.at(
                                                0
                                              )?.weeklyInstalment?.toLocaleString()
                                            : (ActivePlan &&
                                                ActivePlan.at(
                                                  0
                                                )?.monthlyInstalment?.toLocaleString()) ||
                                              0
                                        }`
                                  }`
                                : getTotalPrices(
                                    itemsList,
                                    plan
                                  )?.toLocaleString()
                            } RWF`}</span>
                            <p>Discount:</p>
                            <span className="text-right">{`(${
                              discount?.discountPerc
                            }%) ${(
                              getTotalPrice(itemsList) *
                              (discount?.discountPerc / 100)
                            )?.toLocaleString()} RWF`}</span>

                            <p className="flex items-center font-bold text-neutral-800 mt-5">
                              {/* {!disableCheckout && (
                      <DoneAllIcon className="text-blue-500 mr-1" />
                    )} */}
                              Final amount:
                            </p>

                            {/* hereeeeee! */}
                            <span
                              className={`text-right font-bold text-slate-800 mt-5`}
                            >{`${
                              isSubscripitonPage
                                ? `${
                                    planType === "b2c_prepaid"
                                      ? ActivePlan &&
                                        ActivePlan.at(
                                          0
                                        )?.initialPayment?.toLocaleString()
                                      : `${
                                          wareHouse?.createdCart?.data
                                            ?.instalmentPlan === "daily"
                                            ? ActivePlan &&
                                              ActivePlan.at(
                                                0
                                              )?.dailyInstalment?.toLocaleString()
                                            : wareHouse?.createdCart?.data
                                                ?.instalmentPlan === "weekly"
                                            ? ActivePlan &&
                                              ActivePlan.at(
                                                0
                                              )?.weeklyInstalment?.toLocaleString()
                                            : (ActivePlan &&
                                                ActivePlan.at(
                                                  0
                                                )?.monthlyInstalment?.toLocaleString()) ||
                                              0
                                        }`
                                  }`
                                : (
                                    getTotalPrices(itemsList, plan) -
                                    getTotalPrice(itemsList) *
                                      (discount?.discountPerc / 100)
                                  )?.toLocaleString()
                            } RWF`}</span>
                          </div>
                        </div>

                        {/* Actions */}
                        <div className="flex mt-10 mb-5d">
                          {/* handleCheckout */}
                          <LoadingButton
                            type="submit"
                            onClick={() => setIsCheckoutModalOpen(true)}
                            // onClick={handleCheckout}
                            variant="contained"
                            // startIcon={<AddIcon />}
                            //   sx={{ minWidth: "100%" }}
                            loading={pos?.isFetching}
                            className="w-full"
                            disabled={
                              (isMacyeMacye ? false : disableCheckout) ||
                              (discount?.discountPerc > 1 &&
                                discount?.proof.length < 1) ||
                              (discount?.discountPerc > 1 && hasNullValues) ||
                              !wareHouse?.createdCart?.data?.customer
                            }
                            // disabled={isMacyeMacye ? false : disableCheckout}
                          >
                            Checkout
                          </LoadingButton>
                        </div>
                      </>
                    )}
                  {wareHouse?.createdCart?.data?.status === "rejected" && (
                    <div className="!mt-5">
                      <p className="font-bold">Rejection Reason</p>
                      <hr className="mt-1 mb-3" />
                      <p className="text-red-400">
                        {wareHouse?.createdCart?.data?.rejectSubReason}
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                <div className="items-center">
                  {/* //here */}
                  <div className="flex flex-col pb-3 space-y-1">
                    <span>Subscription Type</span>
                    {/* <div>
                      <Radio.Group
                        onChange={onChange}
                        value={active}
                        className="flex flex-wrap pb-2"
                        disabled={
                          wareHouse?.createdCart?.data?.isSubscriptionSubmitted
                        }
                      >
                        {["b2c_prepaid", "b2c_postpaid"]?.map((d: any) => (
                          <Radio
                            value={d}
                            className={`w-44 h-14 border pl-4 mb-2 rounded-md border-gray-400 flex items-center xjustify-center ${
                              planType === d &&
                              "bg-[#7461e0] text-white"
                            }`}
                          >
                            {`${d}`}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </div> */}
                    <Select
                      showSearch
                      disabled={
                        wareHouse?.isFetching ||
                        wareHouse?.createdCart?.data?.isSubscriptionSubmitted
                      }
                      defaultValue={
                        wareHouse?.createdCart?.data?.initialPaymentType
                      }
                      style={{ width: 200 }}
                      placeholder="Select"
                      optionFilterProp="children"
                      options={[
                        {
                          value: "b2c_prepaid",
                        },
                        {
                          value: "b2c_postpaid",
                        },
                      ]}
                      onChange={onChangeInitialPayType}
                    />
                  </div>
                  <div>
                    <Radio.Group
                      onChange={onChange}
                      value={active}
                      className="flex flex-wrap pb-2"
                      disabled={
                        wareHouse?.createdCart?.data?.isSubscriptionSubmitted
                      }
                    >
                      {wareHouse?.createdCart?.data?.list &&
                        wareHouse?.createdCart?.data?.list[0]?.priceProduct?.subscriptionPlan?.map(
                          (d: any) => (
                            <Radio
                              value={d?.duration}
                              className={`w-44 h-14 border pl-4 mb-2 rounded-md border-gray-400 flex items-center xjustify-center ${
                                active === d?.duration &&
                                "bg-[#7461e0] text-white"
                              }`}
                            >
                              {`${d?.duration} Months`}
                            </Radio>
                          )
                        )}
                    </Radio.Group>
                  </div>

                  <div>
                    <p>Here is the payment plan</p>
                    {/* {JSON.stringify(subsPlans)} */}
                    {subsPlans &&
                      subsPlans[0]
                        ?.filter(
                          (plan: any) =>
                            plan?.duration ===
                            wareHouse?.createdCart?.data?.subscriptionPlan
                        )
                        ?.map((plan: any, index: number) => (
                          <div className="flex justify-start border-t pt-2">
                            {/* <div className="grid grid-cols-2 w-fit gap-y-2 gap-x-2"> */}
                            <Radio.Group
                              onChange={onInstalmentChange}
                              value={activeInstalment}
                              className="flex flex-wrap pb-2"
                            >
                              <Radio
                                value={"daily"}
                                className={`w-full h-9 border pl-4 mb-2 rounded-tl-md rounded-br-md flex items-center ${
                                  activeInstalment === "daily" &&
                                  "border-yellow-400"
                                }`}
                                disabled={
                                  plan?.dailyInstalment === undefined ||
                                  wareHouse?.createdCart?.data
                                    ?.isSubscriptionSubmitted
                                }
                              >
                                <div className="w-full xbg-red-400 flex justify-between">
                                  <p>Daily </p>
                                  {/* <span className="text-right flex-1">{`${plan?.initialPayment?.toLocaleString()} RWF`}</span> */}
                                </div>
                              </Radio>

                              <Radio
                                value={"weekly"}
                                className={`w-full h-9 border pl-4 mb-2 rounded-tl-md rounded-br-md flex items-center ${
                                  activeInstalment === "weekly" &&
                                  "border-yellow-400"
                                }`}
                                disabled={
                                  plan?.weeklyInstalment === undefined ||
                                  wareHouse?.createdCart?.data
                                    ?.isSubscriptionSubmitted
                                }
                              >
                                <div className="w-full xbg-red-400 flex justify-between">
                                  <p>Weekly </p>
                                  {/* <span className="text-right">{`${plan?.weeklyInstalment?.toLocaleString()} RWF`}</span> */}
                                </div>
                              </Radio>

                              <Radio
                                value={"monthly"}
                                className={`w-full h-9 border pl-4 mb-2 rounded-tl-md rounded-br-md flex items-center ${
                                  activeInstalment === "monthly" &&
                                  "border-yellow-400"
                                }`}
                                disabled={
                                  plan?.monthlyInstalment === undefined ||
                                  wareHouse?.createdCart?.data
                                    ?.isSubscriptionSubmitted
                                }
                              >
                                <div className="w-full xbg-red-400 flex justify-between">
                                  <p>Monthly</p>
                                  {/* <span className="text-right">{`${plan?.monthlyInstalment?.toLocaleString()} RWF`}</span> */}
                                </div>
                              </Radio>

                              {/* instalment payments details */}
                              <div className="w-full mt-5 flex justify-between">
                                {wareHouse?.createdCart?.data
                                  ?.instalmentPlan === "weekly" && (
                                  <>
                                    <span className="font-bold text-neutral-800">
                                      {/* {!disableCheckout && (
                                  <DoneAllIcon className="text-blue-500 mr-1" />
                                )} */}
                                      Weekly Payment Amount:
                                    </span>
                                    <span
                                      className={`text-right font-bold text-slate-800`}
                                    >{`${
                                      ActivePlan &&
                                      ActivePlan.at(
                                        0
                                      )?.weeklyInstalment?.toLocaleString()
                                    } RWF`}</span>
                                  </>
                                )}
                                {wareHouse?.createdCart?.data
                                  ?.instalmentPlan === "daily" && (
                                  <>
                                    <span className="font-bold text-neutral-800">
                                      {/* {!disableCheckout && (
                                  <DoneAllIcon className="text-blue-500 mr-1" />
                                )} */}
                                      Daily Payment Amount:
                                    </span>
                                    <span
                                      className={`text-right font-bold text-slate-800`}
                                    >{`${
                                      ActivePlan &&
                                      ActivePlan.at(
                                        0
                                      )?.dailyInstalment?.toLocaleString()
                                    } RWF`}</span>
                                  </>
                                )}
                                {wareHouse?.createdCart?.data
                                  ?.instalmentPlan === "monthly" && (
                                  <>
                                    <span className="font-bold text-neutral-800">
                                      {/* {!disableCheckout && (
                                  <DoneAllIcon className="text-blue-500 mr-1" />
                                )} */}
                                      Monthly Payment Amount:
                                    </span>
                                    <span
                                      className={`text-right font-bold text-slate-800`}
                                    >{`${
                                      (ActivePlan &&
                                        ActivePlan.at(
                                          0
                                        )?.monthlyInstalment?.toLocaleString()) ||
                                      0
                                    } RWF`}</span>
                                  </>
                                )}
                              </div>

                              <div className="w-full mt-5 flex justify-between">
                                <span className="font-bold text-neutral-800">
                                  {/* {!disableCheckout && (
                                  <DoneAllIcon className="text-blue-500 mr-1" />
                                )} */}
                                  Initial Payment Amount:
                                </span>
                                {/* <span
                                  className={`text-right font-bold text-slate-800`}
                                >{`${
                                  ActivePlan &&
                                  ActivePlan.at(
                                    0
                                  )?.initialPayment?.toLocaleString()
                                } RWF`}</span> */}
                                <span
                                  className={`text-right font-bold text-slate-800`}
                                >{`${
                                  planType === "b2c_prepaid"
                                    ? ActivePlan &&
                                      ActivePlan.at(
                                        0
                                      )?.initialPayment?.toLocaleString()
                                    : `${
                                        wareHouse?.createdCart?.data
                                          ?.instalmentPlan === "daily"
                                          ? ActivePlan &&
                                            ActivePlan.at(
                                              0
                                            )?.dailyInstalment?.toLocaleString()
                                          : wareHouse?.createdCart?.data
                                              ?.instalmentPlan === "weekly"
                                          ? ActivePlan &&
                                            ActivePlan.at(
                                              0
                                            )?.weeklyInstalment?.toLocaleString()
                                          : (ActivePlan &&
                                              ActivePlan.at(
                                                0
                                              )?.monthlyInstalment?.toLocaleString()) ||
                                            0
                                      }`
                                } RWF`}</span>
                              </div>
                            </Radio.Group>
                            {/* </div> */}
                          </div>
                        ))}
                    {availablePlan?.map((data: any) => (
                      <div className="flex flex-col pb-3 space-y-4 mt-4">
                        <p className="border-b pb-1 font-semibold">Data Plan</p>
                        <div className="flex items-center flex-wrap space-x-3">
                          <div className="flex flex-col pb-3 space-y-1">
                            <span>Bundles</span>
                            <Select
                              dropdownMatchSelectWidth={false}
                              showSearch
                              placeholder="Select  Role of the Employee!"
                              className="capitalize"
                              style={{ width: 100 }}
                              onChange={onChangeBundleSelected}
                              disabled={
                                wareHouse?.createdCart?.data
                                  ?.isSubscriptionSubmitted ||
                                wareHouse?.isFetching
                              }
                              defaultValue={
                                wareHouse?.createdCart?.data?.other?.bundle
                              }
                            >
                              {data?.bundle?.map((bundle: any, index: any) => (
                                <Option
                                  value={bundle}
                                  key={index}
                                  className="capitalize"
                                >
                                  {`${bundle} GB`}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <div className="flex flex-col pb-3 space-y-1">
                            <span>SMS</span>
                            <Select
                              dropdownMatchSelectWidth={false}
                              showSearch
                              placeholder="Select  Role of the Employee!"
                              className="capitalize"
                              style={{ width: 100 }}
                              onChange={onChangeSMSSelected}
                              disabled={
                                wareHouse?.createdCart?.data
                                  ?.isSubscriptionSubmitted ||
                                wareHouse?.isFetching
                              }
                              defaultValue={
                                wareHouse?.createdCart?.data?.other?.sms
                              }
                            >
                              {data?.sms?.map((sms: any) => (
                                <Option value={sms} className="capitalize">
                                  {sms}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <div className="flex flex-col pb-3 space-y-1">
                            <span>Call</span>
                            <Select
                              dropdownMatchSelectWidth={false}
                              showSearch
                              placeholder="Select  Role of the Employee!"
                              className="capitalize"
                              style={{ width: 100 }}
                              onChange={onChangeCallSelected}
                              disabled={
                                wareHouse?.createdCart?.data
                                  ?.isSubscriptionSubmitted ||
                                wareHouse?.isFetching
                              }
                              defaultValue={
                                wareHouse?.createdCart?.data?.other?.call
                              }
                            >
                              {data?.call?.map((call: any) => (
                                <Option value={call} className="capitalize">
                                  {call}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <div className="flex flex-col pb-3 space-y-1">
                            <span>Period</span>
                            <Select
                              dropdownMatchSelectWidth={false}
                              showSearch
                              placeholder="Select  Role of the Employee!"
                              className="capitalize"
                              style={{ width: 100 }}
                              onChange={onChangePeriodSelected}
                              disabled={
                                wareHouse?.createdCart?.data
                                  ?.isSubscriptionSubmitted ||
                                wareHouse?.isFetching
                              }
                              defaultValue={
                                wareHouse?.createdCart?.data?.other?.period
                              }
                            >
                              {[
                                "3",
                                "6",
                                "9",
                                "12",
                                "14",
                                "18",
                                "20",
                                "22",
                                "24",
                              ]?.map((call: any) => (
                                <Option value={call} className="capitalize">
                                  {call}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <LoadingButton
                    // style={{ padding: "6px", marginTop: "40px" }}
                    className=""
                    variant="contained"
                    loading={customer?.isFetching || wareHouse?.isFetching}
                    onClick={handleSendRequest}
                    disabled={
                      !wareHouse?.createdCart?.data?.customer?.subscriptionInfo
                        ?.nationalIDDoc ||
                      !wareHouse?.createdCart?.data?.subscriptionPlan ||
                      wareHouse?.createdCart?.data?.isSubscriptionSubmitted ||
                      !wareHouse?.createdCart?.data?.other
                    }
                  >
                    {planType === "b2c_postpaid" ? "Next" : "Request"}
                  </LoadingButton>
                </div>
              )}
            </div>
          )}
        </div>

        {/* confirm payment model */}
        <Modal
          open={isCheckoutModalOpen}
          onCancel={() => setIsCheckoutModalOpen(false)}
          title={ <div className="flex gap-5">< ExclamationCircleOutlined style={{color:"orange",fontSize:"23px"}}/><h1 className="text-[#030229] font-medium text-2xl">Confirmation</h1></div> }
          okText="Confirm"
          onOk={() => {
            handleCheckout();
            setIsCheckoutModalOpen(false);
          }}
          okButtonProps={{ style: { border: "1px solid blue",color:"black" } }}
          confirmLoading={pos?.isFetching}
          // okButtonProps={{
          //   loading: pos?.isFetching,
          //   icon: pos?.isFetching ? <LoadingOutlined /> : null,
          // }}
        >
          <Divider className="!p-0 !m-0" />
          <div className="flex justify-center border-t pt-2 ">
            <div className="w-full  gap-x-2">
              <div className="flex items-center justify-between">
                <p className="text-[#030229] text-base font-normal">Total: </p>
                <span className="text-right">{`${
                  isSubscripitonPage
                    ? `${
                        planType === "b2c_prepaid"
                          ? ActivePlan &&
                            ActivePlan.at(0)?.initialPayment?.toLocaleString()
                          : `${
                              wareHouse?.createdCart?.data?.instalmentPlan ===
                              "daily"
                                ? ActivePlan &&
                                  ActivePlan.at(
                                    0
                                  )?.dailyInstalment?.toLocaleString()
                                : wareHouse?.createdCart?.data
                                    ?.instalmentPlan === "weekly"
                                ? ActivePlan &&
                                  ActivePlan.at(
                                    0
                                  )?.weeklyInstalment?.toLocaleString()
                                : (ActivePlan &&
                                    ActivePlan.at(
                                      0
                                    )?.monthlyInstalment?.toLocaleString()) ||
                                  0
                            }`
                      }`
                    : getTotalPrices(itemsList, plan)?.toLocaleString()
                } RWF`}</span>
              </div>
              <div className="flex items-center justify-between">
                <p className="text-[#030229] text-base font-normal">Discount:</p>
                <span className="text-right">{`(${discount?.discountPerc}%) ${(
                  getTotalPrice(itemsList) *
                  (discount?.discountPerc / 100)
                )?.toLocaleString()} RWF`}</span>
              </div>
              <div className="flex items-center justify-between">
                <p className="flex items-center text-[#030229] text-base font-normal mt-5">
                  Paid amount:
                </p>
                <span className="text-right font-bold text-neutral-800 ">{`${
                  paidAmount?.toLocaleString() || 0
                } RWF`}</span>
              </div>

              <div className="flex items-center justify-between">
                <p className="flex items-center text-[#030229] text-base font-normal mt-5">
                  Final amount:
                </p>
                <span
                  className={`text-right font-bold text-slate-800 mt-5`}
                >{`${
                  isSubscripitonPage
                    ? `${
                        planType === "b2c_prepaid"
                          ? ActivePlan &&
                            ActivePlan.at(0)?.initialPayment?.toLocaleString()
                          : `${
                              wareHouse?.createdCart?.data?.instalmentPlan ===
                              "daily"
                                ? ActivePlan &&
                                  ActivePlan.at(
                                    0
                                  )?.dailyInstalment?.toLocaleString()
                                : wareHouse?.createdCart?.data
                                    ?.instalmentPlan === "weekly"
                                ? ActivePlan &&
                                  ActivePlan.at(
                                    0
                                  )?.weeklyInstalment?.toLocaleString()
                                : (ActivePlan &&
                                    ActivePlan.at(
                                      0
                                    )?.monthlyInstalment?.toLocaleString()) ||
                                  0
                            }`
                      }`
                    : (
                        getTotalPrices(itemsList, plan) -
                        getTotalPrice(itemsList) *
                          (discount?.discountPerc / 100)
                      )?.toLocaleString()
                } RWF`}</span>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <Modal
        centered
        open={termsAndConditionModalOpen}
        footer={null}
        onCancel={onCloseTermAndContions}
        width={1000}
      >
        <TermsAndConditionForm onCancel={onCloseTermAndContions} />
      </Modal>

      <Modal
        centered
        open={loadingSpiner}
        footer={null}
        width={1000}
        className="custom-modal"
      >
        <div className="flex items-center justify-center rounded-lg mx-auto bg-transparent">
          <Spin indicator={antIcon} />
        </div>
      </Modal>
    </>
  );
};

export default PaymentProcess;


