import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";
export const createAccountService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/account`, data, token);
};

export const getAllAccount = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/account${query}`, token);
};

export const getOneAccountService = async (id: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/account/one/${id}`, token);
};

