import { BsFillQuestionCircleFill } from "react-icons/bs";
import UserProfile from "./userProfile";
import { ReactComponent as ShippingBag } from "../../assets/images/shopping-bag.svg";
import SearchButton from "../SearchButton";
import { useNavigate } from "react-router-dom";
import GetNotification from "./Notification";
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Badge, Space, Tooltip } from 'antd';
import { useMemo, useState } from "react";
const Header = (props: any) => {
  const navigate = useNavigate();
  const [arrow, setArrow] = useState('Show');

  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }

    if (arrow === 'Show') {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);
  return (
    <>
      <div
        className={`bg-white fixed top-0   w-full z-10 h-[72px] ${
          props?.open ? "left-[14rem] pr-[14rem]" : "left-[5rem] pr-20"
        }`}
      >
        <div className="w-full py-2 px-10">
          <div className="flex justify-between">
            <h1 className="text-[#030229cc] text-lg pt-3 font-medium">
              Point Of Sales
            </h1>
            <SearchButton />
            <div className="flex gap-5e gap-x-5">
            <Tooltip placement="bottom" title=<h1>Pending Sales</h1> arrow={mergedArrow}>
            
          
              <div
                className="relative cursor-pointer"
                onClick={() => navigate("/sale")}
              >
                 {/* <Badge count={props?.data?.carts?.data? props?.data?.carts?.data?.length:0}>
      <Avatar icon={<ShippingBag />} />
    </Badge> */}
                <ShippingBag className="mt-1"/>
                <div className="absolute h-4 w-4 bg-[#F1595C] rounded-full top-2 right-0 z-2s00 flex items-center justify-center">
                  {" "}
                  <span className="text-white text-bases">
                    
                    {props?.data?.carts?.data?.length}
                  </span>
                </div>
              </div>
              </Tooltip>
              <BsFillQuestionCircleFill
                size={20}
                className="mt-3 cursor-pointer"
                color="black"
                onClick={() => navigate("/knowledge")}
              />
              <hr className="h-8 w-[1px] mt-2 bg-gray-200" />
              <div>
                <GetNotification />
              </div>
              <hr className="h-6 w-[1px] mt-3 bg-gray-200" />
              <UserProfile />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
