import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Tag } from "antd";
import { searchValue } from "../../utils/setColor";
import { useSelector } from "react-redux";

const TransferGridCard = (props: any) => {
  const navigate = useNavigate();
  const { channel } = useSelector((state: any) => state);

  // console.log("result Again >>>", channel?.rfsTransfer?.data);

  return (
    <>
      {channel?.rfsTransfer?.data?.map((el: any) => (
        <div className="relative custom-backface-visibilty bg-white border-gray-100  p-2 min-w-[300px] border text-black min-h-[126px] rounded-md !duration-200 hover:border-gray-300">
          <Stack
            spacing={2}
            onClick={() => {
              navigate(`${el?._id}/${el?.stockRequest?.requestId}`);
            }}
          >
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems="center"
              sx={{ width: "100%" }}
              display={"relative"}
            >
              <Stack spacing={0}>
                <h1>{`${el?.product?.product?.model}`}</h1>
                <p className="text-xs pt-4" style={{ color: "blue" }}>
                  Specification
                </p>
                <div>
                  <p className="capitalize">
                    capacity:{" "}
                    <span>
                      {el?.product?.specification
                        ?.filter(
                          (item: any) =>
                            item[0]?.toLowerCase() === "capacity (rom)"
                        )
                        .map((item: any) => item[1])}
                    </span>
                  </p>
                  <p>
                    Memory:{" "}
                    <span>
                      {el?.product?.specification
                        ?.filter(
                          (item: any) =>
                            item[0]?.toLowerCase() === "memory (ram)"
                        )
                        .map((item: any) => item[1])}
                    </span>
                  </p>
                </div>
                <div className="pt-2">
                  <p className="text-sm">
                    Requested Qnty:
                    <span className="pl-2">{el.qtyApproved}</span>
                  </p>
                </div>
              </Stack>
              <Tag
                color={searchValue(el.transferStatus)}
                style={{ minWidth: "4rem", textAlign: "center" }}
                className="capitalize absolute right-2 top-2"
              >
                {el.transferStatus}
              </Tag>
            </Stack>
          </Stack>
        </div>
      ))}
    </>
  );
};

export default TransferGridCard;
