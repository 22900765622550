const SearchButton=()=>{
    return(
        <>
        
<form className="w-[850px] mx-auto mt-1">   
    <div className="relative pxs-3">
        <div className="absolute inset-y-0 start-0 flex items-center pl-10 pointer-events-none">
            <svg className="w-4 h-4 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
        </div>
        <input type="search" id="default-search" className="block w-full pl-20 py-3 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 " placeholder="Search .." required />
       
    </div>
</form>

        </>
    )
}
export default SearchButton;