import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/Layer.png";
import { subMenusList } from "../../assets/data/pages";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import "../buttons/button.css";
import { GoPlus } from "react-icons/go";
import { MdClose } from "react-icons/md";
import { useNavigate, useSubmit } from "react-router-dom";
import { myLayoutActions } from "../../store/layout";
import { useDispatch } from "react-redux";
import CustomModal from "../Modals/CustomModal";
import CreateNoSales from "../../pages/dashboard/Sales/CreateNoSales";
import CreateSalesBtn from "../../pages/dashboard/Sales/CreateSalesBtn";
import { myWareHouseActions } from "../../store/wareHouse";
import { ReactComponent as Expandicons } from "../../icons/expand.svg";
import {
  getActiveMenu,
  storeActiveMenu,
  storeSelectedCaption,
} from "../../utils/converter";

const SideNav = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  type SubMenuOpenState = {
    [key: number]: boolean;
  };

  const [subMenuOpen, setSubMenuOpen] = useState<SubMenuOpenState>({});
  const [selectedTabIndex, setSelectedTabIndex] = useState(getActiveMenu());
  const [openDropDownIndex, setOpenDropDownIndex] = useState(getActiveMenu());
  const [sideBar, SetSideBar] = useState(false);
  const showSideBar = () => {
    SetSideBar(!sideBar);
  };

  let indexes: number[] = [];

  const toggleSubMenu = (indexes: number[], providedIndex: number, status=false) => {
    setSubMenuOpen((prevState) => {
      const newState = { ...prevState };
      indexes.forEach((index) => {
        if (index !== providedIndex) {
          newState[index] = false;
        } else {
          newState[index] = status===false ? !prevState[index] : true;
        }
      });
      return newState;
    });
  };
  
  
  const [showCreateSales, setShowCreateSales] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [attemptToOpen, setAttemptToOpen]=useState(false);
  const handleOk = () => {
    setIsModalOpen(true);
  };
  const handlCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(()=>{
    let currentMenu = localStorage.getItem('currentMenu') || '';
    toggleSubMenu(indexes, parseInt(currentMenu), true);
  },[attemptToOpen]);




  return (
    <div
      className={`bg-[#FAFAFB] shadow-lg duration-300 z-10 h-screen pt-4 ${
        props?.open ? "w-56" : "w-20"
      } fixed`}
    >
      <IoIosArrowDroprightCircle
        className={`absolute top-[4.3rem] z-[999] text-[#605BFF] opacity-80 -right-3 cursor-pointer ${
          props?.open && "rotate-180"
        }`}
        size={30}
        onClick={() => props?.setOpen(!props?.open)}
      />
      <div className="flex justify-center items-center gap-2.5 duration-500 font-medium py-3 border-slate-300">
        {props?.open ? (
          <img
            src={Logo}
            alt="Logo"
            className={`transition-all w-[8rem] mst-3`}
          />
        ) : (
          <h1 className="text-2xl  font-bold text-black duration-500 text-center">
            S
          </h1>
        )}
      </div>
      <ul className="borders-y py-5 borders-slate-300 p-2 ">
        {subMenusList?.map((mainMenu: any, index) => {
          indexes.push(index);
          return (
            <>
              <li
                key={mainMenu?.id}
                className={`flex gap-3 my-2 p-2 py-2 rounded-sm cbg-[#FAFAFB] hover:bg-[#ebf2ff46] ${
                  selectedTabIndex === mainMenu.id ? "  bg-[#FAFAFB]" : " "
                }`}
                onClick={() => {
                  setSelectedTabIndex(mainMenu.id);
                  storeActiveMenu(mainMenu.id);
                  if (!mainMenu.menus) {
                    navigate(mainMenu.path);
                    showSideBar();
                  }
                }}
              >
                <span
                  className={`text-2xl ml-4  ${
                    selectedTabIndex === mainMenu.id
                      ? "activeIcon shadow-[-53px_0px_25px_6px_rgba(96,91,255,1)]"
                      : "#030229CC"
                  }`}
                >
                  <mainMenu.icon />
                </span>
                <span
                  className={` text-base flex items-center cursor-pointer rounded-md ${
                    !props?.open && "hidden"
                  } ${
                    selectedTabIndex === mainMenu.id
                      ? "text-[#605BFF]"
                      : "text-[#030229CC]"
                  }`}
                  onClick={(e:any) => {
                    toggleSubMenu(indexes, index);

                  }}
                >
                  {" "}
                  {mainMenu.name}
                </span>
                <span>
                  {" "}
                  {mainMenu?.menus && props?.open && (
                    <IoIosArrowDown
                      onClick={(e:any) => {
                        e.stopPropagation();
                        toggleSubMenu(indexes, index);


                      }}
                      className={`mt-1 ${
                        subMenuOpen[index] ? "rotate-180" : ""
                      } ${
                        selectedTabIndex === mainMenu.id
                          ? "text-[#605BFF]"
                          : "text-[#030229CC]"
                      }`}
                    />
                  )}
                </span>
              </li>
              <ul>
                {mainMenu?.menus &&
                  subMenuOpen[index] &&
                  props?.open &&
                  mainMenu?.menus.map((subMenu: any, subIndex: any) => {
                    return (
                      <div className="flex" key={subIndex}>
                        <div className="ml-8">
                          <Expandicons />
                        </div>
                        <li
                          key={subMenu.caption}
                          onClick={() => {
                            localStorage.setItem('currentMenu', index.toString());
                            const selectedCaption = subMenu.caption;
                            storeSelectedCaption(selectedCaption);
                            navigate(subMenu.path);
                            showSideBar();
                            setAttemptToOpen(!attemptToOpen);
                          }}
                          className={`py-1 cursor-pointer flex gap-2 text-sm my-[1px] px-2 hover:bg-light-white rounded-md duration-500 ${
                            localStorage.getItem("selectedCaption") ===
                            subMenu.caption
                              ? "text-[#605BFF] bg-[#ebf2ff46]"
                              : "text-[#030229CC]"
                          }`}
                        >
                          {subMenu.caption}
                        </li>
                      </div>
                    );
                  })}
              </ul>
            </>
          );
        })}
      </ul>

      {!showCreateSales ? (
        <div
          className={`bg-gradient-to-r left-[30%] bottom-2 cursor-pointer absolute from-[#605BFF] to-[#8294FF] flex ${
            props?.open ? " h-[80px] w-[80px] " : " h-[30px] w-[30px]"
          }m-auto rounded-full items-center justify-between`}
          onClick={() => {
            dispatch(myWareHouseActions.setCreatedCart(null));
            dispatch(myLayoutActions.setSetConfirmCheckout(false));
            setShowCreateSales(true);
          }}
        >
          <GoPlus
            className={` ${
              props?.open
                ? "h-[42px] w-[42px] ml-5"
                : "h-[22px] w-[30px] flex items-center justify-center"
            }`}
            color="white"
          />
        </div>
      ) : (
        <div className="absolute bottom-0">
          <CustomModal
            title={
              <h1 className="text-[#0F0F47] text-lg font-medium">
                Create No sale
              </h1>
            }
            width={900}
            openModal={isModalOpen}
            cancelModal={handlCloseModal}
            closeModal={handleOk}
            component={
              <CreateNoSales onClick={handleOk} onCancel={handlCloseModal} />
            }
          />
          <div
            className={`bg-gradient-to-r  cursor-pointer absolute bottom-2 ${
              props?.open ? "left-10" : "leftx-3"
            }  from-[#F1595C80] to-[#F1595C40] flex ${
              props?.open ? " h-[80px] w-[80px] " : " h-[30px] w-[30px] "
            }m-auto rounded-full items-center justify-between`}
            onClick={() => setShowCreateSales(false)}
          >
            <MdClose
              className={` ${
                props?.open
                  ? "h-[42px] w-[42px] ml-5"
                  : "h-[22px] w-[22px] ml-1"
              }`}
              color="red"
            />{" "}
          </div>
          <div
            className={`absolute bottom-[6rem] rounded-full ${
              props?.open ? "left-20" : "-left-6"
            }`}
          >
            <CreateSalesBtn setShowCreateSales={setShowCreateSales} />
          </div>
          <div
            className={`absolute cursor-pointer bottom-5 rounded-full ${
              props?.open ? "left-40" : "left-10"
            }`}
          >
            <div
              className={`bg-[#AFACFF] flex h-[90px] w-[90px] m-auto rounded-full items-center justify-between text-center  px-2 fade-in`}
              onClick={handleOk}
            >
              <h1 className="text-white text-base font-medium">
                Create no Sales
              </h1>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SideNav;
