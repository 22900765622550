import { notification } from "antd";
import { myAccounts } from ".";
import {
  getAllAccount,
  getOneAccountService,
  createAccountService,
} from "./services";

export const createOrderAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myAccounts.setIsFetching(true));
      const res = await createAccountService(data, token);
      if (res?.status === 201) {
        dispatch(myAccounts.setNew(res));
        dispatch(myAccounts.setIsFetching(false));
      }
      dispatch(myAccounts.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllAccountAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myAccounts.setIsFetching(true));
      const res = await getAllAccount(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myAccounts.setAll(res));
        dispatch(myAccounts.setIsFetching(false));
      }
      dispatch(myAccounts.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneAccountAction = (itemId: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myAccounts.setIsFetching(true));
      const res = await getOneAccountService(itemId, token);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myAccounts.setSelected(res?.data));
        dispatch(myAccounts.setIsFetching(false));
      }
      dispatch(myAccounts.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
