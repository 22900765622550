import { Paper, Stack, Button } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { DatePicker, DatePickerProps, Select, Spin } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myProductActions } from "../../store/product";
import { getAllAttributesAction } from "../../store/product/actions";
import { getAllTransactionAction } from "../../store/pos/actions";
import {
  getAllChannelAction,
  getAllShopAction,
} from "../../store/channel/actions";
import { myChannelActions } from "../../store/channel";
import AutoCompleteSearch from "./AutoCompleteSearch";
import { getActiveShop } from "../../utils/converter";
import dayjs from "dayjs";
import moment from "moment";

const POSTransactionsFiltering = (props: any) => {
  const { product, auth, pos, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState(false);
  const [type, setType] = useState("");
  const [brand, setBrand] = useState("");
  const [selectedChannel, setSelectedChannel] = useState("");
  const [shop, setShop] = useState("");
  const { Option } = Select;

  dispatch(
    myProductActions.setQuery(
      `?${type && `type=${type}&${brand && `brand=${brand}`}`}`
    )
  );
  selectedChannel &&
    dispatch(
      myChannelActions.setQuery(
        `${selectedChannel && `channel=${selectedChannel}}`}`
      )
    );

  const resetFilter = () => {
    if (selectedFilters) {
      setType("");
      setBrand("");
      setSelectedChannel("");
      props.setSelectedStatus("");
    }
  };

  // useEffect(() => {
  //   auth?.token &&
  //     // auth?.user?.shop &&
  //     getAllTransactionAction(
  //       auth?.token,
  //       auth?.user?.shop?.shopId
  //         ? `?${
  //             auth?.user?.shop?.assigned
  //               ? `channel=${
  //                   selectedChannel ??
  //                   getActiveShop(auth?.user?.shop?.assigned)[0]?.channel?._id
  //                 }${
  //                   auth?.user?.shop?.assigned
  //                     ? `&shop=${
  //                         shop ??
  //                         getActiveShop(auth?.user?.shop?.assigned)[0]?.shop
  //                           ?._id
  //                       }`
  //                     : ""
  //                 }`
  //               : `channel=${auth?.user?.channelId}${
  //                   auth?.user?.shop?.assigned
  //                     ? `&shop=${auth?.user?.shop?.shopId}`
  //                     : ""
  //                 }`
  //           }`
  //         : `?${
  //             selectedChannel
  //               ? `channel=${selectedChannel}${
  //                   shop ? `&shop=${shop}&limit=15` : ""
  //                 }`
  //               : "limit=15"
  //           }`
  //     )(dispatch); //add dispatch here

  //   auth?.token &&
  //     getAllAttributesAction(auth?.token, product?.query || "?")(dispatch);
  // }, [auth, dispatch, product?.query, selectedChannel, shop]);

  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token, "")(dispatch);
  }, [auth?.token, dispatch, channel?.query]);

  const disabledDate = (current: any) => {
    if (current && current > moment().endOf("day")) {
      return true;
    }
    return false;
  };

  const onChangeDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    props?.setSelectedDate(dateString);
  };

  return (
    <div className="pl-4 pr-4 pt-2 pb-2">
      <Stack
        spacing={1}
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack
          spacing={1}
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Button
            startIcon={
              selectedFilters ? <FilterAltIcon /> : <FilterAltOutlinedIcon />
            }
            onClick={() => {
              setSelectedFilters(!selectedFilters);
              resetFilter();
            }}
          >
            Filter
          </Button>

          {selectedFilters && (
            <Stack>
              {props?.route === "finance" && (
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    allowClear
                    placeholder="Filter By Channel"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").toString().includes(input)
                    }
                    loading={product.isFetching}
                    disabled={product.isFetching}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")

                        .toString()
                        .toLowerCase()
                        .localeCompare(
                          (optionB?.label ?? "").toString().toLowerCase()
                        )
                    }
                    // options={channel?.all?.data}
                    onChange={(value: any) => {
                      //   console.log("::", value);
                      auth?.token &&
                        getAllShopAction(
                          auth?.token,
                          `channel=${value}`
                        )(dispatch);
                      !value && setSelectedChannel("");
                      value && setSelectedChannel(value);
                    }}
                  >
                    {channel?.all?.data?.map((el: any) => (
                      <Option value={el?._id}>{el?.name}</Option>
                    ))}
                  </Select>
                  {!selectedChannel && (
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      allowClear
                      placeholder="Filter By Status"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").toString().includes(input)
                      }
                      loading={product.isFetching}
                      disabled={product.isFetching}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")

                          .toString()
                          .toLowerCase()
                          .localeCompare(
                            (optionB?.label ?? "").toString().toLowerCase()
                          )
                      }
                      // options={channel?.all?.data}
                      onChange={(value: any) => {
                        !value && props.setSelectedStatus("");
                        value && props.setSelectedStatus(value);
                      }}
                    >
                      {["paid", "pending"].map((el: any) => (
                        <Option value={el} className="capitalize">
                          {el}
                        </Option>
                      ))}
                    </Select>
                  )}
                  {selectedChannel && (
                    <Select
                      loading={product.isFetching}
                      disabled={product.isFetching}
                      showSearch
                      allowClear
                      style={{ width: 200 }}
                      placeholder="Filter By Shop"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").toString().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")

                          .toString()
                          .toLowerCase()
                          .localeCompare(
                            (optionB?.label ?? "").toString().toLowerCase()
                          )
                      }
                      // options={product?.attributes?.brands}
                      onChange={(value: any) => {
                        console.log("::", value);
                        !value && setShop("");
                        value && setShop(value);
                      }}
                    >
                      {channel?.allShop?.data?.map((el: any) => (
                        <Option value={el?._id}>{el?.name}</Option>
                      ))}
                    </Select>
                  )}
                </Stack>
              )}
            </Stack>
          )}
          <h1 className="text-gray-400">
            Available Product{pos?.transaction?.total > 1 && "s"}:{" "}
            {pos.isFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              pos?.transaction?.total
            )}
          </h1>
        </Stack>
        <div>
          <DatePicker
            onChange={onChangeDate}
            defaultValue={dayjs()}
            style={{ width: 150 }}
            disabledDate={disabledDate}
          />
        </div>
      </Stack>
    </div>
  );
};

export default POSTransactionsFiltering;
