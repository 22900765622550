import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const createItemsService = async (token: string, data: any) => {
  return await HttpRequest.post(`${SERVER_URL}/ebm/addItem`, data, token);
};

export const getAllOptionsService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/ebm/getSelectOptions${query}`, token);
};

export const getAllItemsService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/ebm/getAllItems${query}`, token);
};
