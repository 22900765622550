import React from "react";
import { Badge, Card, Carousel, Space } from "antd";
import { BsExclamationCircleFill } from "react-icons/bs";
import { LoadingButton } from "@mui/lab";
import productImage from "../../../assets/images/Rectangle 1381.png"
import { getActivePrice } from "../../../utils/converter";
import { updateShopStockCartAction, updateWareHouseDetailsAction } from "../../../store/wareHouse/actions";
import { myWareHouseActions } from "../../../store/wareHouse";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
const ProductCard = (props:any) => {
  const {auth,wareHouse}=useSelector((state:any)=>state);
  const {cartId}=useParams();
  const dispatch=useDispatch();
  const itemPrice = getActivePrice(
    props?.data?.data?.product?.requestedItem?.product?.prices
  );
  const discardSearchResult = () => {
    dispatch(myWareHouseActions.setSearchResult(null));
  };
  return (
    <div>
      <Badge.Ribbon text=<h1 className="text-base pt-1">{props?.data?.data?.isInShop ? "In Shop":"Out"}</h1> color="#FAAD14" className="w-[108px] h-[32px] text-center">
        <div className="border border-gray-100 p-3">
           <div className="flex gap-10">
            <div className="bg-[#E5E5E5] w-[134px] h-[129px] p-2 rounded-md">
                      <Carousel autoplay className="w-full h-full">
              {props?.data?.data?.product?.shopStockItem?.requestedItem?.product?.product?.images?.map(
                (im: any) => (
                  <div>
                    <img src={im} className="w-full h-full" alt="" />
                  </div>
                )
              )}
            </Carousel>
            </div>
            <div>
                <h1 className="text-[#030229] text-2xl font-medium">{props?.data?.data?.product?.requestedItem?.product?.product?.model}</h1>
                <p><span className="text-[#030229B2] text-lg">Type:</span> <span className="text-[#030229] text-lg pl-1">{props?.data?.data?.product?.requestedItem?.product
                              ?.product?.type}</span></p>
                <p><span className="text-[#030229B2] text-lg">Brand:</span> <span className="text-[#030229] text-lg pl-1">{props?.data?.data?.product?.requestedItem?.product
                              ?.product?.brand}</span></p>
                <div className="flex flex-col gap-2">
                          {props?.data?.data?.product?.requestedItem?.product?.specification?.map(
                           (data: any, index: number) => {
                            const regex = /\((.*?)\)/;
                            const match = data[0].match(regex);
          
                            if (match) {
                              const stringFromBracket = match[1];
          
                              if (
                                !["type", "brand"].includes(
                                  stringFromBracket.toLowerCase()
                                )
                              ) {
                                return (
                                  <div className="flex text-[#030229] text-sm font-normal" key={index} >
                                    <p >
                                      <span >{`${stringFromBracket}: `}</span>
                                    </p>
                                    <p className="pl-1">{data[1]}</p>
                                  </div>
                                );
                              }
                            }
          
                            return null;
                          }
                          )}
                        </div>
                <p><span className="text-[#030229B2] text-lg">Extended Warranty:</span> <span className="text-[#030229] text-lg pl-1">{props?.data?.data?.product?.requestedItem?.product?.extendedWarranty}%</span></p>
                
            </div>

           </div>
           <div className="flex justify-between mt-4">
          <div className="flex gap-2"><BsExclamationCircleFill className="mt-1" size={20}/><h1 className="text-[#030229] text-base pt-1">This device is eligible for Warranty</h1> </div>  
            <p className="text-[#030229] text-xl font-bold ">{`RWF ${itemPrice?.toLocaleString()}`}</p>
           </div>
        </div>
      </Badge.Ribbon>
      <div className="flex gap-5 float-right mt-10">
      <LoadingButton
              type="submit"
              sx={{width:"150px",borderRadius:"10px"}}
              variant="outlined"
              onClick={discardSearchResult}
              style={{ padding: "6px" }}
              loading={wareHouse?.isFetching}
            >   <h1 className="text-[#5F5AFE] text-base font-medium">{"Discard"}</h1> 
            </LoadingButton>
      <LoadingButton
              type="submit"
              sx={{width:"150px",borderRadius:"10px",backgroundColor:"#5F5AFE"}}
              variant="contained"
              style={{ padding: "6px" }}
            //   loading={customer?.isFetching}
            onClick={()=>props?.onClick()}
            >   <h1 className="text-[#ffff] text-base font-medium">{wareHouse?.isFetching?<Spin className="ml-5 text-white"  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />: "Add To Cart"}</h1> 
            
            </LoadingButton>
      </div>
    </div>
  );
};

export default ProductCard;
