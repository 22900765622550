import React from "react";
import { TEChart } from "tw-elements-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveShop } from "../../../utils/converter";
import { getAgentEarningsAction } from "../../../store/kpi/actions";
import moment from "moment";
 

export default function EarningChart(): JSX.Element {
  const [backgroundColors, setBackgroundColors] = useState<string[]>([]);
  const dispatch = useDispatch();
  const { auth, appSettings, kpi } = useSelector((state: any) => state);
  let today = moment();
  const formattedDate = new Date().toISOString().slice(0, 10);
  let previousDate = today.subtract(7, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");
  const [startDate, setStartDate] = React.useState<any>(formattedPreviousDate);
  const [endDate, setEndDate] = React.useState<any>(formattedDate);

  useEffect(() => {
    const currentDay = new Date().getDay();
    const colors = Array.from({ length: 7 }, (_, i) =>
      i === currentDay ? "#605BFF" : "#E7E6FF"
    );
    setBackgroundColors(colors);
  }, []);

  const userId = auth?.user?._id;
  const shopId=getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]?.shop?._id || auth?.user?.shop?.shopId;

  useEffect(() => {
    if (startDate || endDate) {
      auth?.token &&
        getAgentEarningsAction(
          auth?.token,
          `?shop=${shopId}&agentId=${userId}&date1=${startDate}&date2=${endDate}`
        )(dispatch);
    } else {
      auth?.token &&
        getAgentEarningsAction(
          auth?.token,
          `?shop=${shopId}&agentId=${userId}`
        )(dispatch);
    }
  }, [auth?.token, dispatch, endDate, shopId, startDate, userId]);
console.log("kpii",kpi?.allEarning?.data?.results?.map((d: any) => d?.revenue))
return (
  <TEChart
    type="bar"
    data={{
      labels: kpi?.allEarning?.data?.results?.map((d: any) => d?.date),
      datasets: [
        {
          label: "Earnings",
          data: kpi?.allEarning?.data?.results?.map((d: any) => d?.revenue),
          backgroundColor: backgroundColors,
          borderRadius: 8,
          barPercentage: 0.5,
          barThickness: 40,
        },
      ],
    }}
    options={{
      scales: {
        x: {
          ticks: {
            font: {
              family: "Poppins",
            },
          },
        },
        y: {
          ticks: {
            font: {
              family: "Poppins",
            },
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (context: any) => {
              const value = context.parsed.y;
              return `Earnings: Rwf ${value}`;
            },
          },
          titleFont: {
            family: "Poppins",
          },
          bodyFont: {
            family: "Poppins",
          },
        },
        legend: {
          labels: {
            font: {
              family: "Poppins",
            },
          },
        },
        datalabels: {
          formatter: (value: any, context: any) => {
            return `Rwf ${value}`;
          },
          font: {
            family: "Poppins",
          },
        },
      },
    }}
  />
);

}
