export const comments = [
  "No Inventory",
  "Unkown",
  "Eligible for Macye Macye",
  "Not Eligible - New Sim Card User (Under 6 Months)",
  "Not Eligible - Low Traffic on Momo",
  "Not Eligible - Less Initial Payment (7100)",
  "Not Eligible - Macye Macye System Failure",
  "Not Eligible - CRB Blacklisted",
  "Not Eligible - Over Aged (65 and Above)",
  "Not Eligible - Other",
  "CRB issue",
  "Pending on BK side",
  "Pending on intelligra side",
  "Customer doesn't have enough money",
  "Customer will come back another day",
  "Customer was just looking for information",
  "The device is too expensive for the customer",
];
