import React, { useEffect, useState } from "react";
import CustomModal from "../../../components/Modals/CustomModal";
import PamentModeDrower from "../../../components/Modals/PamentModeDrower";
import AddToCart from "../../../components/AddToCart";
import { Form, Skeleton, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ProductCard from "./productCard";
import {
  getAllShopStockAction,
  getShopStockCartAction,
  updateShopStockCartAction,
  updateShopStockCartDetailsAction,
  updateWareHouseDetailsAction,
} from "../../../store/wareHouse/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { myWareHouseActions } from "../../../store/wareHouse";
import { myCustomerActions } from "../../../store/customer";
import SearchBar from "../../../components/SearchBar";
import {
  getActivePlan,
  getActivePrice,
  getActiveShop,
  getTotalPaidAmount,
  getTotalPrice,
} from "../../../utils/converter";
import { getOneCustomerByQueryAction } from "../../../store/customer/actions";
import AddCustomer from "../../../components/cards/pos/AddCustomer";
import AddCustomerWithSubscription from "../../../components/cards/pos/AddCustomerWithSubscription";
import ConfirmCheckout from "../POS/steps/ConfirmCheckout";
import BarcodeScannerModal from "../../../components/BarcodeScannerModal";
const antIcon = <LoadingOutlined style={{ fontSize: 44 }} spin />;
const CreateSale = () => {
  const { auth, appSettings, wareHouse, customer, layout } = useSelector(
    (state: any) => state
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addToCart, setAddToCart] = useState(false);
  const [newSearch, setNewSearch] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const [scannedCode, setScannedCode] = useState<string>();
  const { cartId } = useParams();
  const dispatch = useDispatch();

  const getCode = (code: string) => {
    setNewSearch(false);
    onFinish({ query: code });
    setScannedCode(code);
  };
  const handleChange = (e: any) => {
    setNewSearch(true);
  };
  const isFound =
    wareHouse?.searchResult?.status === 200 &&
    wareHouse?.searchResult?.data?.isInShop &&
    wareHouse?.searchResult?.data?.product?.status !== "sold";
  const isSubscripitonPage =
    wareHouse?.createdCart?.data?.isSubscription === "subscription";
  const productData = wareHouse?.searchResult;
  const isCartEmpty = wareHouse?.createdCart?.data?.list?.length < 1;
  // const showModal = () => {
  //   setIsModalOpen(true);
  // };
  const onFinish = async (values: any) => {
    setIsSearching(true);
    try {
      auth?.token &&
        (await getAllShopStockAction(
          auth?.token,
          `?serialNumber=${values?.query}&shopId=${
            getActiveShop(auth?.user?.shop?.assigned)[
              appSettings?.selectedShopNumber
            ]?.shop?._id ?? auth?.user?.shop?.shopId
          }`
        )(dispatch));
    } catch (error) {
      console.log(error);
    } finally {
      setIsSearching(false);
    }
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    console.log(":::::::::::::::weeeeee");
    auth?.token &&
      wareHouse?.isCartCreated &&
      cartId &&
      getShopStockCartAction(auth?.token, cartId)(dispatch);
  }, [auth?.token, cartId, dispatch]);
  React.useEffect(() => {
    dispatch(myWareHouseActions.setCreatedCart(null));
    dispatch(myCustomerActions.setSelected(null));
  }, [dispatch]);

  const [current, setCurrent] = useState(0);

  const subsPlans = wareHouse?.createdCart?.data?.list?.map(
    (plan: any, index: number) => plan?.priceProduct?.subscriptionPlan
  );

  const ActivePlan = getActivePlan(
    subsPlans,
    wareHouse?.createdCart?.data?.subscriptionPlan
  );

  const discountPerc = wareHouse?.createdCart?.data?.discount?.discountPerc;
  const totalPrice = getTotalPrice(wareHouse?.createdCart?.data?.list);
  const payment = getTotalPaidAmount(wareHouse?.createdCart?.data?.payment);
  const finalAmount = isSubscripitonPage
    ? `${
        wareHouse?.createdCart?.data?.initialPaymentType === "b2c_prepaid"
          ? ActivePlan && ActivePlan.at(0)?.initialPayment
          : `${
              wareHouse?.createdCart?.data?.instalmentPlan === "daily"
                ? ActivePlan && ActivePlan.at(0)?.dailyInstalment
                : wareHouse?.createdCart?.data?.instalmentPlan === "weekly"
                ? ActivePlan && ActivePlan.at(0)?.weeklyInstalment
                : (ActivePlan && ActivePlan.at(0)?.monthlyInstalment) || 0
            }`
      }`
    : totalPrice - totalPrice * (discountPerc / 100);

  // useEffect(() => {
  //   auth?.token && getShopStockCartAction(auth?.token, cartId)(dispatch);
  // }, [auth?.token, cartId, dispatch]);

  const handleAddToCartInfo = async () => {
    setAddToCart(true);
    const product = wareHouse?.searchResult?.data?.product;
    const payload = {
      cart: cartId,
      shopStockItem: product?._id,
      warehouseProduct: product?.wareHouseItem?._id,
      channel: product?.shop?.channel?._id,
      shop: product?.shop?._id,
      priceProduct: product?.requestedItem?.product?._id,
      payment: {
        amount: +getActivePrice(product?.requestedItem?.product?.prices),
        extendedWarranty: 0,
        installementPayment: `${
          wareHouse?.createdCart?.data?.initialPaymentType === "b2c_prepaid"
            ? ActivePlan && ActivePlan.at(0)?.initialPayment
            : `${
                wareHouse?.createdCart?.data?.instalmentPlan === "daily"
                  ? ActivePlan && ActivePlan.at(0)?.dailyInstalment
                  : wareHouse?.createdCart?.data?.instalmentPlan === "weekly"
                  ? ActivePlan && ActivePlan.at(0)?.weeklyInstalment
                  : (ActivePlan && ActivePlan.at(0)?.monthlyInstalment) || 0
              }`
        }`,
      },
    };

    try {
      auth?.token &&
        (await updateShopStockCartAction(
          auth?.token,
          cartId,
          payload
        )(dispatch));
      dispatch(myWareHouseActions.setSearchResult(null));
      await updateWareHouseDetailsAction(
        auth?.token,
        product?.wareHouseItem?._id,
        {
          dataActivated: false,
        }
      )(dispatch);
    } catch (e) {
    } finally {
      // setAddToCart(false)
    }
  };

  useEffect(() => {
    if (
      wareHouse?.createdCart?.data?.status?.toLocaleLowerCase() === "paid" ||
      wareHouse?.createdCart?.data?.status?.toLocaleLowerCase() === "cancelled"
    ) {
      setCurrent(1);
    }
  }, [wareHouse?.createdCart?.data?.status]);

  useEffect(() => {
    dispatch(myCustomerActions.setSelected(null));
    dispatch(myWareHouseActions.setCreatedCart(null));
  }, [dispatch]);

  useEffect(() => {
    const addCustomer = async () => {
      customer?.new?.data &&
        (await getOneCustomerByQueryAction(
          auth?.token,
          `?phone=${customer?.new?.data?.phone}`
        )(dispatch));
      dispatch(myCustomerActions.setNew(null));
      dispatch(myCustomerActions.setUpdated(null));
      dispatch(myCustomerActions.setSearchResult(null));
    };
    addCustomer();
  }, [auth?.token, customer?.new, customer?.searchResult, dispatch]);

  useEffect(() => {
    auth?.token && getShopStockCartAction(auth?.token, cartId)(dispatch);
  }, [auth?.token, cartId, customer?.updated, dispatch]);

  useEffect(() => {
    if (customer?.searchResult?.length > 0) {
      auth?.token &&
        updateShopStockCartDetailsAction(auth?.token, cartId, {
          customer: customer?.searchResult[0]?._id,
        })(dispatch);
    }
  }, [customer?.searchResult, cartId, dispatch, auth?.token]);


  return (
    <div className="text-black">
      {!layout?.confirmCheckout ? (
        <>
          <CustomModal
            title={
              <h1 className="text-[#0F0F47] text-lg font-medium">
                Select Selling Mode
              </h1>
            }
            width={600}
            openModal={isModalOpen}
            closeModal={handleOk}
            component={<PamentModeDrower onClick={handleOk} />}
          />

          <div className="flex gap-5">
            <div className="w-[60%] h-[85vh] ">
              {wareHouse.isFetching ? <Skeleton className="h-[48.5%] w-full"/>:
              <div className="h-[48.5%] bg-white shadow-md rounded-md p-5 w-full ">
                {!isFound ? (
                  <div className="flex justify-center gap-x-2">
                    <Form onFinish={onFinish}>
                      <Form.Item
                        name="query"
                        className="rounded-lg flex"
                        // label="Enter serial number"
                        initialValue={scannedCode}
                      >
                        <SearchBar
                          isLoading={isSearching}
                          scannedCode={newSearch ? "" : scannedCode}
                          onChange={handleChange}
                          isDisabled={!isCartEmpty && isSubscripitonPage}
                        />
                      </Form.Item>
                      <div className="py-1">
                        {!wareHouse?.isFetching &&
                          wareHouse?.searchResult?.status === 200 &&
                          wareHouse?.searchResult?.data?.isInShop &&
                          wareHouse?.searchResult?.data?.product?.status !==
                            "sold" && (
                            <p className="font-semibold text-green-500">
                              Product found
                            </p>
                          )}
                        {!wareHouse?.isFetching &&
                          wareHouse?.searchError?.status === 404 && (
                            <p className="font-semibold text-red-500">
                              Product not found
                            </p>
                          )}
                        {!wareHouse?.isFetching &&
                          !wareHouse?.searchResult?.data?.isInShop && (
                            <div className="font-semibold text-yellow-500">
                              {wareHouse?.searchResult?.message}
                            </div>
                          )}
                        {!wareHouse?.isFetching &&
                          wareHouse?.searchResult?.data?.isInShop &&
                          wareHouse?.searchResult?.data?.product?.status ===
                            "sold" && (
                            <div className="font-semibold text-yellow-500">
                              The product is sold
                            </div>
                          )}
                      </div>
                    </Form>
                    <BarcodeScannerModal getCode={getCode} />
                  </div>
                ) : (
                  <ProductCard
                    onClick={handleAddToCartInfo}
                    data={productData}
                  />
                )}
              </div>}
              
              <div className="h-1/2 bg-white shadow-md rounded-md p-5 w-full mt-2">
                {customer?.isFetching? (
                  <div className="flex items-center justify-center h-[55%] bg-white p-2 rounded-lg">
                    <Spin indicator={antIcon} />
                  </div>
                ) : (
                  <>
                    {wareHouse?.createdCart?.data?.isSubscription === "pos" ? (
                      <div className="h-[55%] bg-white p-2 rounded-lg">
                        <AddCustomer />
                      </div>
                    ) : (
                      <div className="h-[55%] bg-white p-2 rounded-lg">
                        <AddCustomerWithSubscription />
                      </div>
                    )}
                  </>
                )}
              </div>


            </div>

            <div className="bg-white rounded-md border border-[#0302291A] w-[40%] h-[85vh] shadow-md">
              <AddToCart />
            </div>
          </div>
        </>
      ) : (
        <ConfirmCheckout />
      )}
    </div>
  );
};

export default CreateSale;
