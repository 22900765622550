import React from 'react'

interface cardProps{
    total?:any;
    text?:string;
    icon?:any
}
const OverViewCard = ({total,text,icon}:cardProps) => {
  return (
    <div className='bg-white p-5 w-[384px] rounded-md shadow-md h-[112px]'>
        <div className='flex gap-10'>
            <div className='w-[42px] h-[42px] rounded-full border flex justify-center items-center'>{icon}</div>
            <div>
                <p className='text-[22px] font-semibold text-[#030229cc]'>{total || 0}</p>
                <p className='text-base text-[#030229d0] pt-2'>{text}</p>
            </div>

        </div>
    </div>
  )
}

export default OverViewCard