const statusColor = [
  ["Cancelled", "#f50721"],
  ["Incomplete", "#f50721"],
  ["Incompleted", "#FEE6E980"],
  ["Verified-Incomplete", "#f50721"],
  ["Stored-Verified-Complete", "#795548"],
  ["Stocked", "#795548"],
  ["Review", "#605BFF1A"],
  ["In-Transit", "#F17B2C0D"],
  ["In Transit", "#F17B2C0D"],
  ["Replied", "#ffa500"],
  ["Accepted", "#ffa500"],
  ["Sent", "#fed607"],
  ["Validated", "#ECBC4C"],
  ["Completed", "#0FA91E40"],
  ["StokIn", "#F4F6FA"],
  ["Verified-Completed", "#0FA91E"],
  ["Approved", "#0FA91E0D"],
  ["Verified-Complete", "#0fa958"],
  ["Stored-Complete", "#0fa958"],
  ["Complete", "#0fa958"],
  ["Arrived", "#0FA958"],
  ["Pending", "#F4F4F6"],
  ["Draft", "#c0c0c0"],
  ["Finance", "#2196F3"],
  ["Received", "#0FA91E0D"],
  ["Done", "#605BFF1A"],
  ["international", "#00BCD4"],
  ["local", "#795548"],
  ["Active", "#0FA91E0D"],
  ["In", "#0FA958"],
  ["Out", "#F19F1D"],
  ["Sold", "#FEE6E980"],
  ["Rejected", "#F50721"],
  ["Paid", "#4CAF50"],
  ["Fully-In-Transit", "#4CAF50"],
  ["Partial-In-Transit", "#F19F1D"],
  ["subscription", "#fde047"],
  ["pos", "#FAAD140D"],
  ["Request", "#c0c0c0"],
  ["Requested", "#00BCD4"],
  ["B2C", "#F19F1D"],
  ["Verified-Not Stored", "#C0C0C080"],
];
const statusTextColor = [
  ["Pending", "#030229B2"],
  ["In Transit", "#F17B2C"],
  ["Received", "#0FA91E"],
  ["Verified-Not Stored", "#03022980"],
  ["Completed", "#0FA91E"],
  ["Incompleted", "#DE2C2F"],
  ["Verified-Completed", "#0FA91E"],
  ["Approved", "#0FA91E"],
  ["Verified-Complete", "#0FA91E"],
  ["Active", "#0FA91E"],
  ["In-Transit", "#F17B2C"],
  ["Review", "#605BFF"],
  ["StokIn", "#030229B2"],
  ["Sold", "#DE2C2F"],
  ["Done", "#2196F3"],
]
export const searchValue = (value: string) => {
  for (let i = 0; i < statusColor.length; i++) {
    if (statusColor[i][0]?.toLowerCase() === value?.toLowerCase()) {
      return statusColor[i][1];
      //   break;
    }
  }
};

export const getColorByValue = (arr: any, value: any) => {
  for (let i = 0; i < arr?.length; i++) {
    if (arr[i].value === value) {
      return arr[i].color;
    }
  }
  return null; // If the value is not found in the array, return null or any other appropriate value
};

export const searchTextValue = (value: string) => {
  for (let i = 0; i < statusTextColor.length; i++) {
    if (statusTextColor[i][0]?.toLowerCase() === value?.toLowerCase()) {
      return statusTextColor[i][1];
      //   break;
    }
  }
};