import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "Customer-Library",
  initialState: {
    isFetching: false,
    all: { data: [] },
    selected: null,
    attributes: null,
    new: null,
    updated: null,
    query: null,
    searchResult: null,
    purchased: null,
    customerRepairs: null,
    topCustomers: null,
    customerAnalytics:null,
    customerOverview:null,
    customerStatus:null,
    recentCustomer:null
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setTopCustomers(state, action) {
      state.topCustomers = action.payload;
    },
    setAttributes(state, action) {
      state.attributes = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setUpdated(state, action) {
      state.updated = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setSearchResult(state, action) {
      state.searchResult = action.payload;
    },
    setPurchased(state, action) {
      state.purchased = action.payload;
    },
    setCustomerRepair(state, action) {
      state.customerRepairs = action.payload;
    },
    setAnalyticsCustomers(state, action) {
      state.customerAnalytics = action.payload;
    },
    setCustomerOverview(state,action){
      state.customerOverview = action.payload;
    },
    setcustomerStatus(state,action){
      state.customerStatus = action.payload; 
    },
    setRecentcustomer(state,action){
      state.recentCustomer = action.payload; 
    }
  },
});

export const myCustomerActions = mySlice.actions;

export default mySlice.reducer;
