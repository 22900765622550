import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { notification, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import {
  createStockOutAction,
  getAllStockToTransferAction,
  updateStockReqItemAction,
  updateStockRequestStatusAction,
} from "../../../store/channel/actions";
import { getActiveShop } from "../../../utils/converter";

const columns = [
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Brand",
    dataIndex: "brand",
    key: "brand",
  },
  {
    title: "Models",
    dataIndex: "model",
    key: "model",
  },
  {
    title: "Description",
    dataIndex: "specification",
    key: "specification",
  },
  {
    title: "Quantity",
    dataIndex: "qty",
    key: "qty",
  },
];
const WsCreate = () => {
  const { auth, wareHouseOut, channel, appSettings } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { stockreqitemId, rfsId } = useParams();
  const shopId = getActiveShop(auth?.user?.shop?.assigned)[
    appSettings?.selectedShopNumber
  ]?.shop?._id;

  const handleShopStockOut = async () => {
    if (auth?.token) {
      const res = await createStockOutAction(auth?.token, {
        isTransferred: channel?.selectedRequestedStock?.data[0]?.isTransferred,
        stockRequest: channel?.selectedRequestedStock?.data[0]?._id,
        shop: channel?.selectedRequestedStock?.data[0]?.shopId?._id,
      })(dispatch);
      if (res) {
        await updateStockRequestStatusAction(
          channel?.selectedRequestedStock?.data[0]?._id,
          { status: "done" },
          auth?.token
        )(dispatch);
        getAllStockToTransferAction(
          auth?.token,
          `?shopToTransfer=${shopId}&isTransferred=true&transferStatus=pending&limit=0&page=0`
        )(dispatch);
        notification.success({
          message: `${res?.warehouseOutId} has been created successfully.`,
        });
        navigate(
          `/pos/transfer/stock/${stockreqitemId}/${res?.warehouseOutId}`
        );
      } else {
        notification.error({ message: "Failed to create Transfer!" });
      }
    }
  };

  const handleRejectRequest = async () => {
    if (auth?.token) {
      const res = await updateStockRequestStatusAction(
        channel?.selectedRequestedStock?.data?.at(0)?._id,
        { status: "review", isTransferred: "false" },
        auth?.token
      )(dispatch);
      await updateStockReqItemAction(
        stockreqitemId,
        { isTransferred: false },
        auth?.token
      )(dispatch);
      res &&
        notification.success({
          message: "Request Rejected",
          description: "This request has been returned to review!",
        });
      res && navigate(`/pos/transfer`);
    }
  };

  const data = channel?.selectedRequestedStock?.data
    ?.at(0)
    ?.list?.map((element: any) => {
      return {
        model: element?.product?.product.model,
        specification: element?.product?.specification
          .slice(2)
          ?.map((el: any) => el[1] && `${el[0]}: ${el[1]}`)
          .join(", "),
        type: element?.product?.product.type,
        brand: element?.product?.product.brand,
        qty: element.qtyApproved,
        shop: element?.shopToTransfer?.name,
      };
    });

  return (
    <div className="text-black p-5 mt-3 space-y-2">
      <div className="p-5 bg-white rounded-lg space-y-6">
        <Table
          columns={columns}
          dataSource={data}
          style={{ width: "100%" }}
          loading={channel?.isFetching}
        />
        {channel?.selectedRequestedStock?.data[0]?.status !== "done" &&
          rfsId !== "undefined" && (
            <div>
              <LoadingButton
                style={{ marginLeft: "20px" }}
                variant="contained"
                onClick={() => handleShopStockOut()}
                loading={wareHouseOut?.isFetching}
              >
                Accept Request
              </LoadingButton>

              <LoadingButton
                variant="contained"
                style={{ marginLeft: "20px", backgroundColor: "#F50721" }}
                onClick={() => handleRejectRequest()}
                loading={wareHouseOut?.isFetching}
              >
                Reject Request
              </LoadingButton>
            </div>
          )}
      </div>
    </div>
  );
};

export default WsCreate;
