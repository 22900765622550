import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { Stack, IconButton } from "@mui/material";
import { Badge, Carousel, Tag } from "antd";
import { getColorByValue } from "../../../../utils/setColor";
import DeleteModal from "../../../Modals/DeleteModal";
import defaultPic from "../../../../assets/nophoto.png";
import {
  deleteNosaleAction,
  getAllNosaleAction,
} from "../../../../store/pos/actions";
import { getActiveShop } from "../../../../utils/converter";

const Component = (props: any) => {
  console.log(props);
  const { auth, pos, shop, appSettings } = useSelector((state: any) => state);
  const [deleteId, setDeleteId] = useState("");

  const selectedShopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id ?? shop?.selected?._id;

  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteModalCancels = () => {
    setVisible(false);
  };

  const handleClickDelete = async (value: any) => {
    setVisible(true);
    setDeleteId(value);
  };
  const deleteProduct = async () => {
    auth?.token &&
      (await deleteNosaleAction(auth?.token, deleteId, {
        isDeleted: "true",
      })(dispatch));
    await getAllNosaleAction(
      auth?.token,
      `?shopId=${selectedShopId}`
    )(dispatch);
  };

  return (
    <>
      <div className="rounded-md relative border border-white hover:border-gray-300 duration-500">
        <div className="relative w-80 h-56 p-2 pt-10 bg-white text-black rounded-md">
          <div className="flex w-fit">
            <div style={{ width: "5rem" }}>
              {props?.data?.priceListItemId?.product?.images?.length === 0 && (
                <img src={defaultPic} width={"100%"} alt="" />
              )}
              {props?.data?.priceListItemId?.product?.images?.length >= 1 && (
                <Carousel autoplay>
                  {props?.data?.priceListItemId?.product?.images?.map(
                    (d: any) => (
                      <div>
                        <img src={d} width={"100%"} alt="" />
                      </div>
                    )
                  )}
                </Carousel>
              )}
            </div>
            <Tag className="absolute right-2 top-4 px-2.5">No Sale</Tag>
            <Badge
              className="site-badge-count-10 text-sm p-2 h-1"
              style={{ backgroundColor: "#faad14" }}
            >
              <div className="flex flex-col pl-2 gap-1">
                <h1 className="font-medium text-xs">
                  <span className="font-semibold text-md">Model: </span>
                  {props?.data?.priceListItemId?.product?.model ||
                    props?.data.newProduct}
                </h1>
                <p className="font-medium text-xs">
                  <span className="font-semibold text-md">Customer: </span>
                  {props?.data?.customerId?.name}
                </p>
                <p className="font-medium text-xs">
                  <span className="font-semibold text-md">Telephone: </span>
                  {props?.data?.customerId?.phone}
                </p>
                <div className=" gap-1">
                  {props?.data?.priceListItemId?.specification &&
                    props?.data?.priceListItemId?.specification?.map(
                      (data: any, _index: number) =>
                        data[1] && (
                          <Stack>
                            <p className="text-xs">
                              <b>{data[0]}:</b>
                              {` ${data[1]}`}
                            </p>
                          </Stack>
                        )
                    )}
                </div>
              </div>
            </Badge>
          </div>
          <div className="absolute bottom-1 right-2 flex justify-end items-center w-full py-2 space-x-2">
            {props?.data?.productId?.type && (
              <Tag
                color={getColorByValue(
                  props?.data?.productId?.type,
                  props?.data?.productId?.type
                )}
              >
                {props?.data?.productId?.type}
              </Tag>
            )}

            {props?.data?.productId?.brand && (
              <Tag
                color={getColorByValue(
                  props?.data?.productId?.brand,
                  props?.data?.productId?.brand
                )}
              >
                {props?.data?.productId?.brand}
              </Tag>
            )}

            <IconButton
              aria-label="fingerprint"
              color="error"
              size="small"
              onClick={() => handleClickDelete(props?.data?._id)}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <DeleteModal
        visible={visible}
        onOk={deleteProduct}
        isLoading={pos.isFetching}
        onCancel={handleDeleteModalCancels}
        itemName="Product"
      />
    </>
  );
};

export default Component;
