import React from 'react'
import { Navigate, Outlet } from "react-router-dom";
import DashLayout from "../components/layout/Layout";

import TopNav from "../components/navitems/TopNavItems";
import {subMenusList } from "../assets/data/pages";
 import store from 'store';
import { useSelector } from 'react-redux';

const EarningRoutes = () => {
  const token = store.get("authToken");
  const { auth } = useSelector((state: any) => state)
  let authent = { token: true };
  const pagesArray = [
    {
      caption: "Earning",
      path: "/earning",
      onClick: () => {
        console.log("sale:00");
      },
    },
  ];
  return auth?.token || token ? (
    <DashLayout
      allowSearch={true}
      nav={<TopNav nav={subMenusList[1]} pages={pagesArray} />}
      selectedNav={1}
    >
      <Outlet />
    </DashLayout>
  ) : (
    <Navigate to="/dashboard" />
  );
};

export default EarningRoutes