import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getAllShopStockService = async (token: string, id: string) => {
  return HttpRequest.get(`${SERVER_URL}/stats/warehouseByproduct/${id}`, token);
};

export const getStockItemsStats = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/warehouse?${query || ""}`, token);
};
export const getStockStats = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/shopStats/?${query || ""}`, token);
};
export const getRecentActivities = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/shopStats/recent?${query || ""}`, token);
};
