import { notification } from "antd";
import { myInventoryActions } from ".";
import { getAllShopStockService, getRecentActivities, getStockItemsStats, getStockStats } from "./services";

export const getAllShopStockAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getAllShopStockService(token, id);
      // console.log(">>>>>>>:: shop stock>>>...", res);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setAllShopStock(res));
        dispatch(myInventoryActions.setIsFetching(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};

export const getStockInItemsActions = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getStockItemsStats(token, query);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setStockItems(res));
        dispatch(myInventoryActions.setIsFetching(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getSearchBySerialNumberActions = (
  token: string,
  query: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getStockItemsStats(token, query);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setSearchBySn(res));
        dispatch(myInventoryActions.setIsFetching(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getShopStatsActions = (
  token: string,
  query: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getStockStats(token, query);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setShopStats(res));
        dispatch(myInventoryActions.setIsFetching(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getShopRecentActivitiesActions = (
  token: string,
  query: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInventoryActions.setIsFetching(true));
      const res = await getRecentActivities(token, query);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myInventoryActions.setRecentActivities(res));
        dispatch(myInventoryActions.setIsFetching(false));
      }
      dispatch(myInventoryActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
