import { Navigate, Outlet, useNavigate } from "react-router-dom";
import DashLayout from "../components/layout/Layout";

import TopNav from "../components/navitems/TopNavItems";
import { subMenusList } from "../assets/data/pages";
import { getAllSuppliersAction } from "../store/supplier/actions";
import { authActions } from "../store/auth";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
 import store from "store";

import SelectShopPosModal from "../components/Modals/SelectShopPosModal";
import { myAppSettings } from "../store/appSettings";

// export

const POSRoutes = (props: any) => {
  const { auth, supplier } = useSelector((state: any) => state);
  const result = 3;
  const POSPagesArray = [
    {
      caption: "Overview",
      path: "/dashboard",
    },
    {
      caption: "Sale",
      path: "/pos/sale",
    },
    {
      caption: "No Sale",
      path: "/pos/nosale",
    },
    {
      caption: "Price List",
      path: "/pos/prices",
    },
  ];

  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const handleClose = () => navigateTo("/dashboard");

  const token = store.get("authToken");
  const userStorage = store.get("user");
  useEffect(() => {
    if (token) {
      dispatch(authActions.setToken({ token }));
    }
  }, [auth, dispatch, token]);

  useEffect(() => {
    auth?.token &&
      getAllSuppliersAction(auth?.token, supplier?.query || "?")(dispatch);
  }, [auth, dispatch, supplier.new, supplier?.query]);

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("authToken");
    localStorage.clear()
    dispatch(authActions.logout());
    navigateTo("/login");
  };
  useEffect(() => {
    const selectedShopIndex = localStorage.getItem("selectedShopIndex");
    if (selectedShopIndex) {
      dispatch(
        myAppSettings.setSelectedShopNumber(parseInt(selectedShopIndex))
      );
    }
  }, [dispatch]);

  if (auth.token) {
    return (
      <DashLayout
        allowSearch={true}
        nav={<TopNav nav={subMenusList[3]} pages={POSPagesArray} />}
        selectedNav={3}
      >
        {userStorage?.shop ? (
          <Outlet />
        ) : ["admin", "dev"].includes(auth?.user?.role) ? (
          <SelectShopPosModal handleClose={handleClose} showModal={true} />
        ) : (
          handleLogout()
        )}
      </DashLayout>
    );
  } else {
    return <Navigate to="/login/scan" />;
  }
};

export default POSRoutes;
