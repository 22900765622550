import React, { useEffect } from 'react'
import { getAllCartsAction } from '../../../store/pos/actions';
import { getActiveShop } from '../../../utils/converter';
import { useDispatch, useSelector } from 'react-redux';
import ScrollableFrame from '../../../components/layout/ScrollableFrame';
import { Spin } from 'antd';
import CartGridPOS from '../../../components/grids/POS/sale/CartGridPOS';

const SalesOverView = () => {
    const {auth, appSettings,pos}=useSelector((state:any)=>state)
    const dispatch =useDispatch();
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(15);
    const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.user?.shop?.shopId;
    useEffect(()=>{
        auth?.token &&
        getAllCartsAction(
          auth?.token,
          `?shop=${shopId}&status[]=pending&status[]=rejected&status[]=accepted&status[]=requested&status[]=inquiry`
        )(dispatch);
    },[dispatch])
    console.log("pos?.carts?.data?",pos?.carts)
  return (
    <div>
        <ScrollableFrame 
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={pos?.carts?.total}
          count={Math.ceil(pos?.carts?.total / limit)}
        >
        {pos?.isFetching ? (
          <div className="flex flex-col items-center justify-center mx-auto mt-32">
            <span>
              {" "}
              <Spin spinning />
            </span>
            <span className="ml-3">Loading...</span>
          </div>
        ) : (
            <div className="flex flex-wrap gap-6">{<CartGridPOS/>}</div>
         
        )}
      </ScrollableFrame>
    </div>
  )
}

export default SalesOverView