import { Stack, Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import PosProductGrid from "../../../components/grids/priceList/PosProductGrid";
import { getPricelistAction } from "../../../store/pos/actions";
// import ProductFilter from "../../../components/filters/ProductFiltering";
import PriceListFilter from "../../../components/filters/PriceListFiltering";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import SearchInput from "../../../components/buttons/SearchButton";
import HeaderShops from "../../../components/headerShops";
import { GoListUnordered } from "react-icons/go";
import { TbGridDots } from "react-icons/tb";
import { Radio } from "antd";
import DatePick from "../channels/DatePicker";
//  

const PageView = (props: any) => {
  const { auth, pos } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchKey, setSearchKey] = React.useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const channelId = auth?.user?.shop?.channel || auth?.user?.shop?.channelId;
  const [viewDetails, setViewDetails] = useState(false);

  useEffect(() => {

    if (auth?.token && channelId) {
      getPricelistAction(
        auth?.token,
        `?channel=${channelId}&page=${page - 1}&limit=${limit}`
      )(dispatch);
    }
  }, [auth?.token, channelId, dispatch, limit, page]);

  const goBack = () => {
    navigate(-1);
  };


  const handleSearch = () => {
    if (auth?.token && channelId) {
      getPricelistAction(
        auth?.token,
        `/searchPriceList?channel=${channelId}&sk=${searchKey}&page=${page - 1
        }&limit=${limit}`
      )(dispatch);
    }

  };

  return (
    <div className="flex flex-col h-full">
      <div className="mt-s4 flex justify-between">
        {!viewDetails ? (
          <>
            <div className="flex gap-5 flex-wrap">
              <PriceListFilter  />
            </div>
            <div className="flex  items-center gap-5">
              <SearchInput
                onSearch={handleSearch}
                onChange={(e: any) => {
                  e.preventDefault();
                  setSearchKey(e.target.value);
                }}
              />
              <div className="bg-[#605BFF] flex justify-center items-center h-[30px] w-[30px] mt-1.5 rounded-md">
                <GoListUnordered size={23} color="#ffff" />
              </div>
              <div className="bg-white flex justify-center items-center h-[30px] w-[30px] mt-1.5 rounded-md">
                <TbGridDots size={23} color="#C0C0C9" />
              </div>
              <hr className="h-7 w-0.5 bg-gray-300 mt-1.5" />
            </div>
          </>
        ) : (
          <div className="flex justify-between w-full">
            <Radio.Group
              onChange={handleSearch}
              optionType="button"
              buttonStyle="solid"
            />
            <div className="flex gap-5">
              <DatePick />
              <hr className="h-7 w-0.5 bg-gray-300 mt-1.5" />
              <div className="bg-[#605BFF] flex justify-center items-center h-[30px] w-[30px] mt-1.5 rounded-md">
                <GoListUnordered size={23} color="#ffff" />
              </div>
              <div className="bg-white flex justify-center items-center h-[30px] w-[30px] mt-1.5 rounded-md">
                <TbGridDots size={23} color="#C0C0C9" />
              </div>
            </div>
          </div>
        )}
      </div>
      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={pos?.priceList?.total}
        count={Math.ceil(pos?.priceList?.total / limit)}
      >
        {pos?.isFetching && (
          <div className="flex flex-wrap gap-4 justify-start items-start">
            {Array.from({ length: 14 }).map((d: any) => (
              <div className="w-80">
                <Skeleton animation="wave" />
                <Skeleton variant="rectangular" height={150} />
              </div>
            ))}
          </div>
        )}
        {!pos.isFetching && (
          <div className="flex flex-wrap gap-4 justify-start items-start mt-5">
            {pos?.priceList?.status === 200 && (
              <PosProductGrid data={pos?.priceList?.data} />
            )}
          </div>
        )}
      </ScrollableFrame>
    </div>
  );
};
export default PageView;
