import React, { useState, useEffect } from "react";
import { Radio, Modal, Alert } from "antd";
// import { handlePaymentMode } from "../../store/layout/actions";
import { useDispatch, useSelector } from "react-redux";
import type { RadioChangeEvent } from "antd";
import { useParams } from "react-router-dom";
import { updateShopStockCartDetailsAction } from "../../store/wareHouse/actions";
import { IoMdAlert } from "react-icons/io";
import { ReactComponent as InventoryIcons } from "../../assets/icons/dashboard/fontisto_shopping-pos-machine.svg"
import  YellowIcons  from "../../assets/icons/dashboard/Yellow Logo 1.png"
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const PamentModeDrower= (props:any) => {
  const { auth,wareHouse } = useSelector((state: any) => state);
  const [isActive, setIsActive] = useState("");

  const dispatch = useDispatch();
  const { cartId } = useParams();

  const onChange = async (e: RadioChangeEvent) => {
    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      isSubscription: e.target.value,
    })(dispatch);
    setIsActive(e.target.value);
    props.onClick();
  };

  const channelId = auth?.user?.shop?.channelId || auth?.user?.shop?.channel;

  useEffect(() => {
    channelId !== process.env.REACT_APP_Save_The_Children_Channel_ID &&
      updateShopStockCartDetailsAction(auth?.token, cartId, {
        isSubscription: "pos",
      })(dispatch);
  }, [auth?.token, channelId, dispatch]);

  return (
    <>
      {/* <Modal
        title="Select Buying Mode"
        footer={null}
        closable={false}
        open={showModal}
      > */}
        <div className="xmt-24 h-[20rem] w-full">
          <div className="flex flex-col items-center justify-around w-full h-[15vh]">
            <Radio.Group
              onChange={onChange}
              value={isActive}
              className=" w-[24rem]"
            >
              <Radio
                value={"pos"}
                className={`w-full h-42 rounded-2xl bg-[#0302290D] 
                flsex p-4 px-10 gap-4`}
              >
                <div className="flex justify-between divide-x-2 w-[18rem]">
                <div>
                POS
                 <p className="text-[#030229] text-xl font-semibold">Normal Sale</p>
              </div>
              
               <div className="pl-10 pt-2">
                 <InventoryIcons className="w-14 h-12"/>
                 </div>
                 </div>
              </Radio>
              <Radio
                value={"subscription"}
                className={`w-full h-42 mt-2 rounded-2xl bg-[#0302290D] 
                flsex p-4 px-10 gap-4`}
              >
               <div className="flex justify-between divide-x-2 w-[18rem]">
                <div>
                B2C
                 <p className="text-[#030229] text-xl font-semibold">Device Financing</p>
              </div>
              
               <div className="pl-5">
                 <img src={YellowIcons} alt="Yellow" className="w-14 h-12 mt-1"/>
                 </div>
                 </div>
              </Radio>
            </Radio.Group>
            {wareHouse?.isFetching && <div><Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} /></div>}
            <div className="flex gap-2 mt-14 text-base px-6 py-1 text-[#5F5AFE] border border-[#5F5AFE] rounded-[4px]"><IoMdAlert color="#5F5AFE" size={18} className="mt-1"/> Selecting selling mode required! kindly make choice Now!</div>
          </div>
        </div>
    </>
  );
};

export default PamentModeDrower;
