import React, { useState, useEffect, useRef } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Form, Input, Spin } from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector, useDispatch } from "react-redux";
import {
  createCustomerAction,
  updateCustomerAction,
} from "../../store/customer/actions";
import ReactFlagsSelect from "react-flags-select";
// import UploadComponent from "../forms/Upload";
import Webcam, { WebcamProps } from "react-webcam";
import { customerProfileAction } from "../../store/pos/actions";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import WebCam from "../buttons/WebCam";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const App = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { customer, auth, pos, wareHouse } = useSelector((state: any) => state);
  const [pictures, setPictures] = useState([]);
  const [selectedCoutry, setSelectedCountry] = useState("250");
  const isUpdate = props?.action === "update";
  const firstNameToUpdate = props?.dataToUpdate?.name?.split(" ")[0];
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("camera");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const webcamRef = useRef<Webcam | null>(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [capturedImage, setCapturedImage] = useState<any | null>(null);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const profile = props?.dataToUpdate?.nationalId;

  const onFinish = async (values: any) => {
    const images = pictures.map((pic: any) => pic?.response?.data?.secure_url);
    const name = `${values?.firstName}  ${values?.lastName}`;
    delete values.firstName;
    delete values.lastName;

    if (isUpdate) {
      console.log("updating...");
      auth?.token &&
        (await updateCustomerAction(auth?.token, props?.dataToUpdate?._id, {
          ...values,
          name,
          picture: images[0],
          // nationalId: capturedImage
          //   ? pos?.customerProfile?.data?.secure_url
          //   : profile,
        })(dispatch));
    } else {
      console.log("saving...", values);
      auth?.token &&
        createCustomerAction(auth?.token, {
          ...values,
          name,
          picture: images[0],
          // nationalId: pos?.customerProfile?.data?.secure_url,
        })(dispatch);
    }
    props?.onCancel();
    form.resetFields();
  };

  // dispatch(myProductActions.setQuery(`?`));
  useEffect(() => {
    // auth?.token &&
    //   getAllCustomersAction(
    //     auth?.token,
    //     `?page=${props?.page}&limit=${props?.limit}`
    //   )(dispatch);
  }, [auth, dispatch, props?.limit, props?.page]);

  console.log("selectedCoutry>>>", selectedCoutry);

  const handleCountryChange = (country: any) => {
    setSelectedCountry(country);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  const handlerDropDown = (value: string) => {
    setSelectedOption(value);
    closeDropdown();
  };

  const videoConstraints: WebcamProps["videoConstraints"] = {
    facingMode: "user",
  };

  const openCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: videoConstraints,
      });
      setMediaStream(stream);
      setIsCameraOpen(true);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const closeCamera = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
    }
    setIsCameraOpen(false);
  };

  const captureImage = async () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setCapturedImage(imageSrc);
      if (imageSrc) {
        customerProfileAction(auth?.token, {
          image: imageSrc,
        })(dispatch);
      }
      setIsCameraOpen(false);
      closeCamera();
    }
  };

  useEffect(() => {
    !isCameraOpen && setIsModalOpen(false);
  }, [isCameraOpen]);

  useEffect(() => {
    !isModalOpen && setMediaStream(null);
  }, [isModalOpen]);
console.log("data",props?.dataToUpdate)
useEffect(() => {
  if (props?.dataToUpdate) {
    form.setFieldsValue(props?.dataToUpdate);
  } else {
    form.resetFields();
  }
}, [form, props?.dataToUpdate]);

const initialData = {
  ...props?.dataToUpdate,
  firstName: props?.dataToUpdate?.name?.split(" ")[0],
  lastName: props?.dataToUpdate?.name
    ?.slice(firstNameToUpdate?.length)
    ?.trim(),
};
  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        initialValues={initialData ||{}}
        style={{ maxWidth: "100%" }}
        scrollToFirstError
        className="flex justify-center flex-col antd-custom-styl py-1"
      >
        <div className="flex justify-center gap-x-20 h-full">
          <div className="flex-1 rounded-lg space-y-2">
            <p className="lg:text-md md:text-sm font-semibold">{`${
              props?.isBusiness ? "Contact Info" : "Required"
            }`}</p>
            <Form.Item
              name="firstName"
              // label="First Name"
              tooltip="Please enter customer's first name"
              rules={[
                {
                  required: true,
                  message: "First Name is required!",
                  whitespace: true,
                },
              ]}
              getValueFromEvent={(e) =>
                e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
              }
            >
              <Input
                className="pl-4 h-[5vh] border border-[#0302291A]"
                placeholder="First Name"
              />
            </Form.Item>

            <Form.Item
              name="lastName"
              // label="Last Name"
              tooltip="Please enter customer's last name"
              rules={[
                {
                  required: true,
                  message: "Last Name is required!",
                  whitespace: true,
                },
              ]}
              getValueFromEvent={(e) =>
                e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
              }
            >
              <Input
                className="pl-4 h-[5vh] border border-[#0302291A]"
                placeholder="Last Name"
              />
            </Form.Item>

            <Form.Item
              // label="Telephone"
              name="phone"
              // rules={[
              //   {
              //     required: true,
              //     message: "Phone Number is required!",
              //     max: 18,
              //   },
              // ]}
              rules={[
                {
                  validator: (_, value) => {
                    const phoneNumberLength = value
                      ? value.replace(/\D/g, "").length
                      : 0;
                    if (selectedCoutry?.slice(0, 3) === "250") {
                      if (phoneNumberLength === 12) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Phone Number should be exactly 9 Digits.")
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
              hasFeedback
            >
              <PhoneInput
                onChange={handleCountryChange}
                country={"rw"}
                // buttonStyle={{ border: "none", borderRight: "3px solid #fff" }}
                inputStyle={{
                  width: "100%",
                  height: "46px",
                  border: "1px solid #0302291A",
                }}
                containerClass="phone-container"
                placeholder="+250 780 000 000"
              />
            </Form.Item>

            <Form.Item
              name="nid"
              // label="National ID"
              tooltip="Please enter NID?"
              rules={[
                {
                  required: true,
                  message: "NID is required!",
                  whitespace: true,
                },
              ]}
            >
              <Input
                className="pl-4 h-[5vh] border border-[#0302291A]"
                placeholder="National ID"
              />
            </Form.Item>
          </div>
          <div className="flex-1 rounded-lg space-y-2">
            {!props?.isBusiness && (
              <>
                <p className="font-semibold lg:text-md md:text-sm"> Optional</p>

                <Form.Item
                  name="email"
                  // label="Email"
                  tooltip="Please enter customer email?"
                >
                  <Input
                    className="pl-4 h-[5vh] border border-[#0302291A]"
                    placeholder="Email"
                  />
                </Form.Item>

                <Form.Item
                  name="address"
                  // label="Address"
                  tooltip="Please enter business address"
                  initialValue={props?.dataToUpdate?.address}
                >
                  <Input
                    className="pl-4 h-[5vh] border border-[#0302291A]"
                    placeholder="Street address"
                  />
                </Form.Item>

                <Form.Item
                  name="city"
                  // label="City"
                  tooltip="Please enter customer city name?"
                  initialValue={props?.dataToUpdate?.city}
                >
                  <Input
                    className="pl-4 h-[5vh] border border-[#0302291A]"
                    placeholder="City"
                  />
                </Form.Item>

                <Form.Item
                  name="country"
                  // label="Country"
                  tooltip="Please enter customer country name"
                  initialValue={props?.dataToUpdate?.country}
                >
                  {/* <Input
                  className="h-[5vh] border border-[#0302291A]"
                  placeholder="Country"
                /> */}
                  <ReactFlagsSelect
                    selected={selectedCoutry}
                    searchable
                    onSelect={(code) => setSelectedCountry(code)}
                    className="dropdown-up h-[5vh] border border-[#0302291A] rounded-md flex items-center justify-center"
                  />
                </Form.Item>

                {/* <Form.Item
                name="street"
                // label="Street"
                tooltip="Please enter customer street address?"
                initialValue={props?.dataToUpdate?.street}
              >
                <Input
                  className="h-[5vh] border border-[#0302291A]"
                  placeholder="Street"
                />
              </Form.Item>

              

              <Form.Item
                name="province"
                // label="Province"
                tooltip="Please enter customer province name"
                initialValue={props?.dataToUpdate?.province}
              >
                <Input
                  className="h-[5vh] border border-[#0302291A]"
                  placeholder="Province"
                />
              </Form.Item>

               */}
              </>
            )}

            {props?.isBusiness && (
              <>
                <p className="font-semibold lg:text-md md:text-sm">
                  Business Info
                </p>

                <Form.Item
                  name="business_name"
                  // label="Legal Name"
                  tooltip="Please enter busness name"
                  initialValue={props?.dataToUpdate?.business_name}
                  rules={[
                    {
                      required: true,
                      message: "Business Name is required!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    className="pl-4 h-[5vh] border border-[#0302291A]"
                    placeholder="Legal Name"
                  />
                </Form.Item>

                <Form.Item
                  name="tinNumber"
                  // label="TIN"
                  tooltip="Please enter business TIN number?"
                  initialValue={props?.dataToUpdate?.tinNumber}
                  rules={[
                    {
                      required: true,
                      message: "TIN Nmber is required!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    className="pl-4 h-[5vh] border border-[#0302291A]"
                    placeholder="TIN"
                  />
                </Form.Item>

                <Form.Item
                  name="email"
                  // label="Email"
                  tooltip="Please enter business email?"
                  initialValue={props?.dataToUpdate?.email}
                >
                  <Input
                    className="pl-4 h-[5vh] border border-[#0302291A]"
                    placeholder="Email"
                  />
                </Form.Item>

                <Form.Item
                  name="address"
                  // label="Address"
                  tooltip="Please enter business address"
                  initialValue={props?.dataToUpdate?.address}
                >
                  <Input
                    className="pl-4 h-[5vh] border border-[#0302291A]"
                    placeholder="Address"
                  />
                </Form.Item>
              </>
            )}
          </div>
        </div>
        <div className="flex justify-end gap-x-4 mr-5 mt-2 !h-4 box-border">
          
          <Form.Item {...tailFormItemLayout}>
            <LoadingButton
              // type="submit"
              sx={{width:"100px",borderRadius:"10px"}}
              style={{ padding: "6px" }}
              variant="outlined"
              loading={customer?.isFetching}
              onClick={props?.onCancel}
            >
             <h1 className="text-[#5F5AFE] text-base font-medium">{"Discard"}</h1> 
            </LoadingButton>
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <LoadingButton
              type="submit"
              sx={{width:"100px",borderRadius:"10px",backgroundColor:"#5F5AFE"}}
              variant="contained"
              style={{ padding: "6px" }}
              loading={customer?.isFetching}
            >
              {isUpdate ?    <h1 className="text-[#ffff] text-base font-medium">{"Update"}</h1>  :   <h1 className="text-[#ffff] text-base font-medium">{"Save"}</h1> }
            </LoadingButton>
          </Form.Item>
        </div>
      </Form>

      <WebCam
        isModalOpen={isModalOpen}
        isCameraOpen={isCameraOpen}
        title={"National ID"}
        captureImage={captureImage}
        closeCamera={closeCamera}
        videoConstraints={videoConstraints}
        webcamRef={webcamRef}
      />
    </>
  );
};

export default App;
