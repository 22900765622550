import React, { useState } from "react";
import productImage from "../../../assets/images/Rectangle 1381.png";
import {
  Button,
  Carousel,
  Divider,
  InputNumber,
  notification,
  Skeleton,
  Space,
  Switch
} from "antd";
import CustomButton from "../../../components/buttons/CustomButton";
import {
  getShopStockCartAction,
  updateShopStockCartItemDetailsAction,
  updateWareHouseDetailsAction
} from "../../../store/wareHouse/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getActivePrice } from "../../../utils/converter";
const OrderCompletedCard = (props: any) => {
  const { auth,wareHouse } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { cartId } = useParams();
  const [enabledStates, setEnabledStates] = React.useState<any>(
    props?.data?.map((d: any) => !!d?.payment?.extendedWarranty) || []
  );
  const [dataActivatedStates, setDataActivatedStates] = React.useState(
    props?.data?.map((d:any) =>d?.warehouseProduct?.dataActivated)
  );
  const [isNewSimCard, setIsNewSimCard] = React.useState(
    props?.data?.map((d:any) => d?.warehouseProduct?.isNewSimcard? d?.warehouseProduct?.isNewSimcard: false)
  );
  const handleSwitchChange = async (checked: boolean, index: number) => {
    const newEnabledStates = [...enabledStates];
    newEnabledStates[index] = checked;
    setEnabledStates(newEnabledStates);
    if (auth?.token) {
      const item = props.data[index];
      updateShopStockCartItemDetailsAction(auth?.token, item?._id, {
        payment: {
          ...item?.payment,
          extendedWarranty: checked
            ? item?.shopStockItem?.requestedItem?.product?.extendedWarranty
            : 0
        }
      })(dispatch);
    }
  };
  const handleSwitchDataActivated = async (checked: boolean, index: number) => {
    const newDataActivatedStates = [...dataActivatedStates];
    newDataActivatedStates[index] = checked;
    setDataActivatedStates(newDataActivatedStates);

    if (auth?.token) {
      const item = props.data[index];
      await updateWareHouseDetailsAction(
        auth?.token,
        item?.warehouseProduct?._id,
        {
          dataActivated: checked
        }
      )(dispatch);
      await getShopStockCartAction(auth?.token, cartId)(dispatch);
    }
  };
  const handleSwitchNewSimCard = async (checked: boolean, index: number) => {
    const newSimCardStates = [...isNewSimCard];
    newSimCardStates[index] = checked;
    setIsNewSimCard(newSimCardStates);

    if (auth?.token) {
      const item = props.data[index];
      await updateWareHouseDetailsAction(
        auth?.token,
        item?.warehouseProduct?._id,
        {
          isNewSimcard: checked
        }
      )(dispatch);
      await getShopStockCartAction(auth?.token, cartId)(dispatch);
    }
  };
  return (
    <>
    {wareHouse?.isFetching? <Skeleton/>:
    <div>
      {props?.data?.map((d: any, index: number) => {
        const extendedWarranty =
          d?.shopStockItem?.requestedItem?.product?.extendedWarranty;

        return (
          <div
            className="border border-[#03022940] rounded-lg p-3 mt-2"
            key={index}
          >
            <div className="flex gap-10 relative">
              <div className="bg-[#E5E5E5] w-[100px] h-[100px] p-2 rounded-xl">
                <Carousel autoplay className="w-full h-full">
                  {d?.shopStockItem?.requestedItem?.product?.product?.images?.map(
                    (im: any) => (
                      <div>
                        <img src={im} className="w-full h-full" alt="" />
                      </div>
                    )
                  )}
                </Carousel>
              </div>
              <div>
                <h1 className="text-[#030229] text-base font-medium">
                  {d?.shopStockItem?.requestedItem?.product?.product?.model}
                </h1>
                <p>
                  <span className="text-[#030229B2] text-sm">Type:</span>{" "}
                  <span className="text-[#030229] text-sm pl-1">
                    {d?.shopStockItem?.requestedItem?.product?.product?.type}
                  </span>
                </p>
                <p>
                  <span className="text-[#030229B2] text-sm">Brand:</span>{" "}
                  <span className="text-[#030229] text-sm pl-1">
                    {d?.shopStockItem?.requestedItem?.product?.product?.brand}
                  </span>
                </p>
                {d?.shopStockItem?.requestedItem?.product?.specification?.map(
                  (data: any, index: number) => {
                    const regex = /\((.*?)\)/;
                    const match = data[0].match(regex);

                    if (match) {
                      const stringFromBracket = match[1];

                      if (
                        !["type", "brand"].includes(
                          stringFromBracket.toLowerCase()
                        )
                      ) {
                        return (
                          <div className="flex" key={index}>
                            <p>
                              <span className="text-[#030229B2] text-sm">{`${stringFromBracket}: `}</span>
                            </p>
                            <p className="pl-1 text-[#030229] text-sm ">
                              {data[1]}
                            </p>
                          </div>
                        );
                      }
                    }

                    return null;
                  }
                )}
                <p>
                  <span className="text-[#030229B2] text-sm">IMEI:</span>{" "}
                  <span className="text-[#030229] text-sm pl-1">
                    {d?.warehouseProduct?.serialNumber}
                  </span>
                </p>
                <p>
                  <span className="text-[#030229B2] text-sm">Price:</span>{" "}
                  <span className="text-[#030229] text-sm pl-1">
                  {d?.payment?.amount?.toLocaleString()} RWF
                  </span>
                </p>
              </div>
              <div className="absolute bottom-0 right-0">
                <div className="border border-[#03022940] px-2 py-1 rounded-md flex gap-2">
                  <p className="text-[#030229B2] text-[12px]">
                    Extended Warranty({extendedWarranty}%)
                  </p>
                  <Switch
                    checked={enabledStates[index]}
                    onChange={(checked) => handleSwitchChange(checked, index)}
                  />
                </div>

                <div className="border border-[#03022940] px-2 py-1 rounded-md flex justify-between mt-1">
                  <p className="text-[#030229B2] text-[12px]">Activate Data</p>
                  <Switch
                    checked={dataActivatedStates[index]}
                    onChange={(checked) =>
                      handleSwitchDataActivated(checked, index)
                    }
                  />
                </div>
                <div className="border border-[#03022940] px-2 py-1 rounded-md flex justify-between mt-1">
                  <p className="text-[#030229B2] text-[12px]">New SimCard</p>
                  <Switch
                    checked={isNewSimCard[index]}
                    onChange={(checked) =>
                      handleSwitchNewSimCard(checked, index)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>}
    </>
  );
};

export default OrderCompletedCard;
