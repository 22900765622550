import React from "react";
import { createShopStockCartAction } from "../../../store/wareHouse/actions";
import { getActiveShop } from "../../../utils/converter";
import { useDispatch, useSelector } from "react-redux";
import { myCustomerActions } from "../../../store/customer";
import { myLayoutActions } from "../../../store/layout";
import { useNavigate } from "react-router-dom";
import { getAllCartsAction } from "../../../store/pos/actions";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const CreateSalesBtn = (props:any) => {
  const { auth, appSettings,wareHouse } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleCreateSale = async () => {
    const activeShop = getActiveShop(auth?.user?.shop?.assigned);
    auth?.token &&
      (await createShopStockCartAction(auth?.token, {
        shop:
          getActiveShop(auth?.user?.shop?.assigned)[
            appSettings?.selectedShopNumber
          ]?.shop?._id ?? auth?.user?.shop?.shopId,
          isSubscription:"pos"
      })(dispatch));
      auth?.token &&
      getAllCartsAction(
        auth?.token,
        `?shop=${
          activeShop[appSettings?.selectedShopNumber]?.shop?._id ??
          auth?.user?.shop?.shopId
        }&status[]=pending&status[]=rejected&status[]=accepted&status[]=requested&status[]=inquiry`
      )(dispatch);
    dispatch(myCustomerActions.setSelected(null));
    dispatch(myLayoutActions.setIsNext(0));
    props?.setShowCreateSales(false)
  };
  React.useEffect(() => {
    if (wareHouse?.createdCart?.data?._id) {
      if (wareHouse?.isCartCreated) {
        navigate(`sale/${wareHouse?.createdCart?.data?._id}`);
      }
    }
  }, [navigate, wareHouse?.createdCart?.data?._id, wareHouse?.isCartCreated]);


  return (
    <div
      className={`bg-[#7782FF] cursor-pointer flex h-[90px] w-[90px] m-auto rounded-full items-center justify-between text-center  px-2 fade-in`}
      onClick={() => handleCreateSale()}
    >{wareHouse?.isFetching? <Spin className="ml-5 text-white"  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />:
      <h1 className="text-white text-base font-medium">Create Sales</h1>}
    </div>
  );
};

export default CreateSalesBtn;
