import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { authActions } from "../../store/auth";
import { getActiveShop } from "../../utils/converter";
import { getOneShopAction } from "../../store/shop/actions";
import SideNav from "./SideNav";
import "../buttons/button.css";
import { getAllCartsAction } from "../../store/pos/actions";
import HeaderShops from "../headerShops";
import React from "react";
import store from "store";
import Header from "./Header"
const Layout = (props: any) => {
  const dispatch = useDispatch();
  const { auth, shop, appSettings, pos } = useSelector((state: any) => state);
  const location = useLocation();
  const [activeShops, setActiveShops] = React.useState<any[]>([]);
  const ishome = ["/dashboard"].includes(location.pathname);
  const removeGlass =
    location.pathname.includes("pos/sale/") || location.pathname === "/repair";
  const shopInfo =
    getActiveShop(auth?.user?.shop?.assigned)[
      appSettings?.selectedShopNumber
    ] || shop?.myShop;

  useEffect(() => {
    dispatch(authActions.setIsFetching(true));
    dispatch(authActions.setUser(store.get("user")));
    dispatch(authActions.setIsFetching(false));
  }, [auth.token, dispatch]);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      auth?.token &&
        getOneShopAction(
          JSON.parse(user)?.shop?.shopId,
          auth?.token,
          true
        )(dispatch);
    }
  }, [auth?.token, dispatch]);

  const [open, setOpen] = useState(true);
  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.user?.shop?.shopId;
  useEffect(() => {
    auth?.token &&
      getAllCartsAction(
        auth?.token,
        `?shop=${shopId}&status[]=pending&status[]=rejected&status[]=accepted&status[]=requested&status[]=inquiry`
      )(dispatch);
  }, [dispatch]);

  // localStorage.setItem('selectedShopId', auth?.user?.shop?.assigned[0]?._id);

  React.useEffect(() => {
    setActiveShops(getActiveShop(auth?.user?.shop?.assigned));
  }, [auth?.user?.shop?.assigned]);

  return (
    <div className="flex">
      <SideNav open={open} setOpen={setOpen} />
      <div className="flex flex-col w-full">
        <Header open={open} data={pos} />
        <main
          className={`flex-grow px-5 ${
            open ? "pl-[250px]" : "pl-[100px]"
          } pt-24 bg-[#FAFAFB] overflow-auto max-h-screen`}
        >
          <div className="mb-5">
            <HeaderShops selectedShop={activeShops} />
          </div>
          {props.children}
        </main>
      </div>
    </div>
  );
};

export default Layout;
