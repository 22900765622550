import React, { useState } from "react";
import { Button, Modal } from "antd";

interface modalProps {
  component?: any;
  title?: any;
  width?: any;
  openModal?: any;
  closeModal?: any;
  cancelModal?: any;
}
const CustomModal = ({
  component,
  title,
  width,
  openModal,
  closeModal,
  cancelModal
}: modalProps) => {
  return (
    <>
      <Modal
        title={title}
        open={openModal}
        onOk={closeModal}
        onCancel={cancelModal}
        width={width}
        footer={null}
      >
        {component}
      </Modal>
    </>
  );
};

export default CustomModal;
