import React, { useState } from 'react'
import OverViewCard from '../../../components/cards/inventory/OverViewCard'

import { useDispatch, useSelector } from "react-redux";
import { Input, Spin } from "antd";
import { ReactComponent as BoxIcons } from "../../../assets/icons/dashboard/box-tick.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { Radio } from 'antd';
import type { RadioChangeEvent } from 'antd';
import { TbGridDots } from 'react-icons/tb';
import { GoListUnordered, GoPlus } from 'react-icons/go';
import type { SearchProps } from "antd/es/input/Search";
import Search from "antd/es/input/Search";
import { SearchOutlined } from "@ant-design/icons";
import CustomButton from '../../../components/buttons/CustomButton';
import StockTransferTable from '../../../components/tables/StockTransferTable';
import { getActiveShop } from '../../../utils/converter';
import { overViewStatActions } from '../../../store/posOverView/actions';
import store from 'store';
const StockTransfer = () => {
    const { posOverView,appSettings,auth,shop } = useSelector((state: any) => state);
    const dispatch=useDispatch();
    const [value4, setValue4] = useState('pending');
    const onChange4 = ({ target: { value } }: RadioChangeEvent) => {
      setValue4(value);
    };
    const options = [
      { label: 'Pending Stock ', value: 'pending' },
      // { label: 'Approved Stock ', value: 'request', title: 'done' },
      { label: 'In Transit Stock ', value: 'In-Transit'},
      // { label: 'Review ', value: 'review'},
    ];
    const shopId=getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]?.shop?._id || auth?.user?.shop?.shopId;
    // const shopId =
    // getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
    //   ?.shop?._id ?? shop?.selected?._id;

  React.useEffect(() => {
    auth?.token && overViewStatActions(auth?.token, shopId)(dispatch);
  }, [auth?.token, dispatch, shopId]);
  return (
    <div className='text-black'>
       <h1 className="text-[#030229d8] text-xl ">Stock Transfer</h1>
       <div className="flex flex-wrap gap-4 mt-3">
        <OverViewCard
          total={
            posOverView.setIsFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              posOverView?.overViewStats?.data?.stockRequests || 0
            )
          }
          text="Total Requested"
          icon={<BoxIcons />}
        />
        <OverViewCard
          total={
            posOverView.setIsFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              posOverView?.overViewStats?.data?.totalCurrentDevices || 0
            )
          }
          text="Total Stock In"
          icon={<BoxIcons />}
        />
        <OverViewCard
          total={
            posOverView.setIsFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              posOverView?.overViewStats?.data?.totalDevicesSold || 0
            )
          }
          text="Total Sold"
          icon={<BoxIcons />}
        />
        <OverViewCard
          total={
            posOverView.setIsFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              posOverView?.overViewStats?.data?.transfer ||0
            )
          }
          text="Total Transfer"
          icon={<BoxIcons />}
        />
      </div>
      <div className='mt-5 flex justify-between'>
      <Radio.Group
        options={options}
        onChange={onChange4}
        value={value4}
        optionType="button"
        buttonStyle="solid"
      />
    <div className="flex gap-5">
          {/* <Search
            placeholder="input search text"
            onSearch={(value, _e) => console.log(value)}
            style={{ width: 200,marginTop:"5px" }}
            prefix={
              <SearchOutlined style={{ color: "#1677ff", fontSize: "16px" }} />
            }
          /> */}
          <div className='bg-[#605BFF] flex justify-center items-center h-[30px] w-[30px] mt-1.5 rounded-md'>

          <GoListUnordered size={23}  color='#ffff'/>
          </div>
          <div className='bg-white flex justify-center items-center h-[30px] w-[30px] mt-1.5 rounded-md'>

          <TbGridDots size={23}  color='#C0C0C9'/>
          </div>
          <hr className="h-7 w-0.5 bg-gray-300 mt-1.5"/>
        </div>
      </div>
      <div className='mt-5'>
        <StockTransferTable selectedValueButton={value4}/>
      </div>
        </div>
  )
}

export default StockTransfer