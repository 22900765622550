import { Badge, Carousel, Form } from "antd";
import SearchBar from "../SearchBar";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { getActivePrice } from "../../utils/converter";
import BarcodeScannerModal from "../BarcodeScannerModal";
import { useState } from "react";
import { ReactComponent as SearchItemImage } from "../../assets/images/Scan to sell Option 2.svg";
import { searchCartItemBySerialNumberAction } from "../../store/repair/actions";
import { myRepairActions } from "../../store/repair";

const RepairProductSearch: React.FC<{ isView: boolean }> = ({ isView }) => {
  const { auth, wareHouse, repair } = useSelector((state: any) => state);
  const [scannedCode, setScannedCode] = useState<string>();
  const [newSearch, setNewSearch] = useState<boolean>(false);
  const dispatch = useDispatch();
  const productData = isView
    ? repair?.repairSearchResult?.cartItemId
    : repair?.repairSearchResult;

  const isFound = repair?.repairSearchResult;
  // repair?.searchResult?.data?.product?.status !== "sold";

  const onFinish = async (values: any) => {
    auth?.token &&
      (await searchCartItemBySerialNumberAction(
        auth?.token,
        values?.query
      )(dispatch));
  };

  const getCode = (code: string) => {
    setNewSearch(false);
    onFinish({ query: code });
    setScannedCode(code);
  };
  const handleChange = (e: any) => {
    setNewSearch(true);
  };

  const discardSearchResult = () => {
    dispatch(myRepairActions.setRepairSearchResult(null));
  };

  const itemPrice = getActivePrice(
    productData?.shopStockItem?.requestedItem?.product?.prices
  );

  return (
    <div
      className={`relative bg-white rounded-lg text-black pl-4 pr-4 pt-2 pb-2 leading-none box-border`}
    >
      <div className="flex flex-col justify-between h-[calc(100%-50px)]">
        {!isFound && (
          <div className="flex flex-col">
            <div className="flex justify-center gap-x-2">
              <Form onFinish={onFinish}>
                <Form.Item
                  name="query"
                  className="rounded-lg"
                  // label="Enter serial number"
                  initialValue={scannedCode}
                >
                  <SearchBar
                    isLoading={wareHouse.isFetching}
                    scannedCode={newSearch ? "" : scannedCode}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Form>
              <BarcodeScannerModal getCode={getCode} />
            </div>
            <div className="flex justify-center">
              <SearchItemImage className="h-44" />
            </div>
          </div>
        )}

        {isFound && (
          <Badge.Ribbon
            text=<h1 className="text-base pt-1">
              {productData?.data?.isInShop ? "In Shop" : "Out"}
            </h1>
            color="#FAAD14"
            className="w-[108px] h-[32px] text-center"
          >
            <div className="border border-gray-100 p-3">
              <div className="flex gap-10">
                <div className="bg-[#E5E5E5] w-[134px] h-[129px] p-2 rounded-md">
                  <Carousel autoplay className="w-full h-full">
                    {productData?.shopStockItem?.requestedItem?.product?.product?.images?.map(
                      (im: any) => (
                        <div>
                          <img src={im} className="w-full h-full" alt="" />
                        </div>
                      )
                    )}
                  </Carousel>
                </div>
                <div>
                  <h1 className="text-[#030229] text-2xl font-medium">
                    {
                      productData?.shopStockItem?.requestedItem?.product
                        ?.product?.model
                    }
                  </h1>
                  <p>
                    <span className="text-[#030229B2] text-lg">Price:</span>{" "}
                    <span className="text-[#030229] text-lg pl-1">{`RWF ${itemPrice?.toLocaleString()}`}</span>
                  </p>
                  <p>
                    <span className="text-[#030229B2] text-lg">Type:</span>{" "}
                    <span className="text-[#030229] text-lg pl-1">
                      {
                        productData?.shopStockItem?.requestedItem?.product
                          ?.product?.type
                      }
                    </span>
                  </p>
                  <p>
                    <span className="text-[#030229B2] text-lg">Brand:</span>{" "}
                    <span className="text-[#030229] text-lg pl-1">
                      {
                        productData?.shopStockItem?.requestedItem?.product
                          ?.product?.brand
                      }
                    </span>
                  </p>
                  <div className="flex flex-col gap-2">
                    {productData?.shopStockItem?.requestedItem?.product?.specification?.map(
                      (data: any, index: number) => {
                        const regex = /\((.*?)\)/;
                        const match = data[0].match(regex);

                        if (match) {
                          const stringFromBracket = match[1];

                          if (
                            !["type", "brand"].includes(
                              stringFromBracket.toLowerCase()
                            )
                          ) {
                            return (
                              <div
                                className="flex text-[#030229] text-sm font-normal"
                                key={index}
                              >
                                <p>
                                  <span>{`${stringFromBracket}: `}</span>
                                </p>
                                <p className="pl-1">{data[1]}</p>
                              </div>
                            );
                          }
                        }

                        return null;
                      }
                    )}
                  </div>
                  <p>
                    <span className="text-[#030229B2] text-lg">
                      Extended Warranty:
                    </span>{" "}
                    <span className="text-[#030229] text-lg pl-1">
                      {
                        productData?.shopStockItem?.requestedItem?.product
                          ?.extendedWarranty
                      }
                      %
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </Badge.Ribbon>
        )}

        {isFound && (
          <div className="absolute bottom-4 right-6  gap-4">
            <div className="flex flex-wrap gap-x-2">
              <LoadingButton
                type="submit"
                variant="contained"
                sx={{ backgroundColor: "#605BFF" }}
                onClick={discardSearchResult}
                disabled={isView}
              >
                Clear Search
              </LoadingButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RepairProductSearch;
