import React, { useEffect, useState } from "react";
import OverViewCard from "../../../components/cards/inventory/OverViewCard";
import { ReactComponent as BoxIcons } from "../../../assets/icons/dashboard/box-tick.svg";
import { ReactComponent as PdfIcons } from "../../../assets/icons/dashboard/codicon_file-pdf.svg";
import { ReactComponent as CsvIcons } from "../../../assets/icons/dashboard/ph_file-csv.svg";
import { DatePicker, Select, DatePickerProps } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { GoListUnordered } from "react-icons/go";
import { TbGridDots } from "react-icons/tb";
import Search from "antd/es/input/Search";
import { SearchOutlined } from "@ant-design/icons";
import CustomButton from "../../../components/buttons/CustomButton";
import { IoFilterOutline } from "react-icons/io5";
import TransactionsTable from "../../../components/tables/TransactionTable";
import { getActiveShop } from "../../../utils/converter";
import { useDispatch, useSelector } from "react-redux";
import { getAllSalesReportAction, getSalesReportTable } from "../../../store/report/actions";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { getAllTransactionAction } from "../../../store/pos/actions";
import store from "store"
// Extend the jsPDF instance with the autoTable plugin
(jsPDF as any).autoTable = require('jspdf-autotable');

const Transaction = () => {
  const { auth, appSettings, report, shop, pos } = useSelector((state: any) => state)
  const dispatch = useDispatch();
  dayjs.extend(customParseFormat);

  const [showFilter, setShowFilter] = useState(false);
  const formattedDate = new Date().toISOString().slice(0, 10);
  const [selectedDate, setSelectedDate] = useState<any>(formattedDate);
  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    setSelectedDate(dateString)
  };
  const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
    setSelectedSecondDate(dateString)
  };
  const [SelectedSecondDate, setSelectedSecondDate] = useState<any>(formattedDate)
  

  console.log(pos?.transaction?.data,"dddddddddddddddddddddddddddddddddddddddddddddddddd");
  // Function to generate PDF
  const generatePDF = () => {
    const doc: any = new jsPDF(); // Cast jsPDF instance to 'any'

    doc.text("Sales Report", 10, 10);

    // Prepare table data
    const tableData = pos?.transaction?.data?.map((row: any) => {
      return [
        row?.paidOn?.split("T")[0],
        row?.data?.cart?.customer?.phone,
        row?.createdBy?.names,
        row?.product?.model,
        row?.payment?.amount,
        row?.cart?.cartId,
        row?.status
      ];
    });

    // Set column headers
    const headers = ['Date', 'customer', 'CreatedBy', 'Model', 'Amount', 'transactionId', 'status'];

    // Add table to PDF
    doc.autoTable({
      head: [headers],
      body: tableData,
    });

    // Save PDF
    doc.save('transactions.pdf');
  };

  // Function to generate CSV
  const generateCSV = () => {
    const csvContent = "data:text/csv;charset=utf-8," +
      report?.tableData?.data?.map((row: any) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "transactions.csv");
    document.body.appendChild(link);
    link.click();
  };
  const shopId=getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]?.shop?._id || auth?.user?.shop?.shopId;
useEffect(()=>{
  getAllSalesReportAction(
    auth?.token,
    `?shops=${shopId}&date1=${selectedDate}&date2=${SelectedSecondDate}`
  )(dispatch);
},[selectedDate,SelectedSecondDate,dispatch])

  return (
    <div className="text-black">
      <div className="flex justify-between">
        <h1 className="text-[#030229d8] text-xl pt-2">Sales Report</h1>
        <div className="flex gap-2">
          <p className="pt-2">From</p>
          <DatePicker defaultValue={dayjs(formattedDate)} onChange={onChange} />
          <p className="pt-2">To</p>
          <DatePicker defaultValue={dayjs(formattedDate)} onChange={onChangeDate} allowClear={false} />
          <PdfIcons className="w-8 h-8 cursor-pointer" onClick={generatePDF} />
          {/* <CsvIcons className="w-8 h-8  cursor-not-allowed" onClick={generateCSV} /> */}
          <CsvIcons className="w-8 h-8  cursor-not-allowed"/>
        </div>
      </div>
      <div className="flex flex-wrap gap-5 mt-8">
        <OverViewCard text="Total Quantity" icon={<BoxIcons />} total={report?.isFetching ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : report.all?.data?.quantity} />
        <OverViewCard text="Total Amount" icon={<BoxIcons />} total={report?.isFetching ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : report.all?.data?.revenue?.toLocaleString()} />
        <OverViewCard text="Total Customers" icon={<BoxIcons />} total={report?.isFetching ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : report.all?.data?.customer} />
      </div>
      <div>
        <div className="flex justify-between my-5">
          <div className="flex gap-3">
            <CustomButton
              // title={showFilter ? "" : "filter"}
              textColor="[#030229B2]"
              icon={<IoFilterOutline size={20} />}
              bgColor={"white"}
              border="[#0302291A]"
            // onClick={() => setShowFilter(!showFilter)}
            />
            {showFilter && (
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="filter by Model"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "1",
                    label: "Pop 8 (BC6)"
                  },
                  {
                    value: "2",
                    label: "Spark 20"
                  },
                  {
                    value: "3",
                    label: "Pop7"
                  }
                ]}
              />
            )}
          </div>
          <div className="flex gap-5">
            {/* <Search
              placeholder="input search text"
              onSearch={(value, _e) => console.log(value)}
              style={{ width: 200, marginTop: "5px" }}
              prefix={
                <SearchOutlined
                  style={{ color: "#1677ff", fontSize: "16px" }}
                />
              }
            /> */}
            <div className="bg-[#605BFF] flex justify-center items-center h-[30px] w-[30px] mt-1.5 rounded-md">
              <GoListUnordered size={23} color="#ffff" />
            </div>
            <div className="bg-white flex justify-center items-center h-[30px] w-[30px] mt-1.5 rounded-md">
              <TbGridDots size={23} color="#C0C0C9" />
            </div>
            <hr className="h-7 w-0.5 bg-gray-300 mt-1.5" />
          </div>
        </div>
      </div>
      <div>
        <TransactionsTable id="transactions-table" selectedDate={selectedDate} SelectedSecondDate={SelectedSecondDate} />
      </div>
    </div>
  );
};

export default Transaction;
