import React, { useEffect } from 'react';
import { Divider, Table } from 'antd';
import type { TableColumnsType } from 'antd';
import ScrollableFrame from '../layout/ScrollableFrame';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveShop } from '../../utils/converter';
import { getAllStockSentAction } from '../../store/channel/actions';
import { GrFormView } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';
import store from 'store';
interface DataType {
  key: React.Key;
  sold: number;
  model: string;
  stockIn: number;
  transfer: number;
  descriptions: string;
}


const StockRequestTable= (props:any) => {
  const { posOverView , auth, channel, appSettings,shop } = useSelector((state: any) => state);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const dispatch=useDispatch() ;
  const navigate=useNavigate();
  const shopId=getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]?.shop?._id || auth?.user?.shop?.shopId;
  // const shopId =
  // getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
  //   ?.shop?._id ?? shop?.selected?._id;
  useEffect(() => {
    auth?.token &&
      shopId &&
      getAllStockSentAction(
        auth?.token,
        `?shopId=${shopId}&limit=${limit}&page=${page - 1}&status=${props?.value}`
      )(dispatch);
  }, [auth?.token, dispatch, limit, page, shopId,props?.value]);
  const columns: TableColumnsType<DataType> = [
    {
      title: 'RFS',
      dataIndex: 'requestId',
    },
    {
      title: 'Shop',
      dataIndex: 'shop',
    },
    {
      title: 'Requested By',
      dataIndex: 'requestedBy',
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
    },
    {
      title: 'Approved By',
      dataIndex: 'approved',
    },
    {
      title: "View",
      dataIndex: "view",
      render: (text: any, record: any) => {
        return (
          <GrFormView
            size={25}
            color="#5F5AFE"
            className="cursor-pointer"
            onClick={() => {
              navigate(`/pos/request/${record?.requestId}`)
            }}
          />
        );
      }
    }
  ];
  
 
  const stockData=channel?.selectedRfs?.data?.map((el:any)=>{
    return{
      key: el?._id,
      requestId: el?.requestId,
      createdBy: el?.createdBy?.names,
      createdAt:el?.createdAt?.slice(0,10),
      price:el?.requestedItem?.product?.prices
      ?.filter((d: any) => d.isActive === true)
      ?.map((d: any) => d.value)
      ?.toLocaleString(),
      status:el?.requestedItem?.transferStatus,
      requestedBy:el?.createdBy?.names,
      approved:el?.approvedBy?.names,
      shop:el?.shopId?.name,
      data:el
    }
  })
  return(
  <>
   <ScrollableFrame
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={channel?.selectedRfs?.total}
          count={Math.ceil(channel?.selectedRfs?.total / limit)}
          // isTable
        >
    <Table columns={columns} dataSource={stockData} size="middle" loading={channel?.isFetching} pagination={false}/>
    </ScrollableFrame>
  </>
)};

export default StockRequestTable;